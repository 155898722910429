import React, { useState } from 'react';
import { Link } from 'react-feather';
import { useSubdomain } from '../../../hooks';
import { getCommunityData } from '../../../app';

import './community_header.css';

const CommunityHeader = ({ mode }) => {
  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));

  const generateCommunityLinks = () => {
    const linkEls = [];
    for (const link of community.links) {
      let icon;
      const size = "24px"; // Set icon size inline for easier use with feather
      switch (link.platform) {
        case 'general':
          icon = <Link size={size} />
          break;
        case 'slack':
          icon = <img src="/community/slack-logo.png" title="Slack" width={size} />
          break;
        case 'discord':
          icon = <img src="/community/discord-logo.png" title="Discord" width={size} />
          break;
        default:
          console.error("Unexpected platform: " + link.platform);
      }
  
      if (icon) {
        linkEls.push(
          <a href={link.url} target="_blank" rel="noopener noreferrer" key={link.platform}>{icon}</a>
        );
      }
    }
  
    return linkEls;
  };

  return (
    <div className="community-header">
      {community && <>
        <div className={"subdomain-banner " + subdomain + "-background"} />
        <div className="subdomain-header container">
          <img 
            src={"./community/" + subdomain + "-logo.png"} 
            alt={community.name + " logo"}
            className="community-logo" />
          <div className={"community " + subdomain + "-color"}>
            <div className="community-title">
              <div className="title">{community.name}</div>
              <div className="links">{generateCommunityLinks()}</div>
            </div>
            <h3 className="community-description">{community.description}</h3>
          </div>
        </div>
        </>
      }
    </div>
  )
}

export default CommunityHeader;