const currentUserString = localStorage.getItem('currentUser');
const currentUser = JSON.parse(currentUserString);

export const initialState = {
  user: currentUser?.user,
  authToken: currentUser?.authToken,
  loginMethod: currentUser?.loginMethod,
  loading: false,
  errorMessage: null
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        loading: true
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        user: action.payload.user,
        authToken: action.payload.authToken,
        loginMethod: action.payload.loginMethod,
        loading: false
      };
    case 'LOGOUT':
      return {
        ...initialState,
        user: '',
        authToken: ''
      };
    case 'LOGIN_ERROR':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};