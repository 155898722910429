import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ChevronDown, ArrowRight } from 'react-feather';
import { capitalize } from '../../util';
// import '../../css/field.css';
import './field.css';

const Field = (props) => {
  const [field, setField] = useState('');
  const [pageElements, setPageElements] = useState(<ul></ul>);
  const headerRefs = useRef([]);
  const careerRefs = useRef([]);
  const hrRefs = useRef([]);

  useEffect(() => { // []
    setField(capitalize(props.match.params.field));
  }, []);

  useEffect(() => { // [field]
    if (field.length === 0) return;
    document.title = `Careers in ${field} - PathFinder`;

    let pageItems = {};
    if (field === 'Software') {
      pageItems = {
        'Data/ML/AI': [
          'Data Analyst', 'Data Scientist', 'Data Engineer', 
          'Data Architect', 'Machine Learning Engineer', 'Computer Vision Engineer', 
          'Computer Vision Researcher', 'NLP Engineer', 'Perception Engineer', 
          'Machine Learning Researcher'
        ],
        'Database': ['Database Administrator', 'Database Engineer'],
        'Human Computer Interface': ['UX Designer', 'UX Researcher', 'UI Designer'],
        'Security': [
          'Security Engineer', 'Security Architect', 'Information Security Analyst', 
          'Information Security Architect'
        ],
        'Quality Assurance': [
          'Quality Assurance Specialist', 'Quality Assurance Engineer', 'Test Engineer',
          'Automation Engineer'
        ],
        'Game Development': ['Game Designer'],
        'Generic and App Dev': [
          'Software Engineer', 'Web Developer', 'Mobile Developer',
          'Android Developer', 'iOS Developer', 'Fullstack Engineer',
          'Backend Engineer', 'Frontend Engineer', 'Application Engineer',
          'Application Architect', 'Enterprise Architect', 'Information Architect'
        ],
        
        'Other': [
          'Quantitative Developer', 'Quantitative Researcher', 'AR Developer',
          'Blockchain Developer'
        ]
      }
    }
    else if (field === 'Hardware') {
      pageItems = {
        'Robotics': ['Robotics Engineer'],
        'Hardware/Systems': [
          'Distributed Systems Engineer', 'Systems Engineer', 'Systems Architect', 
          'Software Architect', 'Embedded Engineer', 'Firmware Engineer', 
          'ASIC Designer Engineer', 'FPGA Designer', 'Hardware Engineer', 
          'Controls Engineer', 'RF Engineer', 'Optical Engineer', 
          'Signal Processing Engineer', 'Audio Engineer'
        ],
        'Network': ['Network Engineer', 'Network Specialist', 'Network Administrator'],
        'Operations': [
          'DevOps Engineer', 'DevOps Specialist', 'Site Reliability Engineer', 
          'System Administrator', 'Cloud Architect', 'Infrastructure Engineer'
        ],
      }
    }
    else if (field === 'Product') {
      pageItems = {
        'Product': ['Product Designer', 'Product Manager', 'Product Engineer'],
        'IT': ['IT Administrator'],
        'Management': [
          'Project Manager', 'Engineering Manager', 'Software Engineering Lead',
          'Software Manager', 'Tech Lead', 'Director of Engineering',
          'VP Engineering'
        ],
        'Solution or Sales': [
          'Solutions Engineer', 'Customer Support Engineer', 'Solutions Architect',
          'Sales Engineer'
        ],
      }
    }

    const pageElements = [];

    // Iterate through each subfield to create JSX
    for (const [idx, [subfield, careers]] of Object.entries(pageItems).entries()) {
      const careersElements = [];
      // Create JSX for careers of this subfield
      for (const [i, career] of careers.entries()) {
        careersElements.push(
          <li 
            key={uuid()}
            className="career result"
            onClick={() => {
              props.mixpanel.track('Clicked listed career from fields page', {
                subfield: subfield,
                career: career
              })
              
              // props.history.push(`/career/${career.replace(/ /g, '')}`);
              props.history.push(`/career/${career}`);
            }}
          >
            {career}
            <ArrowRight className="arrow-right" preserveAspectRatio="none" />
          </li>
        );
      }
      
      pageElements.push(
        <li key={uuid()}>
          <div className="subfield">
            <h3 className="subfield-header"
                onClick={() => {
                  headerRefs.current[idx].children[0].children[0].classList.toggle('rotate-180');
                  careerRefs.current[idx].classList.toggle('show-careers');
                  hrRefs.current[idx].classList.toggle('show-careers');
                }}
                ref={el => {
                  headerRefs.current[idx] = el;
                }}
              >
              {subfield}
                <span>
                  <ChevronDown />
                </span>
            </h3>
            <ul className="subfield-careers"
                ref={el => careerRefs.current[idx] = el}
            >
              {careersElements}
            </ul>
            <hr ref={el => hrRefs.current[idx] = el} 
                className="subfield-careers"
            />
          </div>
        </li>
      );
    }

    setPageElements(<ul>{pageElements}</ul>)

  }, [field]);

  return (
    <div className="field-component container">
      <div className="header-margin">
        <h2>{field}</h2>
        {/* <p>A description about the {field} area in tech.</p> */}
      </div>
      <div className="fields-body">
        {pageElements}
      </div>
    </div>
  )
}

export default withRouter(Field);