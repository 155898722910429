import Chart from 'chart.js/auto';
import { getTermDescription } from './app';
import { formatPercentage } from './util';

// chart.js docs
// https://www.chartjs.org/docs/latest/charts/bar.html
/**
 * Draws the barchart
 * @param {{ term: string, count: number }[]} data The data to be displayed
 * @param {number} jobCount The number of job posts
 * @param {React.Ref} chartRef React chart ref
 * @param {string} selectedTermType The type of the selected term
 * @param {React.Dispatch<React.SetStateAction<string>>} setSelectedTerm 
 *    The set state function for the current term
 */
const barchart = async (data, jobCount, chartRef, termType, setTermData, descriptionCardBodyRef) => {
  let currentStatus={}, currentTab="languages";

  let totalLabels = [];
  let totalCounts = [];

  let percentages = [];
  for (const e of data) {
    const count = parseInt(e.count);
    let percentage = formatPercentage(count, jobCount);

    totalCounts.push(count);
    totalLabels.push(e.term);
    percentages.push(percentage);
  }

  let labels = totalLabels.slice(0, 10);
  let counts = totalCounts.slice(0, 10);

  const dataConfig = {
    labels: labels,
    datasets: [{
      axis: 'y',
      label: '',
      data: counts,
      fill: false,
      backgroundColor: '#527EF0',
      borderWidth: 1
    }]
  };

  const plugins = {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        label: () => null,
        title: () => null,
      },
      backgroundColor: 'rgba(0, 0, 0, 0.0)'
    },
  }

  // let currentTerm = ''; // keep track of current term to prevent unnecessary fetching
   
  const handleChartClick = async (evt, elements, chart) => {
    if (elements.length > 0) {
      const idx = elements[0].index;
      const term = chart.data.labels[idx];
      const currentTerm = document.querySelector('.card-title')?.innerText;
      // console.log('curTerm vs term:', currentTerm, term);
      if (currentTerm === term) return;
      
      descriptionCardBodyRef.current?.classList.add('loading');
      const description = ((termType === 'languages' || termType === 'skills')
        ? await getTermDescription(termType, term)
        : ''
      );
      descriptionCardBodyRef.current?.classList.remove('loading');

      const termCount = chart.data.datasets[0].data[idx];

      let newData = {
        name: term,
        description: description,
        count: termCount,
        percentage: formatPercentage(termCount, jobCount)
      }

      setTermData(newData);
    }
  }

  // Draws percentage text to the right of each bar in the chart
  const displayPercentages = ({chart}) => {
    const ctx = chart.ctx;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    let meta = chart._metasets[0];

    for (const [idx, bar] of meta.data.entries()) {
      let termCount = meta._dataset.data[idx];
      const percentage = formatPercentage(termCount, jobCount);
      ctx.fillText(percentage, bar.x+percentage.length*5+20, bar.y+10);
    }
  }

  const config = {
    type: 'bar',
    data: dataConfig,
    options: {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          // Adjust this value if percentages are cut off
          // max(counts) + mean(counts)/1.75
          max: Math.max(...counts) + (counts.reduce((a, b) => a + b) / counts.length) / 1.75,
          grid: {
            display: false,
            borderWidth: 0
          },
          ticks: {
            display: false
          }
        },
        y: {
          grid: {
            display: false,
            borderWidth: 5,
            borderColor: '#A1A1A1',
          },
          ticks: {
            font: {
              size: 20,
              family: 'DM Sans'
            },
            color: 'black',
            callback: function(value) {
              // Truncate labels and add an ellipsis if needed
              let str = this.getLabelForValue(value);
              return str.substring(0,30) + (str.length > 30 ? '...' : '');
            }
          }
        }
      },
      layout: {
        margin: {
          left: 100
        }
      },
      plugins: plugins,
      onHover: (evt, elements, chart) => {
        if (elements.length > 0) {
          chartRef.style.cursor = 'pointer';
        } else {
          chartRef.style.cursor = 'default';
        }
      },
      onClick: handleChartClick,
      animation: {
        onProgress: chartInstance => displayPercentages(chartInstance),
      },
    }
  };

  const chart = new Chart(chartRef, config);
  
  // Custom Event when View More Button is Clicked
  chartRef.addEventListener('barChartUpdate', (event) => {
    // event.detail is info we received from toSlice from career.jsx
    currentStatus = event.detail[0];
    currentTab = event.detail[1];
  
    // update Chart
    viewMoreData(chart, currentTab);
  
    // Change text of button depending on tab's current status
    if (document.getElementById("viewMore")) {
      if (currentStatus[currentTab]["viewMoreStatus"]) {
        document.getElementById("viewMore").innerText="View Less";
      } else {
        document.getElementById("viewMore").innerText="View More";
      }
    }
  });

  // Custom Event when tab is switched to update the chart
  chartRef.addEventListener('barChartUpdateTabSwitch', async (event) => {
    var descriptionForTerm = ((termType === 'languages' || termType === 'skills')
        ? await getTermDescription(termType, term)
        : ''
      );
    // event.detail is info we received from toSlice from career.jsx
    currentStatus = event.detail[0];
    currentTab = event.detail[1];

    // data per card
    // most data is the same as line 80, so didn't feel the need to add defining stuff
    let dataAfterSwitch = {
      name: term,
      description: descriptionForTerm,
      count: termCount,
      percentage: formatPercentage(termCount, jobCount)
    }
    setTermData(dataAfterSwitch);
  
  });
  
  // Update Chart : update labels and count from dummydata
  function viewMoreData(chart, currentTab) {
    chart.data.labels = totalLabels.slice(0,currentStatus[currentTab]["toSlice"]);
    chart.data.datasets[0].data = totalCounts.slice(0, currentStatus[currentTab]["toSlice"]);
    chart.update();
  }

  const term = chart.data.labels[0];
  const termCount = chart.data.datasets[0].data[0];

  const initData = {
    name: term,
    description: ((termType === 'languages' || termType === 'skills')
      ? await getTermDescription(termType, term)
      : ''),
    count: termCount,
    percentage: formatPercentage(termCount, jobCount)
  };
  setTermData(initData);
}

export default barchart;
