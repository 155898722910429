import React, { useState, useEffect, useRef } from 'react';

// Tracks whether or not a component is mounted
export const useIsMountedRef = () => {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => isMountedRef.current = false;
  });
  return isMountedRef;
}

let subdomainOverride = '';

export const setSubdomainOverride = (value) => subdomainOverride = value;

export const useSubdomain = () => {
  const [subdomain] = useState(() => {
    if (subdomainOverride.length > 0) {
      console.info("[Debug settings] Overriding subdomain to: " + subdomainOverride);
      return subdomainOverride;
    }

    const parts = window?.location?.hostname?.split('.');
    if (parts.length > 2) {
      return parts[0];
    }
    return "";
  });

  return subdomain;
}