import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { GoogleLogout, useGoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import { logout, useAuthDispatch, useAuthState } from '../../context';
import './navigation.css';
import { getUIAvatar } from '../../util';
import { getCommunityData, authorizeLinkedinSignin, linkedinSignin } from '../../app';
import { useSubdomain } from '../../hooks';
import { Edit2, LogOut } from 'react-feather';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const toggleLogs = false;

const Navigation = (props) => {
  const navRef = useRef();
  const navContainerRef = useRef();
  const menuToggleRef = useRef();
  const userDropdownRef = useRef();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));
  const [logoutButton, setLogoutButton] = useState(<></>);

  const authDispatch = useAuthDispatch();
  const userDetails = useAuthState();

  const handleLogout = async () => {
    await logout(authDispatch);
    
    location.reload();
  }

  const { signOut, loaded } = useGoogleLogout({
    clientId,
    cookiePolicy: 'single_host_origin',
    onLogoutSuccess: handleLogout,
  });

  const handleClickOutsideUserMenu = (evt) => {
    if (userDropdownRef.current && !userDropdownRef.current.contains(evt.target)) {
      setUserMenuOpen(false);
    }
  }

  const initLogoutState = () => {
    if (!userDetails.user) return;
    const loginMethod = userDetails.loginMethod;
    switch (loginMethod) {
      case 'google':
        setLogoutButton(
          <GoogleLogout
            render={renderProps =>
              <li onClick={renderProps.onClick} className="googleButton">
                <LogOut size="18px" />
                <span>Log out</span>
              </li>}
            clientId={clientId}
            cookiePolicy={'single_host_origin'}
            onLogoutSuccess={handleLogout}
          />
        );
        break;
      default:
        setLogoutButton(
          <li onClick={() => handleLogout()}>
            <LogOut size="18px" />
            <span>Log out</span>
          </li>
        );
    }
  }

  const checkLinkedinLogin = () => {
    if (userDetails.user) {
      return
    }

    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const state = params.get("state");
    const hmac = localStorage.linkedinAuthHmac;
    const originalUrl = localStorage.linkedinOriginalUrl;
    const error = params.get("error");

    // when a user cancels the login, reset localStorage items
    if (error) {
      console.error(error);
      localStorage.removeItem("linkedinAuthState");
      localStorage.removeItem("linkedinAuthHmac");
      localStorage.removeItem("linkedinOriginalUrl");
      localStorage.removeItem("linkedinRedirectURI")
      const url = new URL(window.location.href);
      url.search = '';
      history.pushState({}, '', url);
      return
    }

    // if the user isn't signed in, but there are signs of linkedin login, proceed 
    if (code && state && hmac && originalUrl) {
      localStorage.setItem("linkedinCodeTemp", code);
      localStorage.setItem("linkedinStateTemp", state);
      localStorage.setItem("linkedinHmacTemp", hmac);

      // send the user back to their original location
      window.location.assign(localStorage.linkedinOriginalUrl);
      localStorage.removeItem("linkedinOriginalUrl");

      return
    }

    // if the user refreshes the page without logging out, we refresh their linkedin auth session
    if (localStorage.currentUser) {
      const currentUser = JSON.parse(localStorage.currentUser);
      if (currentUser.loginMethod === "linkedin" && !localStorage.linkedinCodeTemp) {
        linkedinSignin();
        return
      }
    }

    // login
    authorizeLinkedinSignin(authDispatch);
  }

  useEffect(() => {
    initLogoutState();
  }, [loaded, userDetails.user])

  const handleScroll = (evt) => {
    if (!navRef.current) return;
    
    const rootElement = document.documentElement;
    navContainerRef.current.style.setProperty('--mobile-opacity', `hsla(0, 0%, 100%, ${rootElement.scrollTop / 300}`);
  }

  useEffect(() => { // []
    // https://blog.logrocket.com/guide-adding-google-login-react-app/
    const initGoogleClient = () => {
      gapi.client?.init({
        clientId: clientId,
        scope: 'https://www.googleapis.com/auth/userinfo.email'
      })
    };
    gapi.load('client:auth2', initGoogleClient);

    checkLinkedinLogin();
    
    document.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutsideUserMenu);

    return () => {
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutsideUserMenu);
    };
  }, []);

  return (
    <nav className="navigation-component d-flex" ref={navRef}>
      <div className="navigation-container container" ref={navContainerRef}>
        <Link to="/">
          <img className="logo" src="/full_logo.png" />
        </Link>
        <input id="menu-toggle" type="checkbox" ref={menuToggleRef} />
        <label className="menu-button-container" htmlFor="menu-toggle">
          <div className="menu-button"></div>
        </label>
        <ul className="menu">
          <li className="nav-left">
            <Link
              className="nav-link nav-page-link"
              id="nav-discover"
              to="/discover"
              title="discover"
              onClick={() => {
                props.mixpanel.track('Clicked discover link', { from: 'nav' });
                menuToggleRef.current.checked = false;
              }}
            >
              discover
            </Link>
            {!community &&
              (<Link
                className="nav-link nav-page-link"
                id="nav-about"
                title="about"
                to="/about"
                onClick={() => {
                  props.mixpanel.track('Clicked about link', { from: 'nav' });
                  menuToggleRef.current.checked = false;
                }}
              >
                about
              </Link>)
            }
          </li>
          <li className="nav-right">
            {userDetails.user
              ? <div className="user-dropdown" ref={userDropdownRef}>
                  <div 
                    className="user-info"
                    onClick={() => {setUserMenuOpen(!userMenuOpen)}}>
                      <img
                        src={userDetails.user.avatarUrl}
                        alt={userDetails.user.firstName + ' Avatar'}
                        referrerPolicy="no-referrer"
                        className="avatar"
                        onError={async (evt) => {
                          const url = `https://ui-avatars.com/api/?name=${userDetails.user.firstName + ' ' + userDetails.user.lastName}&length=2&bold=true&background=527ef0&color=fcfcfc&font-size=0.5`;
                          const imgUrl = await getUIAvatar(url);
                          evt.target.src = imgUrl;
                        }}
                      />
                      <span>{userDetails.user.firstName}</span>
                  </div> 
                  {userMenuOpen &&
                    <ul className="user-menu">
                      {/* <li>
                        <Edit2 size="18px"/>
                        <span>Edit Profile Picture</span>
                      </li> TODO: Implement profile picture upload */}
                      {logoutButton}
                    </ul>
                  }
                </div>
              : <button
                className="signin-button"
                onClick={() => {
                  props.setShowSignin(true);
                }}>Sign in</button>
            }
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
