import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { Story, Comments, Loading} from '../../components';
import Reactions from '../../components/Reactions/Reactions.jsx';
import Share from '../../components/Share/Share';
import { getComments, getCareerPosts, getStory } from '../../app.js'
import { formatLink, getCompanyLogoURL } from '../../util.js'
import { ReactComponent as BackArrow } from '../../assets/back_arrow.svg';
import { useIsMountedRef } from '../../hooks.js';
import './storiesV2.css';

/*
  Note: So far, this page only gets rendered when a story card from the Discover page is clicked
*/
const StoriesV2 = (props) => {
  const [storyData, setStoryData] = useState(null);
  const [postData, setPostData] = useState(null);
  const [commentCount, setCommentCount] = useState(0);
  const [relatedStories, setRelatedStories] = useState([]);
  const [toggleAll, setToggleAll] = useState(false);

  const isMountedRef = useIsMountedRef();
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!isMountedRef.current) return;
      const currentSearchParams = new URLSearchParams(location.search);
      const postId = currentSearchParams.get('post');
      setToggleAll(false);
      fetchCurrentStoryData(postId);
      return () => isMountedRef.current = false;
    });

    fetchCurrentStoryData(searchParams.get('post'));
    return unlisten;
  }, []);

  async function fetchCurrentStoryData(postId) {
    if (!postId) return;

    const story = await getStory(postId);
    setStoryData(story);
    setPostData(story.post);

    const comments = await getComments(story.post._id);
    setCommentCount(Object.keys(comments).length ? comments.comments.length : 0);
  }

  useEffect(() => {
    if (!storyData) return;
    document.title = `${storyData.officialTitle ? storyData.officialTitle : storyData.experienceLevel} @ ${storyData.company} - PathFinder`;

    fetchRelatedStories();
  }, [storyData])

  async function fetchRelatedStories() {
    const relatedStoriesResult = [];
    const results = await getCareerPosts(storyData.jobTitles[0]);
    const relatedStoryCountLimit = Math.min(results.length, 8);
    for (let i = 0; i < relatedStoryCountLimit; i++) {
      const result = results[i];
      const handleImageError = (event) => {
        event.target.src = '/default_avatar.png';
      };

      /* Don't display current story as part of the related stories*/
      if (result.post._id === storyData.post._id) continue;
      /* Get number of comments of each related story*/
      const currComments = result.post.commentSection ?? {};

      relatedStoriesResult.push(
        <Link
          to={`/story?post=${result.post._id}`}
          className="related-story" 
          key={`${result.post._id}related-story`}
        >
          <div className="related-story-header">
            <img
                src={(result.post.user.avatarUrl) ? result.post.user.avatarUrl : getCompanyLogoURL(result.company)}
                className="avatar" referrerPolicy="no-referrer" loading="lazy"
                onError={handleImageError}
              />
            <div className="header-text">
              {(result.post.user.fullName) ? `${result.post.user.fullName} | `: ''} {result.officialTitle} @
              {result.company} | {result.experienceLevel}
            </div>
          </div>
          <div className="related-story-body">{result.team}</div>
          <div className="related-story-footer">
            <Reactions data={result.post} type="story-preview"/>
            <div className={`comment-button ${
              (Object.keys(currComments).length === 0 || currComments.comments.length === 0) 
                ? 'zero-comments' : ''}`}
            >
              {Object.keys(currComments).length !== 0 ? currComments.comments.length : 0} comments
            </div>
          </div>
        </Link>
      );
    }
    setRelatedStories(relatedStoriesResult);
  }
  
  return (
    <div className="stories-page container">
      {storyData && postData ?
        <>
        <BackArrow className="back-arrow" onClick={history.goBack}/>
        <div className="story-section">
          <Story type="story" storyData={storyData} toggleAll={toggleAll} setToggleAll={setToggleAll} />
          <div className="story-footer">
            <div className="story-footer-left">
              <Reactions data={postData} setData={setPostData} type='post'/>
              <div className="comment-button story">{commentCount} comments</div>
            </div>
            <Share className='share-link' currentPost={storyData} jobTitle={storyData.jobTitles[0]}/>
          </div>
          <Comments
            currentPost={storyData}
            mixpanel={props.mixpanel}
          />
        </div>
        <div className="related-stories-section">
          <h4>Related Stories</h4>
            {relatedStories}
        </div>
        </>
      : <Loading mixpanel={props.mixpanel} />
    }
    </div>
  )
}

export default withRouter(StoriesV2);