import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Loading, Story, CommunityHeader } from '../../components';
import FilterSection from './FilterSection';
import './discoverV2.css';
import { getCareerPosts, getCommunityData } from '../../app';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from 'react-feather';
import { setContainsCaseInsensitive } from '../../util';
import DiscoverSearch from '../../components/SearchBar/DiscoverSearch/DiscoverSearch';
import { useSubdomain } from '../../hooks';

const pageStoryLimit = 6; // Limit to number of stories displayed on the page at a time.

/**
 * @typedef {import('../../components/FAQCard/FAQCard').PostInfo} PostInfo
 */

const DiscoverV2 = (props) => {
  /** @type {[PostInfo[], React.Dispatch<React.SetStateAction<PostInfo[]>>]} */
  const [storyData, setStoryData] = useState(null);
  const [storyElements, setStoryElements] = useState(null);
  const [sortState, setSortState] = useState('Top Stories');
  const [sortIsOpen, setSortIsOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [filterData, setFilterData] = useState({}); // All filter values
  const [selectedFilters, setSelectedFilters] = useState({}); // Filters the user has selected
  const [filterCategories, setFilterCategories] = useState([]);
  
  const sortMenuRef = useRef();

  const screenWidth = Math.min(window.outerWidth, window.innerWidth);
  const [filterIsOpen, setFilterIsOpen] = useState(screenWidth > 768);

  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));

  async function initFilterData(stories) {
    let newFilterCategories = [];
    
    if (community) {
      switch (subdomain) {
        case 'bitsinbio':
          newFilterCategories = ["Career", "Company", "Industry"];
          break;
        case 'bobatalks':
          newFilterCategories = ["Mentors", "Career", "Company", "Career Stage", "Major", "Can help with:"];
          break;
        default:
          console.error("Unexpected subdomain: " + subdomain);
      }
    } else {
      newFilterCategories = ["Career", "Company", "Employment Type"];
    }
    setFilterCategories(newFilterCategories);

    let newFilterData = newFilterCategories.reduce((obj, category) => {
      obj[category] = new Set();
      return obj;
    }, {});
    stories.forEach(story => {
      for (const category of newFilterCategories) {
        switch (category) {
          case "Career":
            story.jobTitles.forEach(title => newFilterData["Career"].add(title));
            break; 
          case "Company":
            // Prevent empty companies and duplicates
            if (story.company.length > 0 && !setContainsCaseInsensitive(newFilterData["Company"], story.company)) {
              newFilterData["Company"].add(story.company);
            }
            break;
          case "Employment Type":
            newFilterData["Employment Type"].add(story.experienceLevel);
            break;
          case "Industry":
            if ("industry" in story) {
              newFilterData["Industry"].add(story.industry);
            }
            break;
          case "Mentors":
            if (story.post?.user.mentorshipInfo) {
              const editMemberInfo = story.post.user.mentorshipInfo.substring(6)
              newFilterData["Mentors"].add(editMemberInfo);
            }
            break;
          case "Career Stage":
            newFilterData["Career Stage"].add(story.experienceLevel);
            break;
          case "Major":
            if (story.post?.user.major) {
              newFilterData["Major"].add(story.post.user.major);
            }
            break;
          case "Can help with:":
            const helpList = story.post.responses[0].answer.split(",").map(item => item.trim())
            helpList.forEach(item => newFilterData["Can help with:"].add(item));
            break;
          default:
            console.error("Unexpected filter category: " + category);
        }
      }
    });

    for (const [filterCategory, filterSet] of Object.entries(newFilterData)) {
      newFilterData[filterCategory] = Array.from(filterSet);
    }

    setFilterData(newFilterData);
  }

  const fetchStories = async () => {
    let stories = await getCareerPosts('ALL');
    if (community) {
      stories = stories.filter((story) => story.post.community?.replace(/\s/g, "") === subdomain);
    }

    const sortedStories = sortStories(stories, sortState);
    initFilterData(sortedStories);
    setStoryData(sortedStories);
  }

  const handlePageChange = (page) => {
    if (page === activePage) return;
    setActivePage(page);
    window.scroll({top: 0, behavior: 'auto'});
  }

  // Returns slice of storyEls based on activePage
  function paginate(storyEls) {
    if (!storyEls) return;
    const startIdx = pageStoryLimit * (activePage-1);
    return storyEls.slice(startIdx, startIdx + pageStoryLimit);
  }

  const generatePagination = () => {
    const storyCount = storyElements?.length;
    if (storyCount === 0 || storyCount <= pageStoryLimit) {
      return null;
    }

    const totalPages = Math.ceil(storyCount / pageStoryLimit);

    const getVisiblePages = () => {
      const visiblePages = [];
      const pageToShowBefore = Math.max(Math.min(activePage - 2, totalPages - 4), 1);
      const pageToShowAfter = Math.min(Math.max(activePage + 2, 5), totalPages);
      pageToShowBefore !== 1 && visiblePages.push(1);
      pageToShowBefore > 1 && visiblePages.push('<<')
      for (let i = pageToShowBefore; i <= pageToShowAfter; ++i) {
        visiblePages.push(i);
      }
      pageToShowAfter < totalPages && visiblePages.push('>>')
      pageToShowAfter !== totalPages && visiblePages.push(totalPages);

      return visiblePages;
    }

    const renderPageNumbers = () => {
      const visiblePages = getVisiblePages();
      return visiblePages.map((page) => {
        if (page === '<<') {
          const targetPage = visiblePages[visiblePages.indexOf('<<') + 1] - 1;
          return (
            <button 
              key={page} 
              onClick={() => handlePageChange(targetPage)}
              title={"Go to page " + targetPage}>
                &lt;&lt;
            </button>
          );
        }
        if (page === '>>') {
          const targetPage = visiblePages[visiblePages.indexOf('>>') - 1] + 1;
          return (
            <button 
              key={page} 
              onClick={() => handlePageChange(targetPage)}
              title={"Go to page " + targetPage}>
                &gt;&gt;
            </button>
          );
        }
        return (
          <button key={page} onClick={() => handlePageChange(page)} className={page === activePage ? 'active' : ''}>
            {page}
          </button>
        );
      });
    }

    return(
      <div className="pagination">
        {activePage > 1
          ? <button 
              onClick={() => handlePageChange(activePage - 1)}
              title="Previous page">
                <ChevronLeft stroke="#263238" />
            </button>
          : <div className="space"></div>
        }
        <div className="page-numbers">{renderPageNumbers()}</div>
        {activePage < totalPages
          ? <button 
              onClick={() => handlePageChange(activePage + 1)}
              title="Next page">
                <ChevronRight stroke="#263238" />
              </button>
          : <div className="space"></div>
        }
      </div>
    );
  }

  const handleClickOutsideSortMenu = (evt) => {
    if (sortMenuRef.current && !sortMenuRef.current.contains(evt.target)) {
      setSortIsOpen(false);
    }
  }

  const generateStoryElements = (stories) => {
    let newStoryEls = [];
    for (let i=0; i < stories.length; ++i) {
      const currStoryData = stories[i];
      newStoryEls.push(
        <Story storyData={currStoryData} type="discover" key={currStoryData._id} />
      );
    }

    return newStoryEls;
  }

  const sortStories = (data, sortValue) => {
    let sortedData = [];
    switch (sortValue) {
      case 'Top Stories':
        const getStoryReactScore = (story) => {
          let score = 0;
          // Score from reactions
          const reactionWeight = 40;
          if (story.post.reactions) {
            for (const reactors of Object.values(story.post.reactions)) {
              score += reactors.length * reactionWeight;
            }
          }
          // Score from comments
          const commentWeight = 50;
          if (story.post.commentSection && story.post.commentSection.comments) {
            score += story.post.commentSection.comments.length * commentWeight;
          }

          /**
           * Score from post content
           * 
           * Weighted by length of posts with flat bonus for each response given
           */
          const contentWeight = 1/30, responseBonus = 50;
          const contentScore = Math.round(story.post.responses.reduce((sum, cur) => (
            sum + (cur.answer.length * contentWeight) + responseBonus
          ), 0));

          score += contentScore;
          
          return score;
        }

        sortedData = data.toSorted((a, b) => (
          getStoryReactScore(b) - getStoryReactScore(a)
        ));
        break;
      case 'Most Recent':
        const getStoryDateScore = (story) => Date.parse(story.post.date);

        sortedData = data.toSorted((a, b) => (
          getStoryDateScore(b) - getStoryDateScore(a)
        ));
        break;
      default:
        console.error('Unexpected sortValue: ' + sortValue);
    }
    return sortedData;
  }

  const generateSortStories = () => {
    const sortValues = ['Top Stories', 'Most Recent'];

    const handleClickSortSelection = (evt, stories) => {
      const newSort = evt.target.textContent;
      setSortIsOpen(false);
      if (newSort === sortState) return;
      setSortState(newSort);
      setStoryData(sortStories(stories, newSort));
    }

    const dropdownMenu = sortValues.map((value) => 
      <li 
        className="sort-item" 
        key={value}
        onClick={(evt) => handleClickSortSelection(evt, storyData)}>{value}
      </li>
    );
    
    return (
      <div className="sort-dropdown" ref={sortMenuRef}>
        <button onClick={() => setSortIsOpen(!sortIsOpen)}>
          Sort:&nbsp;
          <span className="current-sort">{sortState}</span>
          <ChevronDown height={"15px"} width={"15px"}/>
        </button>
        {sortIsOpen && 
          <ul className="sort-menu">
            {dropdownMenu}
          </ul>
        }
      </div>
    );
  }

  const filtersAreEmpty = (filters) => {
    return Object.values(filters).every((filterList) => filterList.length === 0);
  }

  const handleFilterSelectionChange = (category, selectedFilters) => {
    let newFilters;
    setSelectedFilters(prevState => {
      newFilters = {
        ...prevState,
        [category]: selectedFilters,
      };
      return newFilters;
    });
    
    filterStories(newFilters);
  };

  /**
   * This applies the OR operation on filters
   * selected within each category and the AND
   * operation between each category on our story data.
   * Filters stories if needed and sets story elements.
   */
  const filterStories = (filters) => {
    if (filtersAreEmpty(filters)) {
      setStoryElements(generateStoryElements(storyData));
      setActivePage(1);
      return;
    }
    let filteredStories = storyData;

    const filterFns = [];
    for (const category of filterCategories) {
      let categoryFilters = filters[category] || [];
      if (categoryFilters.length > 0) {
        filterFns.push((story) => {
          switch (category) {
            case "Career":
              return categoryFilters.some(career => story.jobTitles?.includes(career));
            case "Company":
              return categoryFilters.some(company => (
                story.company?.toLowerCase().includes(company.toLowerCase()) || 
                story.companies?.some(company_ => company_.toLowerCase() === company.toLowerCase())
              ));
            case "Employment Type":
              return categoryFilters.some(employmentType => story.experienceLevel?.includes(employmentType));
            case "Industry":
              return categoryFilters.some(industry => story.industry?.includes(industry));
            case "Mentors":
              return categoryFilters.some(mentor => story.post?.user.mentorshipInfo.includes(mentor));
            case "Career Stage":
              return categoryFilters.some(careerStage => story.experienceLevel?.includes(careerStage));
            case "Major":
              return categoryFilters.some(major => story.post?.user.major.includes(major));
            case "Can help with:":
              const helpAnswer = story.post?.responses?.[0]?.answer;
              if (!helpAnswer) return false;
              const helpList = helpAnswer.split(",").map(item => item.trim());
              return categoryFilters.some(help => helpList.includes(help));
        default:
              console.error("Unexpected filter category: " + category);
          }
        });
      }
    }

    // Filter data
    filteredStories = filteredStories.filter((item) => (
      filterFns.every((filterFn) => filterFn(item))
    ));
  
    // default to first page
    setActivePage(1);
  
    setStoryElements(generateStoryElements(filteredStories));
  }

  const generateFilterSections = () => {
    const elements = [];
    for (const category of filterCategories) {
      elements.push(
        <FilterSection
          filterCategory={category}
          filterData={filterData[category]}
          onFilterSelectionChange={(selectedFilters) => handleFilterSelectionChange(category, selectedFilters)}
          key={category}
        />
      );
    }

    return elements;
  }

  useEffect(() => {
    document.title = 'Discover Stories - PathFinder';
    const discoverTab = document.querySelector('#nav-discover');
    discoverTab.classList.add('selected');
    fetchStories();

    document.addEventListener('mousedown', handleClickOutsideSortMenu);

    return () => {
      discoverTab.classList.remove('selected');
      document.removeEventListener('mousedown', handleClickOutsideSortMenu);
    }
  }, []);

  useEffect(() => {
    if (!storyData) return;

    filterStories(selectedFilters);
  }, [storyData])

  return (
    <div className="discoverv2-component">
      {community && <CommunityHeader mode="discover" />}
      <div className="discover-main container">
      {storyElements ? <>
        <div className="filters-contribute">
          <a
            href={community ? "https://forms.gle/bzCfrZQUGKRXxMfr6" : "https://forms.gle/3nzcm2izVvcFgig2A"} 
            target="_blank" rel="noopener noreferrer" 
            className="contribute-link"
            onClick={() => {
              props.mixpanel.track('Clicked add story link', { from: 'stories' });
            }}>
            <button className="contribute-button">
              Add your Story
            </button>
          </a>
          <div className="filters-dropdown-container">
            <div className="filters-heading-container">
              <h4 className="filters-heading">Filters</h4>
              <div className="filters-mobile-toggle">
                {screenWidth < 768 && (filterIsOpen ? 
                  <ChevronUp onClick={() => setFilterIsOpen(false)}/>
                :
                  <ChevronDown onClick={() => setFilterIsOpen(true)}/>
                )}
              </div>
            </div>
            {filterIsOpen && <div className="filter-options">
              {generateFilterSections()}
            </div>}
          </div>
        </div>
        <div className="stories-section">
          {/* <DiscoverSearch /> */}
          <div className="sort-stories">
            {generateSortStories()}
          </div>
          <div className="stories">
            {paginate(storyElements)}
          </div>
          {<div className="pagination-container">{generatePagination()}</div>}
        </div></>
         
        : <div className="loading-container"><Loading mixpanel={props.mixpanel} /></div>
      }
      </div>
    </div>
  );
};

export default withRouter(DiscoverV2);
