import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ArrowRight, ChevronUp } from 'react-feather';
import { 
  getAllTermsLists, getJobsList, getTermTypes,
  searchForTerms, reverseSearch, getJobPercentages, 
  ResultsTooltip
} from '../../app';
import { capitalize, formatLink, processLink } from '../../util';
import { useIsMountedRef } from '../../hooks';
import './results.css';

const Results = (props) => {
  const [query, setQuery] = useState(''); // query input (for searching)
  const queryRef = useRef();
  const [results, setResults] = useState(null);
  const [resultsCount, setResultsCount] = useState(0);
  const [searching, setSearching] = useState(true);
  const isMountedRef = useIsMountedRef();
  const scrollToTopRef = useRef();

  const searchTerms = {'careers': getJobsList(), ...getAllTermsLists()};
  const termTypes = getTermTypes();

  useEffect(() => { // []
    props.history.listen(location => {
      if (!isMountedRef.current) return;
      let matchTerm = location.pathname.match(/\/results\/(.+)/);
      if (matchTerm) {
        setQuery(processLink(matchTerm[1], false));
      }
      return () => isMountedRef.current = false;
    });
    const queryProp = processLink(props.match.params.query, false);
    setQuery(queryProp);
    setResultsCount(0);

    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
      isMountedRef.current = false;
    }
  }, []);

  // Get suggestions and results
  useEffect(() => { // [query]
    if (query.length === 0) return;
    document.title = `Results for "${query}" - PathFinder`;
    
    let resultsObj = termTypes.reduce((obj, termType) => {
      obj[[termType]] = [];
      return obj;
    }, {});
    let typesMatched = []; // Term types where a match was found

    // Search each termsList to find term
    for (const [curTermType, termsList] of Object.entries(searchTerms)) {
      const termsFound = searchForTerms(query, termsList);
      if (termsFound.length === 0) {
        continue;
      }

      // Check for match
      const foundMatch = termsFound[0].toLowerCase() === query.toLowerCase() && curTermType !== 'careers';
      if (foundMatch) {
        typesMatched.push(curTermType);
      }

      // Store resulting terms in resultsObj; skip first if found match
      resultsObj[curTermType] = termsFound.slice(foundMatch);
    }
    setResultsCount(0);
    createResultElements(resultsObj, typesMatched);
  }, [query]);

  /**
   * Create JSX from resultsObj
   * @param {Object.<string, string[]>} resultsObj Object whose keys are 
   *  term types and values are an array containing the resulting terms 
   *  of that term type
   * @param {string[]} typesMatched
   */
  async function createResultElements(resultsObj, typesMatched) {
    let resultElements = [];

    // Create suggestion elements
    // const resultTypes = Object.keys(resultsObj);
    for (const [curTermType, resultTerms] of Object.entries(resultsObj)) {
      if (resultTerms.length === 0) continue;

      let resultsList = [];
      // const resultTerms = resultsObj[curTermType];
      const isCareerType = curTermType === 'careers';

      // Create elements for each result
      for (const term of resultTerms) {
        resultsList.push(
          <li 
            key={uuid()}
            className="result"
            onClick={() => {
              props.mixpanel.track('Clicked results page item', {
                type: isCareerType ? 'career' : 'suggestion',
                value: term
              });
              if (isCareerType) {
                props.history.push(`/career/${formatLink(term)}`);
              }
              else {
                setQuery(term);
                props.history.push(`/results/${formatLink(term)}`);
              }
            }}
            title={term}
          >
            <span>{term}</span>
            {isCareerType &&
            <ArrowRight className="arrow-right" preserveAspectRatio={'none'} />}
          </li>
        );

        setResultsCount(currCount => currCount + 1);
      }

      // Add results section if results were found
      if (resultsList.length > 0) {
        resultElements.push(
          <li key={uuid()}>
            <div className="results-section">
              <h3>{capitalize(curTermType)}</h3>
              <ul className="results-list">{resultsList}</ul>
              <hr />
            </div>  
          </li>
        );
      }
    }
    setSearching(false);
    if (resultElements.length > 0) {
      setResults(<ul>{resultElements}</ul>);
    }

    if (typesMatched.length === 0 || typesMatched.includes('careers')) {
      return;
    }

    // Display feedback for careers search
    resultElements.unshift(createCareersFoundElement(false));
    setResults(<ul>{resultElements}</ul>);

    // Search using each match type to find termType that yields the most results
    let searchResults = [];
    let termType = '';
    for (const curTermType of typesMatched) {

      const results = await reverseSearch(query, curTermType);
      if (results.length > searchResults.length) {
        searchResults = results;
        termType = curTermType;
      }
    }

    if (searchResults.length === 0) {
      resultElements.shift();
      setResults(resultElements);
      return;
    }

    // Calculate job percentage matches
    let jobPercentages = await getJobPercentages(searchResults, termType);

    // Abort display search results if user changed input
    if (!isMountedRef.current) return;
    else if (query !== queryRef.current?.innerText) return;

    setResultsCount(currCount => currCount + jobPercentages.length);

    // Create elements for job matches found
    let jobResultsElements = [];
    for (const entry of jobPercentages) {
      jobResultsElements.push(
        <li
          key={uuid()}
          className="result"
          onClick={() => {
            props.mixpanel.track('Clicked results page item', {
              type: 'result',
              value: entry.jobtitle
            });
            props.history.push(`/career/${formatLink(entry.jobtitle)}`);
          }}
          title={entry.jobtitle}
        >
          <span className="result-title">{entry.jobtitle}</span>
          <ArrowRight className="arrow-right" preserveAspectRatio={"none"} />
          <span className="percent-match-container">{entry.percentage}% match</span>
        </li>
      );
    };

    // Replace loading display with careers found
    resultElements[0] = createCareersFoundElement(true, jobResultsElements);
    setResults(<ul>{resultElements}</ul>);
  }

  /**
   * @param {boolean} doneLoading Pass in `true` if career results have been found.
   *    Otherwise, pass in `false` and don't pass in value for `resultElements`
   * @param {React.ReactElement[]} resultElements Array containing react elements
   *    for results found
   * @return {React.ReactElement} Results section element for career paths found
   */
  function createCareersFoundElement(doneLoading, resultElements) {
    const content = (doneLoading && resultElements)
      ? (<> 
        <ul className="results-list">{resultElements}</ul> 
        <hr /> 
      </>)
      : (<>
        <span className="spinner-border spinner-border-sm"></span>
        <span>Searching for careers...</span>
      </>);

    return (
      <li key={uuid()}>
        <div className="results-section">
          <h3>
            Career Paths Found
            <ResultsTooltip />
          </h3>
          {content}
        </div>
      </li>
    );
  }

  // Display scroll to top button if user scrolls down enough
  function handleScroll(evt) {
    if (!scrollToTopRef.current) return;
    const rootElement = document.documentElement;
    const scrollAmount = rootElement.scrollTop;
    if (scrollAmount > 500) {
      scrollToTopRef.current.classList.add('show-button');
    } else {
      scrollToTopRef.current.classList.remove('show-button');
    }
  }

  return (
    <div className="results-component container">
      {searching && 
        <div>
          <span className="spinner-border"></span>
          <span className="searching">Searching...</span>
        </div>
      }
      {!searching &&
        <div className="display-results">
          <h1>
            Results for "<span className="query-text" ref={queryRef}>{query}</span>"
          </h1>
          <p className="results-count">{resultsCount}
            {resultsCount === 1 ? ' result was ' : ' results were '}found.
          </p>
          <div className="result-sections">{results}</div>
        </div>
      }

      <div className="advanced-search-link-container">
        <Link
          to="/advanced-search"
          className="advanced-search-link"
          onClick={() => props.mixpanel.track('Clicked advanced search button')}
        >
          Advanced Search
        </Link>
      </div>
      <div 
        ref={scrollToTopRef}
        className="scroll-to-top"
        onClick={() => window.scroll({top: 0, behavior: "smooth"})}
      >
        <ChevronUp size="36" stroke="#6C63FF" />
      </div>
    </div>
  );
}

export default withRouter(Results);