import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading, NamePopup } from '../../components';
import { verifyUser } from "../../app";
import { useAuthDispatch } from '../../context';

const Verify = (props) => {
  const authDispatch = useAuthDispatch();
  const history = useHistory();
  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    const verify = async () => {
      const res = await verifyUser(authDispatch, token);
      if (res === 'Success') {
        history.push('/');
        location.reload();
      } else if (res === 'New user') {
        setIsNewUser(true);
      } else {
        alert('Something went wrong with signing in');
        history.push('/');
        location.reload();
      }
    }
    verify();
  }, []);

  return (
    <>
      {isNewUser ? <NamePopup /> : <Loading />}
    </>
  );
}


export default Verify;
