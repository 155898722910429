import React, { useState } from 'react';
import { getAPIPrefix } from '../../app';
import { ThumbsDown, ThumbsUp, Copy } from 'react-feather';
import ChatBox from './ChatBox/ChatBox';
import ChatSuggestion from './ChatSuggestion/ChatSuggestion';
import ChatResponse from './ChatResponse/ChatResponse';
import ChatPrompt from './ChatPrompt/ChatPrompt';
import { useSubdomain } from '../../hooks';
import { getCommunityData } from '../../app';
import { v4 as uuid } from 'uuid';
import './ai_chat.css';

const reqHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'PfApiKey': process.env.REACT_APP_PF_APIKEY,
}

const AIChat = ({ mixpanel, active, setActive }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [showBetaMessage, setShowBetaMessage] = useState(false);
  const [isBetaMessageClosed, setIsBetaMessageClosed] = useState(false); 
  const [suggestedPrompts, setSuggestedPrompts] = useState(
    [
      "Where does artificial intelligence come into play?",
      "What skills do you need in a Product design role?",
      "What are the pros and cons of working in startups?"
    ]
  );
  const apiPrefix = getAPIPrefix();

  const closeBetaMessage = () => {
    setIsBetaMessageClosed(true);
    setShowBetaMessage(false);
  };

  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));

  async function getAIChatResponse(newPrompt) {
    setChatHistory(prevState => [...prevState, <ChatPrompt mixpanel={mixpanel} content={newPrompt} key={uuid()} />]);
    try {
      const res = await fetch(`${apiPrefix}/chat/getAIChatResponse/${newPrompt}`, {
        method: 'GET', headers: reqHeaders
      });
      if (!res.ok) {
        throw new Error(`Error: Status ${res.status}`);
      }
      const promptReply = await res.json();
      setChatHistory(prevState => [...prevState, 
        <ChatResponse text={promptReply["GPTResponse"]} 
          sources={promptReply["postIds"]} 
          resources={promptReply["resources"]}
          key={uuid()}/>]);
    }
    catch (error) {
      console.error(error);
      setChatHistory(prevState => [...prevState, 
        <ChatResponse text={`Sorry for the inconvenience! The service has encountered a technical issue. Please contact team@pathfinder.fyi. ${error.message}`} 
          key={uuid()}/>]);
    }
    setIsLoading(false);
  }

  async function getSuggestedPrompts(newPrompt) {
    try {
      const res = await fetch(`${apiPrefix}/chat/generateFollowUpPrompts/${newPrompt}`, {
        method: 'GET', headers: reqHeaders
      });
      if (!res.ok) {
        throw new Error(`Error: Status ${res.status}`);
      }
      const promptReply = await res.json();
      setSuggestedPrompts(promptReply);
    }
    catch (error) {
      console.error(error);
    }
  }

  function handleOnSubmit(newPrompt, inputUI) {
    if (!newPrompt) {
      return;
    }

    mixpanel.track('AI Chat submission', {
      community: community ? community.name : 'none',
      prompt: newPrompt
    });

    setActive(true);
    setIsLoading(true);
    getAIChatResponse(newPrompt);
  
    if (inputUI) {
      inputUI.current.value = "";
    }
  
    if (!isBetaMessageClosed) {
      setShowBetaMessage(true);
    }

    getSuggestedPrompts(newPrompt);
  }

  return (
    <div className="ai-chat container">
      {showBetaMessage && !isBetaMessageClosed && (
        <div className="beta-message">
          <div className="message-content">
            Our AI is currently in beta. We are actively improving it to make fewer mistakes.{' '}
            <a 
              href="https://docs.google.com/document/d/1XZ2yB85FtYVyrI-o_kukJlYEJTDpyibdvnkzyEJdeq8/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer">
                Learn More
            </a>
          </div>
          <div className="close-button" onClick={closeBetaMessage}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect x="1" y="1" width="22" height="22" rx="3" stroke="#3C6CE9" strokeWidth="2" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#3C6CE9"
              />
            </svg>
          </div>
        </div>
      )}
      {active ? (
        <div className="blue-area">
          <div className="scroll-container">
            <div>
              {chatHistory}
              {isLoading && (<div className="loading-bubble-container">
                <div className="chat-bubble-container">
                  {community ?
                      <img src={"./community/" + subdomain + "-logo.png"} className="chat-bubble-icon" />
                    : <img src="./logo.png" className="chat-bubble-icon pf-avatar"/>
                  }
                </div>
                <div className="loading-bubble-content">
                  <span className={"chat-response-title " + subdomain + "-color"}>{community ? community.name : "PathFinder"}</span>
                  <div className="loading-bubble">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                  </div>
                </div>
              </div>)}
              {!isLoading && (
                <ChatSuggestion 
                  mixpanel={mixpanel}
                  type="long"
                  data={suggestedPrompts} 
                  onClick={handleOnSubmit} 
                  setShowBetaMessage={setShowBetaMessage} />
              )}
            </div>
            <ChatBox handleSubmit={handleOnSubmit} />
          </div>
        </div>
      ) : (
        <div className="chat-container">
          <h1 className="chat-header">{community ? 'Member AI Search' : 'Your personal career co\u2011pilot'}</h1>
          <h2 className="chat-subtext">{community ? 'Type in your own words to find exact members that matches your interest' : 'Our AI finds the exact person and article that can answer your career questions'}</h2>
          <div className="chat-input-container">
            <ChatBox mixpanel={mixpanel} handleSubmit={handleOnSubmit} />
            <ChatSuggestion 
              mixpanel={mixpanel}
              type="short"
              data={community && community.AIChat ? community.AIChat.suggestedPrompts : suggestedPrompts} 
              onClick={handleOnSubmit} 
              setShowBetaMessage={setShowBetaMessage}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIChat;