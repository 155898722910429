import React, { useState } from 'react';
import { AIChat, CommunityHeader } from '../../../components';
import { useSubdomain } from '../../../hooks';
import { getCommunityData } from '../../../app';

import './community_landing.css';

const CommunityLanding = ({ mixpanel, active, setActive }) => {
  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));

  return (
    <div className={"community-landing " + subdomain + "-background"}>
      {!active &&<CommunityHeader mode="landing"/>}
      {active && <div className={"community-landing-header-active"}></div>}
      <AIChat mixpanel={mixpanel} active={active} setActive={setActive}/>
      {active &&
      <div className="logo-container container">
        <img 
          src={"./community/" + subdomain + "-logo.png"} 
          alt={community.name + " logo"}
          className="community-logo" />
      </div>}
    </div>
  );
};

export default CommunityLanding;