import React, { useState, useEffect, useRef, forwardRef } from 'react';
import Comment from './Comment/Comment';
import './comments.css';
import { useAuthState } from '../../context';
import { postComment, sendCommentNotification, getComments } from '../../app';

/**
 * @typedef {import('./Comment/Comment').CommentData} CommentData
 */

// props
const Comments = forwardRef(function Comments({ currentPost }, ref) {
  /** @type {[CommentData[], React.Dispatch<React.SetStateAction<CommentData[]>>]} */
  const [comments, setComments] = useState([]); //Comments loaded from MongoDB
  const submitButtonRef = useRef();

  // Get Comments from MongoDB

  useEffect(() => {
    if (!currentPost) return;

    const fetchComments = async () => {
      const returnComments = await getComments(currentPost.post._id);
      if (!returnComments) {
        setComments([]);
      } else {
        setComments(returnComments.comments);
      }
    };

    fetchComments();

  }, [currentPost]);

  // Grab user's Google info
  const userDetails = useAuthState();
  // Grab Current Date
  const currentDate = new Date();
  const d = String(currentDate.getDate());
  const mm = parseInt(currentDate.getMonth());
  const yyyy = currentDate.getFullYear();
  const monthsAbbreviated = [
    'Jan.',
    'Feb.',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];
  const month = monthsAbbreviated[mm];
  const commentDate = `${month} ${d}, ${yyyy}`;

  const isProdSite = () => {
    const hostname = window.location.hostname;
    if (hostname === 'localhost' || hostname === 'pathfinder-test.netlify.app') {
      return false;
    }
    return true;
  }

  const submitComment = (event) => {
    event.preventDefault();

    // User must be signed in to comment
    if (userDetails.user) {
      const submitData = new FormData(event.target);
      const commentsText = submitData.get('comment-entry');

      postComment(userDetails.user._id, currentPost.post._id, commentsText);
      const commenterName = `${userDetails.user.firstName}` +
        (userDetails.user.lastName ? ` ${userDetails.user.lastName}` : '');
      if (isProdSite) {
        sendCommentNotification(currentPost.post.user.email, currentPost.post._id,
          currentPost.post.user.fullName,
          commenterName,
          commentsText);
      }

      if (comments) {
        // insert new comment locally into current Array
        setComments((prev) => [
          ...prev,
          {
            content: commentsText,
            date: new Date().toISOString(),
            reactions: {},
            user: {
              fullName:
                userDetails.user.firstName + ' ' + userDetails.user.lastName,
              avatarUrl: userDetails.user.avatarUrl,
            },
          },
        ]);
      } else {
        // insert new comment locally into new Array
        setComments([
          {
            content: commentsText,
            date: new Date().toISOString(),
            reactions: {},
            user: {
              fullName:
                userDetails.user.firstName + ' ' + userDetails.user.lastName,
              avatarUrl: userDetails.user.avatarUrl,
            },
          },
        ]);
      }

      document.getElementById('comment-entry').value = '';
      // submitData.delete('comment-entry');
      // formData.delete('comment-entry');
    } else {
      alert('Please sign in to give a reaction.');
    }
  };

  const handleInput = (evt) => {
    submitButtonRef.current.disabled = evt.target.value.trim() === '';
  }

  return (
    <div className="comments-component" ref={ref}>
      {/* comments section */}
      <div className="comment-entry-container">
        <form action="submit" onSubmit={submitComment} onInput={handleInput}>
          <textarea
            name="comment-entry"
            id="comment-entry"
            placeholder="Leave a comment or question"
          ></textarea>
          <button 
            className="submit-button" 
            type="submit" 
            disabled
            ref={submitButtonRef}>
              Post
          </button>
        </form>
      </div>
      {comments && comments.length > 0 ? (
        <section className="comments-container">
          <ul className="comments">
            {comments.map((commentData) => (
              <li key={commentData._id}>
                <Comment
                  setComments={setComments}
                  commentData={commentData}
                />
              </li>
            ))}
          </ul>
        </section>
      ) : null}
    </div>
  );
});

export default Comments;
