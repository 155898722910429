import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ArrowRight, X } from 'react-feather';
import {
  getAllTermsLists, getJobsList, getTermTypes,
  searchForTerms, reverseSearch, getJobPercentages,
  ResultsTooltip
} from '../../app';
import { useIsMountedRef } from '../../hooks';
import { capitalize, formatLink } from '../../util';
import './search_bar.css';

let searchFn = null;
let placeholder = '';

/**
 * @param {OverridedMixpanel} mixpanel
 * @param {string} type Type of search bar functionality. `'main'` for default.
 */
const SearchBar = ({ mixpanel, type='main' }) => {
  const [searchResultEls, setSearchResultEls] = useState([]);
  const [prevResultEls, setPrevResultEls] = useState([]);
  const [keywordSuggestionEls, setKeywordSuggestionEls] = useState();
  const containerRef = useRef();
  const searchBarRef = useRef();
  const cursorRef = useRef(-1);
  const resultsRef = useRef();
  const isMountedRef = useIsMountedRef();
  let history = useHistory();

  const termTypes = getTermTypes();
  const searchTerms = {careers: getJobsList(), ...getAllTermsLists()};

  const seeAllResultsEl = 
    <li 
      className="see-all-results" 
      onClick={handleSubmit}
      key={uuid()}
    >
      <span>See all results</span>
    </li>;

  async function handleMainSearch() {
    let searchInput = searchBarRef.current.value.toLowerCase();
    checkNotEmpty();
      
    resultsRef.current = [];
    cursorRef.current = -1;
    
    // Emojis that appear next to category headers and for term labels
    const emojis = {'careers': '💼', 'languages': '💻', 'skills': '🤹‍♂️', 'degrees': '🎓', 'education': '🏫', 'industries': '🏭'};

    // Initial suggestion terms for the user
    if (searchInput.length === 0) {
      const suggestions = [
        'JavaScript', 'Kubernetes', 'Statistics', 'PhD', 
        'Computer Hardware'
      ];
      const termLabels = ['Career','Language','Skill','Degree','Education','Industry'];

      const suggestionEls = [
        <li 
          key={uuid()}
          className="result-header search-element"
        >
          <span><b>Suggestions</b></span>
        </li>,
         
        <li 
          key={"Software Engineer"}
          className="result search-element initial-suggestion"
        >
        <Link to="career/Software-Engineer" key={uuid()} className="result-link">
            <span>Software Engineer</span>
            <ArrowRight className="arrow-right" preserveAspectRatio={"none"} />
            <span className="suggestion-term-label">{termLabels[0]} {emojis['careers']}</span>
        </Link>
        </li>
      ];
      resultsRef.current.push({'jobtitle': 'Software Engineer', type: 'job'});
      for (const [i, term] of suggestions.entries()) {
        suggestionEls.push(
          <li 
            key={uuid()}
            onClick={(evt) => {
              handleSelectResult(term, 'suggestion');
            }}
            className="result search-element initial-suggestion"
            style={{borderRadius: i===4 ? '0 0 25px 25px' : '0'}} // Curve out bottom corners of last suggestion
          >
            <div className="result-term">
              <span>{term}</span>
              <span className="suggestion-term-label">{termLabels[i+1]} {emojis[Object.keys(emojis)[i+1]]}</span>
            </div>
          </li>
        )
        resultsRef.current.push({'jobtitle': term, type: 'suggestion'})
      }
      setSearchResultEls(suggestionEls);
      return;
    }

    // Trim leading spaces
    searchInput = searchInput.replace(/^ +/, '')
    const inputLen = searchInput.length;

    /** @type {{ [termType]: string[] }} */
    let suggestions = termTypes.reduce((obj, termType) => {
      obj[[termType]] = [];
      return obj;
    }, {});
    
    let resultElements = [];
    /** Stores the termType values where the term was found
     *  @type {string[]} */
    let typesMatched = [];

    // Search each list of terms
    for (const [termType, termsList] of Object.entries(searchTerms)) {
      const isCareerType = termType === 'careers';

      const termsFound = searchForTerms(searchInput, termsList);
      if (termsFound.length === 0) continue;
      const foundMatch = !isCareerType && termsFound[0].toLowerCase() === searchInput;
      if (foundMatch) typesMatched.push(termType);

      // Store suggestions
      // Limit to 4 results; skip first if found match
      suggestions[termType] = termsFound.slice(foundMatch, 4 + foundMatch);
    }

    // Create suggestion elements
    for (const [termType, termsList] of Object.entries(suggestions)) {
      if (termsList.length === 0) continue;

      // Suggestion header
      resultElements.push(
        <li 
          key={uuid()}
          className="result-header search-element"
        >
          <span>{emojis[termType]}  <strong className="search-element">{capitalize(termType)}</strong></span>
        </li>
      ); 

      // Suggestion items
      // for (let termsListEntry of termsList) {
      //   const isCareerType = termType === 'careers';
      //   if (!termsListEntry) {
      //     continue;
      //   }
      //   resultElements.push(
      //     <li 
      //       key={uuid()} 
      //       onClick={() => handleSelectResult(termsListEntry, isCareerType ? 'job' : 'suggestion')}
      //       className="result search-element"
      //     >
      //       {termsListEntry.substring(0, inputLen)}<strong>{termsListEntry.substring(inputLen)}</strong>
      //       {isCareerType &&
      //       <ArrowRight className="arrow-right" preserveAspectRatio={'none'} />}
      //     </li>
      //   ); 
      //   resultsRef.current.push({'jobtitle': termsListEntry, "type": isCareerType ? 'job' : 'suggestion'});
      // }

      for (let termsListEntry of termsList) {
        const isCareerType = termType === 'careers';
        if (!termsListEntry) {
          continue;
        }
        let resultElement;
        if (isCareerType) {
          resultElement = (
            <li 
              key={uuid()}
              className="result search-element"
            >
              <Link
                key={uuid()}
                className="result-link"
                to={`/career/${termsListEntry}`}
                onClick={() => handleSelectResult(termsListEntry, 'job')}
              >
                <span>{termsListEntry.substring(0, inputLen)}<strong>{termsListEntry.substring(inputLen)}</strong></span>
                <ArrowRight className="arrow-right" preserveAspectRatio={'none'} />
              </Link>
            </li>
          );
        } else {
          resultElement = (
            <li
              key={termsListEntry}
              className="result search-element"
              onClick={() => handleSelectResult(termsListEntry, 'suggestion')}
            >
              <div className="result-term">
                <span>{termsListEntry.substring(0, inputLen)}<strong>{termsListEntry.substring(inputLen)}</strong></span>
              </div>
            </li>
          );
        }
        resultElements.push(resultElement);
        resultsRef.current.push({'jobtitle': termsListEntry, "type": isCareerType ? 'job' : 'suggestion'});
      }
      
    }

    if (resultElements.length === 0 && typesMatched.length === 0) {
      setSearchResultEls([
        <li key={uuid()} className="no-matches search-element">
          <span>No terms found</span>
          <a 
            href="https://docs.google.com/forms/d/e/1FAIpQLSfV5_ojoz--RJTsFPuJISVTEPuHS2389-Ewk-lDwBIc6CgcaQ/viewform?usp=sf_link"
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => mixpanel.track('Clicked feedback link')}
            className="feedback-link"
          >
            something missing? let us know!
          </a>
        </li>
      ]);
      return;
    }

    // Set suggestions
    setSearchResultEls([...resultElements, seeAllResultsEl]);
    if (typesMatched.length === 0) return;
    
    // Feedback while loading data
    resultElements.unshift(
      <li 
        key={uuid()}
        className="result-header search-element"
      >
        <span>🔎 <i><strong className="search-element">Career Paths Found</strong></i></span>
        <ResultsTooltip />
      </li>,
      <li
        key={uuid()}
        className="search-element"
      >
        <div className="result-spinner">
          <span className="spinner-border spinner-border-sm"></span>
          <span>Searching for careers...</span>
        </div>
      </li>
    );

    setSearchResultEls([...resultElements, seeAllResultsEl]);

    // Start using each match type to find termType that yields the most results
    /** @type {{ jobtitle: string, count: number }[]} */
    let searchResults = [];
    let termType = '';
    for (const curTermType of typesMatched) {
      const results = await reverseSearch(searchInput, curTermType);
      if (results.length > searchResults.length) {
        searchResults = results;
        termType = curTermType;
      }
    }

    if (searchResults.length === 0) {
      const noMatches = [
        <li className="no-matches search-element" key={uuid()}>
          <span>No results found</span>
        </li>
      ]
      setSearchResultEls(noMatches);
      setPrevResultEls(noMatches);
      return;
    }

    let percentages = await getJobPercentages(searchResults, termType);

    // Abort displaying search results if user changed input
    if (!isMountedRef.current) return;
    else if (searchInput !== searchBarRef.current.value.toLowerCase()) {
      searchFn();
      return;
    }

    percentages = percentages.slice(0, 5);
    resultElements = resultElements.slice(2); // Remove loading feedback

    // Create elements for matches
    let jobResultElements = [];
    let matchResults = [];
    for (const entry of percentages) {
      jobResultElements.push(
        <li 
          key={entry.jobtitle}
          className="result search-element"
        >
          <Link 
            to={`/careers/${formatLink(entry.jobtitle)}`} 
            className="result-link"
          >
            <span className="match">{entry.jobtitle}</span>
            <ArrowRight className="arrow-right" preserveAspectRatio={'none'} />
            <span className="percent-match-container">{entry.percentage}% match</span>
          </Link>
        </li>
      );
      matchResults.push({'jobtitle': entry.jobtitle, type: 'job'});
    }
    // Add results to resultsRef
    resultsRef.current = matchResults.concat(resultsRef.current);
    
    // Add results header to top of list
    jobResultElements.unshift(
      <li 
        key={uuid()}
        className="result-header career-results-header search-element"
      >
        <span>🔎  <i><strong className="search-element">Career Paths Found</strong></i></span>
        <ResultsTooltip />
      </li>
    );
    
    // Add new job result elements to results
    resultElements = jobResultElements.concat(resultElements);
    setSearchResultEls([...resultElements, seeAllResultsEl]);
    setPrevResultEls([...resultElements, seeAllResultsEl]);

    // Deselect selected element
    const selectedElement = document.querySelectorAll('.result')[cursorRef.current];
    selectedElement?.classList.remove('selected');
    cursorRef.current = -1;
  }

  useEffect(() => { // []
    switch (type) {
      case 'main': 
        searchFn = handleMainSearch;
        placeholder = 'Search for a tech career path, hard skill, etc.';
        break;

      default:
        console.error(`Error: Invalid search bar function type: ${type}`);
        return;
    }

    document.addEventListener('keydown', docKeydown);
    return () => {
      isMountedRef.current = false;
      document.removeEventListener('keydown', docKeydown);
    }
  }, []);

  // Cycle through keyword suggestions every once in awhile
  useEffect(() => {
    handleKeywordSuggestions();
    const interval = setInterval(() => {
      handleKeywordSuggestions();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Adjust height of component manually to preserve position absolute
  useEffect(() => { // [searchResultEls]
    const baseHeight = 50;
    
    if (searchResultEls.length === 0 || searchBarRef.current !== document.activeElement) {
      containerRef.current.style.height = `${baseHeight}px`;
      return;
    }

    const itemHeight = 30;
    let newHeight = baseHeight + searchResultEls.length * itemHeight;

    // Adjust height if see-all-results is being displayed
    const seeAllHeight = 40;
    const lastResultEl = searchResultEls[searchResultEls.length-1];
    if (lastResultEl.props.className === 'see-all-results') {
      newHeight += seeAllHeight - itemHeight;
    }

    containerRef.current.style.height = `${newHeight}px`;
  }, [searchResultEls]);

  function docKeydown(evt) {
    if (evt.ctrlKey) {
      if (evt.key === '/' || evt.key === 'k') {
        searchBarRef.current.focus();
      }
    }
  }

  function handleSelectResult(value, type) {
    mixpanel.track('Selected search item', {
      type: type,
      value: value,
      searchValue: searchBarRef.current.value
    });

    if (type === 'job') {
      history.push(`/career/${formatLink(value)}`);
    } else if (type === 'suggestion') {
      searchBarRef.current.value = value;
      searchFn();
    }
    /* replacement code for above to create Link redirect (not required as of 4/2/23)
    // if (type === 'job') {
    //   return (
    //     <Link to={`/career/${formatLink(value)}`}>
    //       Career Page
    //     </Link>
    //   );
    // } else if (type === 'suggestion') {
    //   searchBarRef.current.value = value;
    //   searchFn();
    // }
    */
  }

  function handleFocus(evt) {
    if (prevResultEls.length > 0) {
      setSearchResultEls(prevResultEls);
    } else {
      searchFn();
    }
  }

  function handleBlur(evt) {
    if (!isMountedRef.current) return;
    setPrevResultEls(searchResultEls);
    setSearchResultEls([]);
  }

  function handleKeywordSuggestions() {
    let keywords = [
      'python','hadoop','statistics','kubernetes','javascript','mongodb','machine learning','SQL','numpy','react'
    ];
    let elements = [];
    let terms = [];
    if (keywords.indexOf(searchBarRef.current.value) != -1) {
      keywords.splice(keywords.indexOf(searchBarRef.current.value), 1);
    }
    for (let i=0; i<5; i++) {
      let j=Math.floor(Math.random()*keywords.length);
      terms.push(keywords[j]);
      keywords.splice(j,1);
    }
    for (const term of terms) {
      elements.push(
        <div key={uuid()} className={searchBarRef.current.value == term ? "keyword-suggestion-button selected" : "keyword-suggestion-button"} onClick={(e) => {
          searchBarRef.current.focus()
          searchBarRef.current.value = term;
          //e.target.className = "keyword-suggestion-button selected";
          handleSelectResult(term, 'suggestion');
          handleKeywordSuggestions();
        }}>
          {term}
        </div>
      );
    }
    setKeywordSuggestionEls(<div className="keyword-buttons-container">{elements}</div>);
  }
  function handleKeydown(evt) {
    const key = evt.key;
    if (key === 'Escape') {
      searchBarRef.current.blur();
    }
    else if (key === 'Enter') {
      const selected = resultsRef.current[cursorRef.current];
      if (selected) {
        handleSelectResult(selected.jobtitle, selected.type);
        cursorRef.current = -1;
      } else {
        handleSubmit();
      }
    }
    else if (key === 'ArrowDown' || key === 'ArrowUp') {
      const index = cursorRef.current;

      // Increments/decrements cursorRef based on direction, clamped to number of results
      const endIdx = resultsRef.current.length-1;
      cursorRef.current = (key === 'ArrowDown') 
        ? index < endIdx
          ? index+1 : 0
        : index > 0 
          ? index-1 : endIdx;
      const resultElements = document.getElementsByClassName('result');
        
      // Arrow key selection visual feedback
      const prev = resultElements[index];
      prev?.classList.remove('selected');

      const selected = resultsRef.current[cursorRef.current];
      if (selected) {
        searchBarRef.current.value = selected ? selected.jobtitle : null;
        
        // Remove excessive entries in resultsRef that may have been added from searching for a term that is present in more than 1 category (example terms: Data Science, Computer Hardware, etc.)
        if (resultElements.length !== resultsRef.current.length) {
          resultsRef.current = resultsRef.current.splice(resultElements.length);
        }
        resultElements[cursorRef.current].classList.add('selected');
      }
      checkNotEmpty();
      // Prevent default up/down behavior
      evt.preventDefault();
    }
  }

  
  function handleSubmit(evt) {
    const searchVal = searchBarRef.current.value;

    if (searchVal.length === 0 || searchVal.trim().length === 0) return;

    searchVal.replace(/^ +/, ''); // Trim leading spaces
    history.push(`/results/${formatLink(searchVal)}`);
  }

  /* replacement code for above to create Link redirect (not required as of 4/2/23)
  // function handleSubmit(evt) {
  //   const searchVal = searchBarRef.current.value;
  
  //   if (searchVal.length === 0 || searchVal.trim().length === 0) return;
  
  //   searchVal.replace(/^ +/, ''); // Trim leading spaces
  //   return (
  //     <Link to={`/results/${formatLink(searchVal)}`}>
  //      Results Page
  //     </Link>
  //   );
  // }
  /*

  /**
   * Checks if search bar is empty to determine if placeholder should be displayed
   */
  function checkNotEmpty() {
    if (searchBarRef.current.value.length !== 0) {
      searchBarRef.current.classList.add('not-empty')
    } else {
      searchBarRef.current.classList.remove('not-empty');
    }
  }

  return (
    <div 
      className="search-bar-component" 
      onMouseDown={(evt) => {
        // Prevent input blur when selecting result
        // evt.preventDefault();
        // searchBarRef.current.focus();
      }}
      onClick={(evt) => {
        mixpanel.track('Clicked on search bar', { path: history.location.pathname });
      }}
    >
      <div 
        className="search-bar-container"
        ref={containerRef}
      >
        <span 
          className="icon search-element search-icon"
          onClick={handleSubmit}
        >
          <i className="fa fa-search"></i>
        </span>
        <input 
          type="text" 
          className="search-element"
          name="search-bar"
          id="search-bar"
          spellCheck="false"
          tabIndex="1"
          autoComplete="off"
          ref={searchBarRef}
          onChange={searchFn}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeydown}
        />
        <X
          className="x-icon"
          onClick={() => {
            searchBarRef.current.value = '';
            searchBarRef.current.focus();
            searchFn();
            setPrevResultEls([]);
          }}
        />
        <span className="label-placeholder">{placeholder}</span>
        <label htmlFor="search-bar" className="input-placeholder">{placeholder}</label>
        {searchBarRef.current === document.activeElement && 
          <div 
            className="search-results"
            onMouseDown={(evt) => {
              evt.preventDefault();
              searchBarRef.current.focus();
            }}
          >
            <ul>{searchResultEls}</ul>
          </div>
        }
       {keywordSuggestionEls ? <div className="search-bar-suggestions-container">
          {keywordSuggestionEls}
        </div> : null}
      </div>
    </div>
  )
}

export default SearchBar;