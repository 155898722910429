import React, { useState, useRef, useEffect } from 'react';
import {ReactComponent as Heart} from '../../assets/heart.svg';
import {ReactComponent as Like} from '../../assets/like.svg';
import {ReactComponent as Surprise} from '../../assets/surprise.svg';
import {ReactComponent as AddReaction} from '../../assets/add_reaction.svg';
import { updateCommentReaction, updatePostReaction } from '../../app.js'
import Tooltip from '@mui/material/Tooltip';
import ReactionButtonV2 from './ReactionButtonV2/ReactionButtonV2.jsx'
import { useAuthState } from '../../context/index.js';
import './reactions.css';

const Reactions = ({ data, setData, type }) => {
  const reactionArr = ['Love', 'Like', 'Surprise']
  const [reactionModalOpen, setReactionModalOpen] = useState(false);
  const [reactionElements, setReactionElements] = useState([]);
  const userDetails = useAuthState();

  const reactionsRef = useRef(null);
  const addReactionButtonRef = useRef(null);

  const handleReaction =
    (contentId, reaction) => async (e) => {
      if (type && type === 'story-preview') {
        return;
      }

      e.stopPropagation();
      if (userDetails.user) {
        const updatedData = (type && type === "post") ?
          await updatePostReaction(userDetails.user._id, contentId, reaction)
          : await updateCommentReaction(userDetails.user._id, contentId, reaction)
        setData(updatedData);
        setReactionModalOpen(false);
      } else {
        alert('Please sign in to comment.');
      }
  }
  
  const handleAddReaction = (e) => {
    e.stopPropagation();
    setReactionModalOpen(!reactionModalOpen);
  }

  const initElements = () => {
    // Reaction Details Popover
    let elements = [];
    if (data.reactions) {
      for (const reaction of Object.keys(data.reactions)) {
        if(reactionArr.includes(reaction) && (data.reactions[reaction].length > 0)){
          elements.push(
            <ReactionButtonV2
              key={userDetails.user?._id+reaction}
              data={data}
              type={type}
              userId={userDetails.user?._id}
              reaction={reaction}
              handleReaction={handleReaction}/>
          );
        }

        // Display maximum of two reactions for StoryPreview
        if (type === "story-preview" && elements.length === 2) break;
      }
    }

    // push AddReaction button to array of buttons if
    // not all possible reaction options are displayed
    if (elements.length < reactionArr.length && type !== "story-preview") {
      elements.push(
        <span key="AddReaction">
          <button className="reaction-button" 
            ref={addReactionButtonRef}
            onClick={handleAddReaction}>
            <AddReaction/> +
          </button>
          {reactionModalOpen &&
            <div className="reaction-modal-container" onClick={(evt) => evt.stopPropagation()}>
              <div className="reaction-modal-content">
                  <Tooltip title = "Love" placement="top">
                    <Heart onClick={handleReaction(data._id, "Love")}
                      className="reaction-icon" />
                  </Tooltip>
                  <Tooltip title = "Like" placement="top">
                    <Like onClick={handleReaction(data._id, "Like")}
                      className="reaction-icon"/>
                  </Tooltip>
                  <Tooltip title = "Surprise" placement="top">
                    <Surprise onClick={handleReaction(data._id, "Surprise")}
                      className="reaction-icon"/>
                  </Tooltip>
              </div>
            </div>
          }
        </span>
      );
    }

    setReactionElements(elements);
  }

  // Reaction modal closes when user clicks outside of it
  useEffect(() => {
    const handleReactionModalClickOutside = (e) => {
      if (reactionsRef.current && !reactionsRef.current.contains(e.target)) {
        setReactionModalOpen(false);
      }
    }
    
    initElements();

    document.addEventListener('mousedown', handleReactionModalClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleReactionModalClickOutside);
    }
  }, [])

  return(
    <div className="reaction-container" ref={reactionsRef}>
      {reactionElements}
    </div>);
  }

export default Reactions;