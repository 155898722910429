import React from 'react';
import './commentReply.css';

const CommentReply = ({ active }) => {
  if (!active) {
    return null;
  }

  const replyToComment = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const replyData = formData.get('reply-comment');
    document.getElementById('reply-comment').value = '';
  };

  return (
    <div className="commentReply-container">
      <div className="commentReply-body">
        <div className="commentReply-top">
          <form onSubmit={replyToComment}>
            <textarea
              name="reply-comment"
              type="text"
              placeholder="Type a reply..."
              id="reply-comment"
              required
            ></textarea>
            <button className="commentReply-submit" type="submit">
              <img src="/icons/submitArrow.png" alt="Reply button" />
            </button>
          </form>
        </div>

        <div className="commentReply-bottom">Commenting as Molly S.</div>
      </div>
    </div>
  );
};

export default CommentReply;
