import React, { useEffect } from 'react';
// import { withRouter } from 'react-router-dom';
import styles from './notfound.module.css';

const NotFound = (props) => {
  return (
    <div className="container">
      <div className={styles.notfound}>
        <div className={styles.text}>Page not found</div>
        <button 
          type="button" 
          className={"btn " + styles.backButton} 
          onClick={() => history.back()}
        >
          Go back
        </button>
      </div>
    </div>
  );
}

export default NotFound;