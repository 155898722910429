import React, { useState, useEffect } from 'react';
import { getCompanyLogoURL, getComments } from '../../../app.js';
import { Link } from 'react-router-dom';
import Reactions from '../../Reactions/Reactions.jsx';
import './story_preview.css';

const sampleStory = {
  "_id": "63ba0d21da4128291409cd06",
  "company": "NVIDIA",
  "jobTitles": [
    "Software Engineer",
    "Frontend Engineer"
  ],
  "experienceLevel": "Intern",
  "officialTitle": "Software Engineer",
  "team": "AI for Gaming and Graphics",
  "_roleId": "63ba0d21da4128291409cd06",
  "post": {
    "_id": "63ba0d6eda4128291409cfab",
    "userId": "63ba0d243eda3a8910f205a9",
    "roleId": "63ba0d21da4128291409cd06",
    "date": "2021-12-17T11:58:08.793Z",
    "responses": [
      {
        "question": "TLDR: What do you do at your job?",
        "answer": "I develop and design new features or improve existing ones for an image quality comparison tool with the goal of saving the user a lot of time from using a general-purpose editing software."
      },
      {
        "question": "Can you explain what you (or your team) is currently building and what your role entails as part of this team?",
        "answer": "Currently, we are building a tool to streamline the task of comparing and analyzing the quality of videos and images together. My role is to implement desired features from end users in an easy to use way and while improving comparisons. "
      },
      {
        "question": "What's the process of getting this job?",
        "answer": "First, make sure to apply! Preferably with a referral or through a career fair. Personally, I got this internship through a career fair where I was able to use a “special link” to submit my resume. Then, I got contacted for an interview. I am not very good at leetcode because I just simply don't practice those questions. However, I was able to *shine* in my interview because I had 2-3 interesting and diverse projects that I was able to talk about in technical detail! Projects > Leetcode. For interview prep, I mainly focus on talking about my projects, my accomplishments and then behavioral questions. Even though I don’t practice leetcode, I try my best. Articulating your thought process is most important.  "
      },
      {
        "question": "What hard skills do you use for your job?",
        "answer": "Electron, React, Typescript, JSX, scss, Git and then I had to write a few bash scripts "
      },
      {
        "question": "What key things helped you the most in getting this job?",
        "answer": "I had a full stack project that was essentially a very simple version of Google Maps. I used React, Javascript, JSX, and Java. Then, I talked about a wearable device I programmed (Using C for the wearable functions and Python for data analysis) to help people with inaccurate depth perception from tripping/getting injured. Also, I had past internship experiences but the projects were not so interesting so I talked quickly about them. The person interviewing me was very interested in my projects and had a lot of questions about them!"
      },
      {
        "question": "What's your favorite thing about your job/career?",
        "answer": ""
      },
      {
        "question": "What don't you like about job/career?",
        "answer": ""
      }
    ],
    "reactions": {
      "Love": [
        "63ba0d259c5da13b044a6c83"
      ],
      "Like": [],
      "Surprise": ["fwefs"]
    },
    "_userId": "63ba0d243eda3a8910f205a9",
    "user": {
      "_id": "63ba0d243eda3a8910f205a9",
      "firstName": "Daiana",
      "lastName": "",
      "email": "daybilbao@gmail.com",
      "dateJoined": "2021-12-17T11:58:08.793Z",
      "isAnonymous": false,
      "avatarUrl": "",
      "roleIds": [
        "63ba0d21da4128291409cd06"
      ],
      "fullName": "Daiana"
    }
  }
};

const StoryPreview = ({ story }) => {
  const [data, setData] = useState(story);
  const [commentCount, setCommentCount] = useState(0);

  useEffect(() => {
    const fetchCommentCount = async () => {
      const comments = await getComments(data.post._id);
      setCommentCount(Object.keys(comments).length ? comments.comments.length : 0);
    };
    fetchCommentCount();
  }, []);

  const getTitle = () => {
    const isSpotlight = data.post.spotlight ?? false;
    if (isSpotlight) {
      return data.post.spotlightTitle;
    }
    if (data.team) {
      return data.team;
    }
    const title = [];
    data.officialTitle && title.push(data.officialTitle);
    data.company && title.push(data.company);
    if (title.length > 0) return title.join(' @ ');

    return data.user.fullName;
  }

  const getAvatar = () => {
    const handleImageError = (event) => {
      const defaultCategories = [
        { strings: ['student'], url: '/avatars/student_icon.png' },
        { strings: ['engineer', 'developer'], url: '/avatars/engineer_icon.png' },
        { strings: ['founder'], url: '/avatars/founder_icon.png' },
        { strings: ['scientist'], url: '/avatars/scientist_icon.png' },
        { strings: ['manager', 'management'], url: '/avatars/manager_icon.png' },
        { strings: [''], url: '/default_avatar.png' },
      ]

      const title = getTitle().toLowerCase();
      for (const category of defaultCategories) {
        if (category.strings.some(e => title.includes(e))) {
          event.target.src = category.url;
          break;
        }
      }
    };

    return (
      <img
        src={(data.post.user.avatarUrl) ?
          data.post.user.avatarUrl
          : getCompanyLogoURL(data.company)}
        className="avatar" referrerPolicy="no-referrer" loading="lazy"
        onError={handleImageError}
      />
    );
  }

  return (
    <article className="chat-story-preview">
      <Link to={`/story?post=${data.post._id}`}>
        <div className="preview-header">
          <div className="avatar-container">
            {getAvatar()}
          </div>
          <div className="poster-name">{data.post.user.fullName ? data.post.user.fullName : data.officialTitle}</div>
        </div>
        <div className="preview-body">{getTitle()}</div>
        <div className="preview-footer">
          <Reactions data={data.post} setData={setData}
            type='story-preview' />
          <div className="comment-count">{commentCount} comment{commentCount === 1 ? '' : 's'}</div>
        </div>
      </Link>
    </article>

  );
};

export default StoryPreview;