const ROOT_URL = 'https://www.googleapis.com/oauth2/v3/tokeninfo?id_token='

/**
 * Logs the user in by verifying login authentication and handling user data
 */
export async function loginUser(dispatch, authToken, profileObj, loginMethod) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    await fetch(`${ROOT_URL}${authToken}`, requestOptions).then(response => {
      if (!response.ok) throw response;
    }).then(() => {
      const data = {user: profileObj, authToken, loginMethod}
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      localStorage.setItem('currentUser', JSON.stringify(data));
    });

  } catch(error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    console.error(error);
  }
}

/**
 * Logs the current user out
 */
export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('linkedinAuthCode')
  localStorage.removeItem('linkedinAuthState')
  localStorage.removeItem('linkedinAuthHmac')
}