import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { X, Maximize, Minimize, ArrowRightCircle } from 'react-feather';
import './faq_card.css';
import { getUIAvatar } from '../../util';

// /**
//  * The data contained in a user post
//  * @typedef {Object} Post
//  * @property {{question: string, answer: string}[]} responses
//  * @property {string[]} jobTitles
//  * @property {string} officialTitle
//  * @property {string} user
//  * @property {string} company
//  * @property {string} experienceLevel
//  * @property {string} team
//  * @property {string} date
// **/

/**
 * @typedef {Object} User
 * @property {ObjectId} _id
 * @property {String} firstName
 * @property {String} lastName
 * @property {String} fullName
 * @property {String} email
 * @property {String} dateJoined
 * @property {Boolean} isAnonymous
 * @property {String} avatarUrl
 * @property {ObjectId[]} roleIds
 */

/**
 * Mongo Post format
 * @typedef {Object} Post
 * @property {ObjectId} _id
 * @property {ObjectId} userId
 * @property {User} user
 * @property {ObjectId} roleId
 * @property {{question: string, answer: string}[]} responses
 * @property {string} date
 * @property {boolean} spotlight
 * @property {string} spotlightTitle
 * @property {string} spotlightHook
 * @property {{Love: string[], Like: string[], Surprise: string[]}} reactions
**/

/**
 * Aggregate of role, post, user \
 * Return shape of getCareerPosts endpoint
 * @typedef {Object} PostInfo
 * @property {ObjectId} _id
 * @property {string} company
 * @property {string[]} companies
 * @property {string[]} jobTitles
 * @property {string} experienceLevel
 * @property {string} officialTitle
 * @property {string} team
 * @property {ObjectId} _roleId
 * @property {string} _userId
 * @property {Post} post
 * @property {User} user
 * @property {CommentSection} commentSection
 */

/**
 * @param {Object} props
 * @param {PostInfo} props.info
 * @param {String} props.mode
 * @param {Object} props.setCurrentPost
 */
const FAQCard = (props) => {
  /** @type {[ReactElement, React.Dispatch<React.SetStateAction<ReactElement>>]} */
  const [card, setCard] = useState(<></>);
  const [isExpanded, setIsExpanded] = useState(false);
  const componentRef = useRef(<></>);
  const previewCardRef = useRef(<></>);
  const [companyLogoError, setCompanyLogoError] = useState('');

  useEffect(() => {
    switch (props.mode) {
      case 'preview':
        generatePreview(); break;
      case 'expanded':
        generateExpanded(); break;
      case 'discover':
        generateDiscover(); break;
      case 'spotlightPreview':
        generateSpotlightPreview(); break;
      case 'spotlightExpanded':
        generateSpotlightExpanded(); break;
      default:
        console.error(`Error: Invalid card mode: ${props.mode}`);
    }
    if (props.fromLink) cardOnClick();
  }, []);

  /**
   * @param {string} date string representing a date
   * @return {string} string representing a date in format: MMM DD, YYYY
   */
  function formatDate(date) {
    let formattedDate = (new Date(date)).toDateString();
    formattedDate = formattedDate.replace(/\w+\s/, '');
    formattedDate = formattedDate.replace(/(\d)\s/, '$1, ');
    return formattedDate;
  }

  /**
   * Generates response elements from info
   * @param  {{question: string, answer: string}[]} responses q&a responses
   * @return {ReactElement[]} array of list items containing responses
   */
  function generateResponseEls(responses) {
    const responseEls = [];
    
    if (!Object.keys(responses).length) {
      console.error('Error: Post does not have responses');
      return null;
    }
    for (const response of responses) {
      if (!response.answer || response.answer.length === 0) continue;
      let lines = response.answer.split('\n');
      const answer = [];
      for (const line of lines) {
        if (line.length > 0) {
          if (props.info.post.spotlight) {
            answer.push(
              <li key={uuid()} dangerouslySetInnerHTML={{__html: line}}></li>
            );
          } else {
            answer.push(
              <li key={uuid()}>{line}</li>
            );
          }
        }
      }

      responseEls.push(
        <div className="response" key={uuid()}>
          <div className="question">{response.question}</div>
          {props.info.post.spotlight && 
            // Parse spotlight post contents to HTML since they are manually reviewed
            <ul className="answer">{answer}</ul>
          }
          {!props.info.post.spotlight && <ul className="answer">{answer}</ul> }
        </div>
      );
    }

    return responseEls;
  }

  function getCompanyLogo(company, size='64') {
    let companyString = ''; // initially set string to ''
    if(companyLogoError.length > 0){
      let companyLogo = (
        <img 
        src={companyLogoError} 
        title={company}
      />
    );
    return companyLogo;
    }
    const logoExceptions = {'GE Aviation': 'GE.com', 'San Diego Supercomputer Center': 'sdsc.edu', 'AT&T': 'att.com', 'Standard Cognition': 'standard.ai', 'NASA': 'nasa.gov', 'Samsung': 'samsung.com', 'Microsoft': 'office.com', 'Figma': 'figmaelements.com'};
    if (logoExceptions[company]) {
      companyString = logoExceptions[company];
    } else {
      for (const e of Object.keys(logoExceptions)) {
        if (company.indexOf(e) >= 0) {
          companyString = logoExceptions[e];
        }
      }
      if (companyString.length === 0) {
        companyString = company + '.com';
      }
    }
    let companyLogo = (
      <img 
        src={`https://logo.clearbit.com/${companyString}`} 
        title={company}
        key={company}
        onError={async (evt) => {
          // https://ui-avatars.com/
          const url = `https://ui-avatars.com/api/?name=${company[0]}&length=1&bold=true&background=BAA7E1&color=FCFCFC&font-size=0.6`;
          const imgUrl = await getUIAvatar(url);
          evt.target.src = imgUrl;
          setCompanyLogoError(imgUrl);
        }}
      />
    );
    return companyLogo;
  }

  function cardOnClick(element) {
    const postInfo = props.info;
    const responseEls = generateResponseEls(postInfo.post.responses);
    const companyLogo = getCompanyLogo(postInfo.company);
  
    // Return if clicking already selected card
    if (previewCardRef.current.classList && previewCardRef.current.classList.contains('selected')) return;
    let deviceSize = '';
    const vw = Math.min(window.outerWidth, window.innerWidth);
    if (vw <= 767) deviceSize = 'mobile';
    else if (vw > 767) deviceSize = 'desktop';

    // Disable background scroll on card open
    if (deviceSize === 'mobile') {
      // document.body.style.setProperty('overflow-y', 'hidden');
      
      // document.body.classList.add('no-scroll');
      // const nav = document.querySelector('.navigation-component');
      // nav.classList.remove('d-flex');
    }
    // Pass info to Stories -> Comments
    props.setCurrentPost(postInfo)

    const newCard = <FAQCard info={postInfo} key={uuid()} mode="expanded" expandedCardModal={props.expandedCardModal} />;
    props.setSelectedCard(newCard);
    const previewCards = document.getElementsByClassName('selected');
    for (const card of previewCards) {
      card.classList.remove('selected');
    }
    if (previewCardRef.current.classList) previewCardRef.current.classList.add('selected');
    const expandedCardContainer = document.querySelector('.faq-expanded-card-container');

    expandedCardContainer.style.display = 'block';
    props.expandedCardModal.current.classList.add('d-block');
  }
  
  // Generate preview cards on the left side of the page
  function generatePreview() {
    const postInfo = props.info;
    const responseEls = generateResponseEls(postInfo.post.responses);
    const companyLogo = getCompanyLogo(postInfo.company);
    let companyLogos = [];
    if (postInfo.companies) {
      postInfo.companies.forEach((company) => { 
        companyLogos.push(getCompanyLogo(company));
      });
    }
    setCard(
      <div 
        className={"card-body preview-card" + (postInfo.post.spotlight ? " spotlight-card" : "") + (props.selected ? ' selected' : '')}
        onClick={(ele) => {cardOnClick(ele);}} 
        ref={previewCardRef}
      >
        <div className="preview-head">
          <div className="date-posted">Posted on {formatDate(postInfo.post.date)}</div>
          <Maximize className="max-icon" />
        </div>
        {
          postInfo.post.spotlight && <div className="responses-container spotlight-container">
            <div className="spotlight-title">{postInfo.post.spotlightTitle}</div>
            <div className="spotlight-hook">{postInfo.post.spotlightHook}</div>
            <div className="spotlight-footer">
              <img src="/storyImg/spotlightStar.svg" alt="Spotlight Star" id="spotlight-star" />
              <div className="spotlight-logos">{companyLogos}</div>
            </div>
          </div>
        }
        {!postInfo.post.spotlight && <div className="responses-container">
          {responseEls}
        </div>}
        {!postInfo.post.spotlight && <div className="card-footer">
          <div className="company-logo">{companyLogo}</div>
          <div className="official-title">{postInfo.officialTitle}</div>
        </div>}
      </div>
    );
  }

  // Generate expanded card on the right side of the page
  function generateExpanded() {
    const info = props.info;
    const responseEls = generateResponseEls(info.post.responses);
    const companyLogo = getCompanyLogo(info.company);
    
    let companyLogos = [];
    if (info.companies) {
      info.companies.forEach((company) => { 
        companyLogos.push(getCompanyLogo(company));
      });
    }
    const name = ((info.post.user?.firstName.length)
      ? info.post.user.fullName
      : 'Anonymous'
    );
    const team = (info.team?.length > 0 
      ? ' - ' + info.team
      : ''
    );

    const header = (
      <div className={"card-header"+ (info.post.spotlight ? ' spotlight-header' : '')}>
        <X className="x-expanded-card" size="24"
          onClick={() => {
            props.expandedCardModal.current.classList.remove('d-block');
            const selectedCard = document.querySelector('.selected');
            if (selectedCard) selectedCard.classList.remove('selected'); 
        }} />
        <div className="header-container">
          <div className="company-logo">
            {info.post.spotlight ? companyLogos : companyLogo}
          </div>
          <div className="user-info">
            {info.post.spotlight && <div className="poster-role">
              {info.post.spotlightTitle}
            </div>}
            {!info.post.spotlight && <div>
              <div className="poster-role">
                {info.officialTitle} @ {info.company}
              </div>
              <div className="poster-name">
                <span className="fw-bold">{name}</span>{team}
              </div>
            </div>}
          </div>
          {props.mode === 'discover' && 
            <>
              <OverlayTrigger delay={{show: 200, hide: 100}} overlay={
                <Tooltip>
                  See more like this
                </Tooltip>
              }>
                <Link 
                  to={`/career/${info.jobTitles[info.jobTitles.length-1]}/stories?post=`+info.post._id}
                  className="arrow-right"
                  onClick={() => {
                    props.mixpanel.track('Clicked discover page view more', { postId: info.post._id, destination: info.jobTitles[info.jobTitles.length-1] })
                  }}
                >
                  <ArrowRightCircle
                    title="See more"
                    size="24"
                  />
                </Link>
              </OverlayTrigger>
              <Minimize className="min-icon" title="Hide card" size="24"
                onClick={() => {
                  generateDiscover();
                  setIsExpanded(false);
                }} 
              />
            </>
          }
        </div>
      </div>
    );

    let tags = [];
    tags.push(
      <span className="tag" key={uuid()}>
        {info.experienceLevel}
      </span>
    );

    setCard(
      <div className="card-body expanded-card">
        {header}
        <div className="tags-date-container">
          <span className="tags">
            {tags}
          </span>
          <span className="date-posted">
            Posted on {formatDate(info.post.date)}
          </span>
        </div>
        <div className="responses-container">
          {info.post.spotlight && <>
            <div className="intro">
              <div className="spotlight-avatar-container">
                <img 
                  src={info.post.user.avatarUrl} 
                  alt={info.post.user.fullName + " Avatar"} 
                  referrerPolicy="no-referrer"
                  onError={async (evt) => {
                    // https://ui-avatars.com/
                    const url = `https://ui-avatars.com/api/?name=${info.post.user.fullName}&length=1&bold=true&background=BAA7E1&color=FCFCFC&font-size=0.6`;
                    const imgUrl = await getUIAvatar(url);
                    evt.target.src = imgUrl;
                    setCompanyLogoError(imgUrl);
                }}/>
                <span className="spotlight-name fw-semi-bold">{info.post.user.fullName}</span>
              </div>
                {responseEls[0]}
            </div>
            <div className="spotlight-responses">
              {responseEls.slice(1)} 
            </div> </>
          }
          {!info.post.spotlight && responseEls}
        </div>
      </div>
    );
  }

  /**
   * @param {PostInfo} info
   * @param {ReactElement[]} responseEls
   * @param {ReactElement} companyLogo
   */
  function generateDiscover() {
    const info = props.info;
    const responseEls = generateResponseEls(info.post.responses);
    const companyLogo = getCompanyLogo(info.company);

    setCard(
      <div 
        className="card-body discover-card"
        ref={previewCardRef}
      >
        <div className="preview-head">
          <div className="date-posted">Posted on {formatDate(info.post.date)}</div>
          <Maximize className="max-icon" title="Expand card" />
        </div>
        <div className="responses-container">
          {responseEls}
        </div>
        <div className="card-footer">
          <div className="company-logo">{companyLogo}</div>
          <div className="official-title">{info.officialTitle}</div>
        </div>
      </div>
    );
  }

  function handleClick() {
    if (props.mode !== 'discover') return;
    const isFocused = componentRef.current.classList.contains('focused');
    const isFeatured = componentRef.current.classList.contains('featured');
    if (isFocused || !isFeatured) {
      if (!isExpanded) {
        generateExpanded();
        setIsExpanded(true);
      }
    } else {
      for (const card of props.carouselRef.current.children) {
        card.classList.remove('focused');
      }
      props.carouselRef.current.style.setProperty('--index', props.index);
      componentRef.current.classList.add('focused');
    }
  }

  return (
    <article className={"faq-card-component " + (props.className || '')} 
      ref={componentRef}
      onClick={handleClick}>
      {card}
    </article>
  );
}

export default FAQCard;