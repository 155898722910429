import React, { useState } from 'react'
import "./chat_suggestion.css"
import { v4 as uuid } from 'uuid';
import { useSubdomain } from '../../../hooks';
import { getCommunityData } from '../../../app';

/**
 * @param {Object} props - The component props.
 * @param {String} props.type - The type of the chat suggestion: either "long" or "short".
 * @param {String[]} props.data - An array of suggestion strings.
 * @param {Function} props.setShowBetaMessage
 */
const ChatSuggestion = ({ mixpanel, type, data, onClick, setShowBetaMessage }) => {
  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));

  const handleSuggestionClick = (text) => {
    mixpanel.track('Clicked AI Chat suggestion', {
      community: community,
      prompt: text
    });

    setShowBetaMessage(true);
    onClick(text);
  }
  
  return (
    <div className="chatSuggestion-container">
      {data && type === "long" ? (
        <div className="chatSuggestion-long-container">
          <ul className="chatSuggestion-long">
            {data.map((item) => 
              <li key={uuid()}>
                <button onClick={e => handleSuggestionClick(e.target.textContent)}>
                  <span>{item}</span>
                  <img src="./icons/arrow-narrow-right.svg" alt="arrow-link" />
                </button>
              </li>
            )}
          </ul>
        </div>
      )
      : (
        <ul className="chatSuggestion-short">
          {data.map((item) => 
            <li key={uuid()}>
              <button onClick={e => handleSuggestionClick(e.target.textContent)}>
                <span>{item}</span>
                <img src="./icons/arrow-right.svg" alt="arrow-link" />
              </button>
            </li>
          )}
        </ul>
      )
      }
    </div>
  )
}

export default ChatSuggestion