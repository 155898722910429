import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import Reactions from '../Reactions/Reactions.jsx';
import Share from '../Share/Share';
import { Link, useHistory } from 'react-router-dom';
import { getComments } from '../../app.js';
import { getCompanyLogoURL, getFormattedDate, getTimeSincePost } from '../../util.js';
import './story.css';
import { ChevronDown, ChevronRight } from 'react-feather';

/**
 * Aggregate of role, post, user \
 * Return shape of getCareerPosts endpoint
 * @typedef {Object} PostInfo
 * @property {ObjectId} _id
 * @property {string} company
 * @property {string[]} companies
 * @property {string[]} jobTitles
 * @property {string} experienceLevel
 * @property {string} officialTitle
 * @property {string} team
 * @property {ObjectId} _roleId
 * @property {string} _userId
 * @property {Post} post
 */

/**
 * Mongo Post format
 * @typedef {Object} Post
 * @property {ObjectId} _id
 * @property {ObjectId} userId
 * @property {User} user
 * @property {ObjectId} roleId
 * @property {{question: string, answer: string}[]} responses
 * @property {string} date
 * @property {boolean?} spotlight
 * @property {string?} spotlightTitle
 * @property {string?} spotlightHook
 * @property {string?} community
**/


/**
 * Story component displays information about a Story post.
 *
 * @component
 * @param {Object} info - PostInfo
 * @param {String} type - Type string.
 * @returns {JSX.Element} - Rendered Story component.
 */

/**
 * @typedef {import('../../components/FAQCard/FAQCard').PostInfo} PostInfo
 */

const Story = ({ storyData, type, toggleAll, setToggleAll }) => {
  const componentRef = useRef(<></>);
  const [showAnswers, setShowAnswers] = useState(storyData.post.responses.map(() => false));

  const isSpotlight = storyData.post.spotlight ?? false;

  const [card, setCard] = useState(<></>);
  const [postData, setPostData] = useState(storyData.post);
  const [commentCount, setCommentCount] = useState(0);
  const history = useHistory();
  const screenWidth = Math.min(window.outerWidth, window.innerWidth);

  useEffect(() => {
    switch (type) {
      case 'story':
        generateStory(); break;
      case 'discover':
        generateDiscover(); break;
      default:
        console.error(`Error: Invalid card type: ${type}`);
    }
  }, [showAnswers, storyData, postData, commentCount]);

  useEffect(() => {
    const fetchCommentCount = async () => {
      const comments = await getComments(postData._id);
      setCommentCount(Object.keys(comments).length ? comments.comments.length : 0);
    };
    fetchCommentCount();
  }, []);

  useEffect(() => {
    setShowAnswers(storyData.post.responses.map(() => false));
  }, [storyData]);

  const isValidUrl = (url) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	  return urlPattern.test(url);
  }
  const isValidEmail = (email) => {
    const emailPattern = /^\S+@\S+\.\S+$/;
    return emailPattern.test(email);
  }
  const showScheduleMtgLink = () => {
    const scheduleLink = storyData.post.user['scheduleLink'];
    if (scheduleLink) {
      if (isValidUrl(scheduleLink)) {
        return (
          <a href={/^https?:\/\//i.test(scheduleLink) ? scheduleLink : 'https://' + scheduleLink} className="user-link"
            target="_blank" rel="noopener noreferrer">
            {scheduleLink.includes('slack.com') ? 'Slack Me' : 'Schedule a Meeting'}
          </a>
        )
      } else if (isValidEmail(scheduleLink)) {
        return (
          <a href={'mailto:' + scheduleLink} className="user-link"
            target="_blank" rel="noopener noreferrer">
            {'Schedule a Meeting'}
          </a>
        )
      }
    }
    return false;
  }

  const handleToggleAnswer = (index) => {
    const updatedList = [...showAnswers];
    updatedList[index] = !updatedList[index];
    setShowAnswers(updatedList);
  };

  const handleToggleAll = () => {
    const updateAllList = showAnswers.map(() => !toggleAll);
    setShowAnswers(updateAllList);
    setToggleAll(!toggleAll);
  };

  const getTitle = () => {
    if (isSpotlight) {
      return storyData.post.spotlightTitle;
    }
    if (storyData.team) {
      return storyData.team;
    }
    const title = [];
    storyData.officialTitle && title.push(storyData.officialTitle);
    storyData.company && title.push(storyData.company);
    if (title.length > 0) return title.join(' @ ');

    return storyData.user.fullName;
  };

  // Generate subtitle, accounting for missing values
  const getSubtitle = () => {
    const subtitleProps = [];

    storyData.post.user.fullName && subtitleProps.push(storyData.post.user.fullName);

    const role = [];
    storyData.officialTitle && role.push(storyData.officialTitle);
    storyData.company && role.push(storyData.company);
    if (role.length > 0) subtitleProps.push(role.join(' @ '));

    if (!('community' in storyData.post && storyData.post.community === 'bits in bio')) {
      subtitleProps.push(storyData.experienceLevel);
    }

    return subtitleProps.join(' | ');
  };

  const getBody = () => {
    const body = [];
    const charLimit = 700;
    let charCount = 0;
    let viewMoreAdded = false;

    const addToBody = (textToAdd, className) => {
      const content = charCount <= charLimit
        ? textToAdd
        : textToAdd.substring(0, charLimit - (charCount - textToAdd.length));
      body.push(
        <p
          className={className}
          key={`${postData._id}${className}${body.length}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}>
        </p>);
      if (charCount > charLimit && !viewMoreAdded) {
        body.push(
          <span className="view-more-button" to={`/story?post=${storyData.post._id}`} key='view-more'>
            ...View More
          </span>
        );
      }
    };
    postData.responses.map((response) => {
      if (response.answer.length) {
        charCount += 175;
        addToBody(response.question, 'response-question');
        if (charCount > charLimit && !viewMoreAdded)
          viewMoreAdded = true;

        if (response.answer.length) {
          charCount += response.answer.length;
          addToBody(response.answer, 'response-answer');
        }
        if (charCount > charLimit && !viewMoreAdded)
          viewMoreAdded = true;
      }
    });
    return body;
  };

  const getAvatar = () => {
    const handleImageError = (event) => {
      
      const defaultCategories = [
        { strings: ['student'], url: '/avatars/student_icon.png' },
        { strings: ['engineer', 'developer'], url: '/avatars/engineer_icon.png' },
        { strings: ['founder'], url: '/avatars/founder_icon.png' },
        { strings: ['scientist'], url: '/avatars/scientist_icon.png' },
        { strings: ['manager', 'management'], url: '/avatars/manager_icon.png' },
        { strings: [''], url: '/default_avatar.png' },
      ]

      const title = getTitle().toLowerCase();
      for (const category of defaultCategories) {
        if (category.strings.some(e => title.includes(e))) {
          event.target.src = category.url;
          break;
        }
      }
    };

    return (
      <img
        src={(storyData.post.user.avatarUrl) ?
          storyData.post.user.avatarUrl
          : getCompanyLogoURL(storyData.company)}
        className="avatar" referrerPolicy="no-referrer" loading="lazy"
        onError={handleImageError}
      />
    );
  };

  function generateStory() {
    setCard(
      <div className="story-component" ref={componentRef}>
        <div className="scroll-container">
          <div className="story-header">
            <div className="story-header-main">
              <div className="story-header-img">{getAvatar()}</div>
              <div className="story-header-title">
                <h4 className="title">{getTitle()}</h4>
                <div className="subtitle">{getSubtitle()}</div>
              </div>
            </div>
            <div className="story-header-side">
              <div title={getFormattedDate(storyData.post.date)}>{getTimeSincePost(storyData.post.date)}</div>
              <label className="switch">
                <input type="checkbox" onClick={handleToggleAll} defaultChecked={toggleAll} />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          {(storyData.post.user["scheduleLink"] || storyData.post.user["profileLink"]) && (
            <div className="users-links" onClick={(event) => { event.stopPropagation(); }}>
              {storyData.post.user["scheduleLink"] && showScheduleMtgLink()}
              {showScheduleMtgLink() && storyData.post.user["profileLink"] && (<b><span>{" "}|{" "}</span></b>)}
              {storyData.post.user["profileLink"] && (<a href={storyData.post.user["profileLink"]} className="user-link" target="_blank" rel="noopener noreferrer">LinkedIn</a>)}
            </div>
          )}
          <div className="story-body">
            {storyData.post.responses.map((item, i) => {
              if (item.answer.length < 2) return null;
              const showIndAnswer = showAnswers[i] || false;
              return (
                <div
                  className={`qa-container ${showIndAnswer && "open"}`}
                  key={i}
                  onClick={() => !showIndAnswer && handleToggleAnswer(i)}>
                  <div
                    className="qa-question"
                    onClick={() => handleToggleAnswer(i)}>
                    {item.question}
                    {(showIndAnswer) ?
                      <ChevronDown className="qa-button"></ChevronDown>
                      :
                      <ChevronRight className="qa-button"></ChevronRight>
                    }
                  </div>
                  {(showIndAnswer) && (<div className="story-answer" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.answer) }} />)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  function generateDiscover() {
    setCard(
      <div className="discover-story-component" onClick={() => history.push(`/story?post=${storyData.post._id}`)}>
        <Link to={`/story?post=${storyData.post._id}`}
          className="discover-story-header"
          onClick={(evt) => evt.stopPropagation()}>
          {screenWidth > 768 ? <> {/* desktop header */}
            <div className="avatar-container">
              {getAvatar()}
            </div>
            <div className="header">
              <h4 className="title">{getTitle()}</h4>
              <div className="subtitle">{getSubtitle()}</div>
            </div>
          </>
          : <> {/* mobile header */}
            <div className="header">
              <h4 className="title">{getTitle()}</h4>
            </div>
            <div className="mobile-subheader">
              <div className="avatar-container">
                {getAvatar()}
              </div>
              <div className="subtitle">{getSubtitle()}</div>
            </div>
          </>}
          <div className="date" title={getFormattedDate(storyData.post.date)}>{getTimeSincePost(storyData.post.date)}</div>
        </Link>
        {(storyData.post.user["scheduleLink"] || storyData.post.user["profileLink"]) && (
          <div className="users-links" onClick={(event) => { event.stopPropagation(); }}>
            {storyData.post.user["scheduleLink"] && showScheduleMtgLink()}
            {showScheduleMtgLink() && storyData.post.user["profileLink"] && (<b><span>{" "}|{" "}</span></b>)}
            {storyData.post.user["profileLink"] && (<a href={storyData.post.user["profileLink"]} className="user-link" target="_blank" rel="noopener noreferrer">LinkedIn</a>)}
          </div>
        )}
        <Link to={`/story?post=${storyData.post._id}`}
          className='responses-container'
          onClick={(evt) => evt.stopPropagation()}>
          {getBody()}
        </Link>
        <div className='discover-story-footer'>
          <Reactions data={postData} setData={setPostData} type='post' />
          <Link to={`/story?post=${storyData.post._id}`}
            className={`comments-button${(commentCount === 0) ? ' zero-comments' : ''}`}
            onClick={(evt) => evt.stopPropagation()}>
            {commentCount} comments
          </Link>
          <Share currentPost={storyData} jobTitle={storyData.jobTitles[0]} />
        </div>
      </div>
    );
  }

  return (
    <article>
      {card}
    </article>
  );
};

export default Story;