import './chat_response.css';
import { ThumbsDown, ThumbsUp, Copy } from 'react-feather';
import { useState, useEffect } from 'react';
import { getStory } from '../../../app';
import StoryPreview from '../../Story/StoryPreview/StoryPreview';
import ResourceSection from '../ResourceSection/ResourceSection';
import { isEmptyObject } from '../../../util';
import { useSubdomain } from '../../../hooks';
import { getCommunityData } from '../../../app';

const testSources = [
  '63ba0d6eda4128291409cfab',
  '63ba0d6eda4128291409cfc3',
]
const useTestSources = false; // Toggle to use test sources in dev builds

/**
 * @param {String} text
 * @param {String[]} sources postIds
 * @param {Object[]} resources 
 */
const ChatResponse = ({ text, sources = [], resources }) => {
  const [stories, setStories] = useState([]);
  const [copyOpen, setCopyOpen] = useState(false);

  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));
  
  async function getStories(sources) {
    if (useTestSources) sources = testSources;
    for (let i = 0; i < sources.length; i++) {
      const story = await getStory(sources[i]);
      if (isEmptyObject(story)) {
        console.info("No story found with the post id: " + sources[i]);
        continue;
      }
      setStories(prevState => [...prevState, story]);
    }
  }

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);

    setCopyOpen(true);
    setTimeout(()=> {
      setCopyOpen(false);
    }, 2500);
  }

  useEffect(() => {
    getStories(sources);
    
  }, []);


  return (
    <section className="chat-response-container">
      <div className="chat-bubble-container">
        {community ?
            <img src={"./community/" + subdomain + "-logo.png"} className="chat-bubble-icon" />
          : <img src="./logo.png" className="chat-bubble-icon pf-avatar"/>
        }
      </div>
      <div className="chat-response-content">
        <span className={"chat-response-title " + subdomain + "-color"}>{community ? community.name : "PathFinder"}</span>
        <div className="chat-response">
          {text}
          {stories.length > 0 && <div className="story-preview-container">
            <strong>{community ? 'Relevant Members:' : 'Learn more from our professionals:'}</strong>
            <div className="story-preview-content">
              {stories.map((story) => 
                <StoryPreview
                  key={story._id}
                  story={story} 
                  className="story-preview" />
              )}
            </div>
          </div>}
          {resources && (<ResourceSection resources={resources} />)}
        </div>
        <ul className="reply-utils">
          {/* Disable thumbs up and thumbs down until we decide on implementation */}
          {/* <button className="reply-util">
            <ThumbsUp></ThumbsUp>
          </button>
          <button className="reply-util">
            <ThumbsDown></ThumbsDown>
          </button> */}
          <button className="reply-util">
            <Copy onClick={() => copyToClipboard(text)}></Copy>
          </button>
        </ul>
        <div className={copyOpen ? "copy show" : "copy"}>Copied to clipboard</div>
      </div>
    </section>

  );
};

export default ChatResponse;