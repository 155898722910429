import React, { useState, useEffect } from 'react';
import './resource_preview.css';

/**
 * @typedef {Object} resource
 * @property {String} title
 * @property {String} url
 * @property {String} publishedDate YYYY-MM-DD
 * @property {String} author
 * @property {String} id
 * @property {Number} score
 * 
 * @param {Object} props
 * @param {resource} props.resource
 */
const ResourcePreview = ({ resource }) => {
  let domainName = resource.url.indexOf('https://www.') != -1 ? resource.url.substring(12) : resource.url.indexOf('https://') != -1 ? resource.url.substring(8) : resource.url;
  domainName = domainName.substring(0, domainName.indexOf('.'));
  const pubDate = new Date(resource.publishedDate);

  return (
    <article className="resource-preview">
      <a target="_blank" rel="noopener noreferrer" href={resource.url}>
      <div className="resource-header">
        <div className="avatar-container">
          <img
            src={'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=48&url=' + resource.url}
            className="avatar" referrerPolicy="no-referrer" loading="lazy"
          />
        </div>
        <div className="domain-name">{domainName}</div>
      </div>
      <strong className="resource-title">
        {resource.title}
      </strong>
      <div className="resource-footer">
        <div className="author">{resource.author}</div>
        {resource.author ? '\u00A0·\u00A0' : ''}
        {pubDate.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
      </div>
      </a>
    </article>
  );
}
  
export default ResourcePreview; 