import { ResourcePreview } from './';
import { v4 as uuid } from 'uuid';
import './resource_section.css';
const sampleResource = {
    'autopromptstring': '',
    'resources': [
        {
            'title': 'Breaking into VC',
            'url': 'https://breakinto.vc',
            'publishedDate': '2021-10-03',
            'author': null,
            'id': '',
            'score': 0,
        },
        {
            'title': '45 VCs offer advice on getting a job in venture capital',
            'url': 'https://www.businessinsider.com/45-vcs-offer-advice-on-getting-a-job-in-venture-capital-2016-8',
            'publishedDate': '2016-08-11',
            'author': 'CB Insights',
            'id': '',
            'score': 0,
        },
        {
            'title': 'Tips on Approaching a VC - This is going to be BIG! How to Get into FAANG Companies',
            'url': 'https://www.thisisgoingtobebig.com/blog/2012/12/5/tips-on-approaching-a-vc.html',
            'publishedDate': '2012-12-05',
            'author': 'Charlie O\' Donnel',
            'id': '',
            'score': 0,
        },
    ]
}

/**
 * 
 * @param {Object} props 
 * @param {Object[]} props.resources
 * @returns 
 */
const ResourceSection = ({ resources }) => {
  
    return (
      <article className="resource-section">
        <strong>Community-vetted Articles</strong>
        <div className="resource-section-container">
            {resources.map(r => <ResourcePreview resource={r} key={uuid()} />)}
        </div>
      </article>
    );
}
export default ResourceSection; 