/**
 * @param   {string}    str         the string to capitalize
 * @param   {string[]}  delimiters  array of characters used as delimiters
 * @return  {string}    a copy of str with the first character of each word 
 *                      capitalized
 */
export const capitalize = (str, delimiters=[]) => {
  if (str.length===0) return '';
  if (str.length===1) return str.toUpperCase();
  
  for (const delimiter of delimiters) {
    if (!str.includes(delimiter)) continue;
    const words = str.split(delimiter);
    str = words.map(word => {
      return word[0].toUpperCase() + word.substring(1);
    }).join(delimiter);
  }

  return str[0].toUpperCase()+str.substring(1);
}

const symbols = {
  '-':  '%20',
  ' ':  '-',
  '#':  '%23',
  '/':  '%2f',
  '\'': '%27',
};

/**
 * Formats link to be entered into url
 * @param  {string} link    Link to be formatted
 * @return {string} Link with symbols replaced by hex codes
 */
export const formatLink = (link) => {
  if (link.length === 0) return;
  for (const [k, v] of Object.entries(symbols)) {
    let re = new RegExp(k, 'g');
    link = link.replace(re, v);
  }
  return link;
}

/**
 * Processes forward slashes of input into their ASCII code
 */
export const formatFSlashes = (link) => {
  if (link.length === 0) return;
  link = link.replace(/\//g, '%2f');
  return link;
}


const urlSymbols = {
  '#':  '%23',
  '/':  '%2f',
  '\'': '%27',
  '\\?': '%3f'
};

export const formatURL = (link) => {
  if (link.length === 0) return;
  for (const [k, v] of Object.entries(urlSymbols)) {
    let re = new RegExp(k, 'g');
    link = link.replace(re, v);
  }
  return link;
}

/**
 * Processes formatted link back to original value
 * @param  {string} link    Link to be processed
 * @param  {boolean} capitalize  `true` to capitalize returned value by word
 *    boundaries. Defaults to `true`.  
 * @return {string} Link with hex codes replaced by symbols
 */
export const processLink = (link, capitalize=true) => {
  if (link.length === 0) return;

  for (const [k, v] of Object.entries(symbols)) {
    let re = new RegExp(v, 'g');
    link = link.replace(re, k);
  }
  // Case insensitive
  if (capitalize) {
    link = link.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  if (link === 'SoftwareEngineer') link = 'Software Engineer';
  return link;
}

/**
 * Binary search
 * @param  {string}   val  item to look for
 * @param  {string[]} arr  Array of terms to look through
 * @return {number}   Index of found item, or index of last item checked
 *    if element is not found in array
 */
export const binarySearch = (val, arr) => {
  if (arr.length === 0) return -1;

  let l=0, r=arr.length, m;
  while (l <= r) {
    m = Math.floor((l+r) / 2);
    if (arr[m] === val) return m;
    else if (arr[m] < val) l = m + 1;
    else r = m - 1;
  }

  return m;
}

/**
 * Shallow set equality
 * @param  {Set} a   first set
 * @param  {Set} b   second set
 * @return {boolean} True if set contents are equal, false otherwise
 */
export const setEq = (a, b) => (
  (a.size === b.size) 
    ? [...a].every(val => b.has(val))
    : false
);

/**
 * Returns a/b as a percentage
 * @param  {number} a dividend
 * @param  {number} b divisor
 * @return {string} Quotient of a and b with a percent sign at the end.
 *    If quotient is less than 1, round to 2 decimal places, else percentage is
 *    returned as a whole number
 */
export const formatPercentage = (a, b) => {
  let percentage = (a / b) * 100;
  percentage = (percentage > 1
    ? Math.round(percentage)
    : Math.round(percentage*100)/100
  ) + '%';

  return percentage;
}

/**
 * Test if string starts with a vowel
 * @param {string} str string to test
 * @return {bool} True if str starts with a vowel; false otherwise
 */
export const isVowel = (str) => {
  if (typeof str !== 'string') return false;
  if (str.length === 0) return false;

  const vowels = new Set(['a', 'e', 'i', 'o', 'u']);
  const char = str[0].toLowerCase();

  return vowels.has(char);
}

/**
 * Map range of values to another
 */
export const mapNumericRange = (n, startRange, endRange) => {
  const slope = (endRange[1] - endRange[0]) / (startRange[1] - startRange[0]);
  const result = endRange[0] + (slope * (n - startRange[0]));
  return result;
}

/**
 * Returns avatar URL given input URL
 * - Reference: https://ui-avatars.com/
 * @param {string} input
 * @return {string}
 */
export const getUIAvatar = async (input) => {
  const response = await fetch(input, { method: 'GET' });
  if (!response.ok) throw response;
  const imgBlob = await response.blob();
  const imgUrl = URL.createObjectURL(imgBlob);
  return imgUrl;
}

/**
 * Returns the URL of given company's logo
 * @return {string} url of company's logo
 */
export const getCompanyLogoURL = (company, size='64') => {
  let companyString = ''; // initially set string to ''
  // if(companyLogoError.length > 0) return companyLogoError;

  const logoExceptions = {'GE Aviation': 'GE.com', 'San Diego Supercomputer Center': 'sdsc.edu', 'AT&T': 'att.com', 'Standard Cognition': 'standard.ai', 'NASA': 'nasa.gov', 'Samsung': 'samsung.com', 'Microsoft': 'office.com', 'Figma': 'figmaelements.com'};
  if (logoExceptions[company]) {
    companyString = logoExceptions[company];
  } else {
    for (const e of Object.keys(logoExceptions)) {
      if (company.indexOf(e) >= 0) {
        companyString = logoExceptions[e];
      }
    }
    if (companyString.length === 0) {
      companyString = company + '.com';
    }
  }
  const companyLogoURL = `https://logo.clearbit.com/${companyString}`

  return companyLogoURL;
}

/**
 * Calculates time passed since certain date til now
 * @return {string} amount of time passed
 */
export const getTimeSincePost = (date) => {
  const postDate = new Date(date);
  const timePassed = new Date() - postDate;

  const secondsPassed = Math.floor(timePassed/1000);
  const minutesPassed = Math.floor(secondsPassed/60);
  const hoursPassed = Math.floor(minutesPassed/60);
  const daysPassed = Math.floor(hoursPassed/24);
  const monthsPassed = Math.floor(daysPassed/30);
  const yearsPassed = Math.floor(daysPassed/365);

  let timeSincePost = "";
  if (yearsPassed >= 1) {
    timeSincePost = yearsPassed + "y"
  } else if (monthsPassed >= 1) {
    timeSincePost = monthsPassed + "mo"
  } else if (daysPassed >= 1) {
    timeSincePost = daysPassed + "d"
  } else if (hoursPassed >= 1) {
    timeSincePost = hoursPassed + ((hoursPassed === 1) ? "hr" : "hrs")
  } else if (minutesPassed >= 1) {
    timeSincePost = minutesPassed  + ((minutesPassed === 1) ? "min" : "mins")
  } else if (secondsPassed >= 1) {
    timeSincePost = secondsPassed + "s"
  }

  return timeSincePost;
}

export const getFormattedDate = (dateString) => {
  const date = new Date(dateString);

  // Check if parsing was successful
  if (isNaN(date.getTime())) {
    return "Invalid date format.";
  }

  // Get required components
  const options = { 
    weekday: 'short', 
    month: 'short', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric', 
    timeZoneName: 'short' 
  };
  const formattedDate = date.toLocaleString('en-US', options); // Use comprehensive formatting

  return formattedDate;
}


/**
 * Scrolls comment box into view
 */
export const handleReplyButtonClick = (commenterName) => {
  const commentInput = document.getElementById('comment-entry');
  if (commentInput) {
    if (commenterName !== "")
      commentInput.value = `@${commenterName} `;

    commentInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
    commentInput.focus();
  }
};

export const isEmptyObject = (object) => Object.keys(object).length === 0;

/** Returns `true` if the set contains the given value, case-insensitive */
export const setContainsCaseInsensitive = (set, value) => {
  const lowerCaseValue = value.toLowerCase();
  return [...set].some(e => e.toLowerCase() === lowerCaseValue);
}