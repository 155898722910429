import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronUp, ExternalLink, Mail } from 'react-feather';
import './footer.css';
import { useSubdomain } from '../../hooks';
import { getCommunityData } from '../../app';

const Footer = (props) => {
  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));

  const generateDebugLink = () => {
    const url = window.location.host;
    if (url.includes('localhost') || url.includes('staging')) {
      return <li className="col-item">
      <Link to="/debug">
        Debug Settings
      </Link>
    </li>
    }
    return null;
  }

  return (
    <footer className="footer-component">
      <ul className="copyright-col">
        <li id="copyright">Copyright © 2024 PathFinder</li>
        <li className="clearbit-attribution">
          <a href="https://clearbit.com">Logos provided by Clearbit</a>
        </li>
      </ul>
      <div className="columns">
        <div className="col">
          <div className="col-header">PathFinder</div>
          <ul className="col-items">
            <li className="col-item">
              <Link to="/about" onClick={() => props.mixpanel.track('Clicked about link', { from: 'footer' })}>
                About
              </Link>
            </li>
            {!community && (
            <div className="col-item">
              <Link to="/#subscribe" onClick={() => props.mixpanel.track('Clicked subscribe link', { from: 'footer' })}>
                Get Updates
              </Link>
            </div>
            )}
            <li className="col-item">
              <a href="https://medium.com/@Pathfinder.fyi" onClick={() => props.mixpanel.track('Clicked blog link', { from: 'footer' })}>
                Blog
                <ExternalLink size="14" className="link-icon" />
              </a>
            </li>
            <li className="col-item">
              <a href="mailto:team@pathfinder.fyi" onClick={() => props.mixpanel.track('Clicked email contact link', { from: 'footer' })}>
                Contact Us
                <Mail size="14" />
              </a>
            </li>
            <li className="col-item">
              <a 
                href="https://discord.gg/zB8yfE4thX"
                onClick={() => props.mixpanel.track('Clicked Discord link')}
              >
                Discord
                <img 
                  src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/62595384f934b806f37f4956_145dc557845548a36a82337912ca3ac5.svg" 
                  width="14" height="14"
                />
              </a>
            </li>
            <li className="col-item">
              <Link to="/privacy-policy" onClick={() => props.mixpanel.track('Clicked privacy policy link')}>
                Privacy Policy
              </Link>
            </li>
            <li className="col-item">
              <Link to="/terms-of-service" onClick={() => props.mixpanel.track('Clicked ToS link')}>
                Terms of Service
              </Link>
            </li>
          </ul>
        </div>
        <ChevronUp
          className="arrow-up"
          onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
        >
          <title>Scroll to top</title>
        </ChevronUp>
        <div className="col">
          <div className="col-header">Contribute</div>
          <ul className="col-items">
            <li className="col-item">
              <a href="https://forms.gle/3nzcm2izVvcFgig2A" target="_blank" rel="noopener noreferrer" onClick={() => props.mixpanel.track('Clicked add story link', { from: 'footer' })}>
                Add your story
              </a>
            </li>
            <li className="col-item">
              <a href="https://forms.gle/vgyWdLXPmMKuNTGQ9" target="_blank" rel="noopener noreferrer">
                Submit Feedback
              </a>
            </li>
          </ul>
        </div>
        <div className="col">
          <div className="col-header">Site Map</div>
          <ul className="col-items">
            <li className="col-item">
              <Link to="/">
                Home
              </Link>
            </li>
            <li className="col-item">
              <Link to="/discover" onClick={() => props.mixpanel.track('Clicked discover link', { from: 'footer' })}>
                Discover Stories
              </Link>
            </li>
            {generateDebugLink()}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
