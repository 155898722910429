import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getMixpanel } from '../config/routes';
import { Navigation, Loading, Footer } from '.';
import { useAuthState } from '../context';
import { Signin } from './Signin/Signin';

const mixpanel = getMixpanel();

/**
 * Wrapper component used to redirect unauthenticated users from private pages
 */
const AppRoute = ({ component: Component, path, isPrivate, hasFooter, siteLoaded, ...rest }) => {
  const [showSignin, setShowSignin] = useState(false);
  const userDetails = useAuthState();
  const target = window.location.hash;

  useEffect(() => { // []
    // Scroll to top on page load. As an improvement, use 
    // sessionStorage to preserve page scroll position
    // try: https://stackoverflow.com/a/34634154/18536904

    if (target) {
      // Scroll target into view after page load
      const observer = new MutationObserver((mutations, obs) => {
        const el = document.querySelector(target);
        if (el) {
          el.scrollIntoView();
          el.classList.add('targetted');
          obs.disconnect();
          return;
        }
      });
      
      observer.observe(document, {
        childList: true,
        subtree: true
      });
    } else {
      let html = document.querySelector('html');
      html.style.scrollBehavior = 'auto';
      window.scroll({top: 0, behavior: 'auto'});
      html.style.scrollBehavior = '';
    }
  }, []);

  return (
    <Route
      path={path}
      render={(props) => {
        if (!siteLoaded) {
          return <Loading mixpanel={mixpanel} />;
        }
        else {
          return (
            isPrivate && !Boolean(userDetails.authToken) ? (
              <Redirect to={{ pathname: '/' }} /> // Redirect to landing page; change to login page path if we choose to implement one
            ) : (
              <>
                <Navigation mixpanel={mixpanel} setShowSignin={setShowSignin} />
                {showSignin && <Signin setShowSignin={setShowSignin} />}
                <Component {...props} setShowSignin={setShowSignin} />
                {hasFooter && <Footer mixpanel={mixpanel} setShowSignin={setShowSignin} />}
              </>
            )
          );
        }}
      }
    />
  )
}

export default AppRoute;