import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { getJobCounts, getStories } from '../../app';
// import SearchBar from '../../components/SearchBar/SearchBar';
import { mapNumericRange, formatLink } from '../../util';
// import '../../css/careers.css';
import './careers.css';

// Sample data for testing, format can be changed
// let data = {
//   "Popular": [
//     "Software Engineer", "Product Manager"
//   ],
//   "Development":[
//     "Software Engineering","Robotics","Database","Software Engineering","Robotics","Database"
//   ],
//   "Design":[
//     "Software Engineering","Robotics","Database"
//   ],
//   "Product":[
//     "Software Engineering","Robotics","Database","Architect","Dev Ops"
//   ],
//   "Senior Positions":[
//     "Software Engineering","Robotics","Database", "Dev Ops", "Software Engineering","Robotics","Database", "Dev Ops", 
//   ],
//   "Engineering":[
//     "Software Engineering","Robotics","Database","Software Engineering","Robotics","Database", "Dev Ops", 
//   ],
//   "Software":[
//     "Software Engineering","Robotics","Database","Machine Learning"
//   ],
//   "Research":[
//     "Software Engineering","Robotics","Database"
//   ],
//   "Business + Tech":[
//     "Software Engineering","Robotics","Database"
//   ]
// };

const toggleLogs = false;

let data = {
  'Popular': [
    'Software Engineer','Data Analyst','Machine Learning Engineer','Data Scientist','Game Designer'
  ],
  'Generic and App Dev': [
    'Software Engineer','Web Developer','Mobile Developer','Android Developer','iOS Developer','Fullstack Engineer','Backend Engineer','Frontend Engineer','Software Architect','Application Engineer','Application Architect','Enterprise Architect','Information Architect'
  ],
  'Human Computer Interface': [
    'UX Designer','UX Researcher','UI Designer'
  ],
  'Data/ML/AI': [
    'Data Analyst','Data Scientist','Data Engineer','Data Architect','Machine Learning Engineer','Computer Vision Engineer','Computer Vision Researcher','NLP Engineer','Perception Engineer','Machine Learning Researcher'
  ],
  'Database': [
    'Database Administrator','Database Engineer'
  ],
  'Interdisciplinary Fields': [
    'Computational Biologist','Bioinformatics Engineer','Bioinformatics Scientist','Computational Chemistry Scientist','Computational Physicist'
  ],
  'Operations and IT': [
    'DevOps Engineer','DevOps Specialist ','Site Reliability Engineer','System Administrator','Cloud Architect','Infrastructure Engineer','Network Engineer','Network Specialist','Network Administrator'
  ],
  'Security': [
    'Security Engineer','Security Architect','Information Security Analyst','Information Security Architect'],
  'Quality Assurance': [
    'Quality Assurance Specialist','Quality Assurance Engineer','Test Engineer','Automation Engineer'       
  ],
  'Game Development': [
    'Unity Developer','Game Designer'
  ],
  'Solution or Sales': [
    'Solutions Engineer','Customer Support Engineer','Solutions Architect','Sales Engineer'
  ],
  'Product': [
    'Product Designer','Product Manager','Project Manager','Product Engineer'
  ],
  'Other': [
    'Quantitative Developer','Quantitative Researcher','Blockchain Developer'
  ],
  'Robotics': [
    'Robotics Engineer'
  ],
  'Hardware/Systems': [
    'Distributed Systems Engineer','Systems Engineer','Systems Architect','Systems Analyst','Embedded Engineer','Firmware Engineer','Hardware Engineer','Controls Engineer','RF Engineer','Optical Engineer','Signal Processing Engineer','Audio Engineer'
  ],
  'Management': [
    'Engineering Manager','Software Engineering Lead','Software Manager','Tech Lead','Director of Engineering','VP Engineering','Chief Technology Officer','CISO','CIO'
  ]
}

/**
 * @typedef {import('../../components/FAQCard/FAQCard').PostInfo} Post
 */
/**
 * @typedef {{[jobTitle: string]: HeatmapEntry}} Heatmap
 */

/**
 * @typedef {Object} HeatmapEntry
 * @property {number} score 0-100 score weighted by num of posts scraped, num \
 *    of stories, and length of responses. highest scoring job will be 100
 * @property {number} postCnt num of posts
 */

const Careers = (props) => {
  const [columnsState, setColumnsState] = useState(<div></div>);
  const [detailsState, setDetailsState] = useState(<div></div>);
  const [subPage, setSubPage] = useState(false);
  /** @type {[Post[], React.Dispatch<React.SetStateAction<Post[]>>]} */
  const [postsData, setPostsData] = useState([]);
  /** @type {[Heatmap, React.Dispatch<React.SetStateAction<Heatmap>>]} */
  const [heatmap, setHeatmap] = useState({});
  const [careerLinkEls, setCareerLinkEls] = useState({}); // { [jobTitle]: li[] }
  /** @type  { Object.<string, number> } */
  const [formattedJobCounts, setFormattedJobCounts] = useState({});
  const [prevElementsPerCol, setPrevElementsPerCol] = useState(6);
  const dataIndicatorRefs = useRef({});

  useEffect(() => { // []
    document.title = 'Browse Careers - PathFinder';

    const careersTab = document.querySelector('#nav-careers');
    careersTab.classList.add('fw-bold', 'selected');

    const jobCounts = getJobCounts();
    const formattedJobCounts = jobCounts.reduce((obj, cur) => {
      obj[cur.jobtitle] = parseInt(cur.count);
      return obj;
    }, {});

    setFormattedJobCounts(formattedJobCounts);
    setPostsData(getStories());
    
    return () => {
      careersTab.classList.remove('fw-bold', 'selected');
    }
  }, []);

  useEffect(() => { // [formattedJobCounts, postsData]
    if (Object.keys(formattedJobCounts).length === 0 || postsData.length === 0) return;

    generateHeatmap();
  }, [formattedJobCounts, postsData]);

  // Resize
  useEffect(() => {
    function handleResize() {
      const elementsPerCol = calcElementsPerCol();
      if (elementsPerCol !== prevElementsPerCol) {
        drawColumns();
        setPrevElementsPerCol(elementsPerCol);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  useEffect(() => { // [heatmap]
    if (Object.keys(heatmap).length === 0) return;

    for (const category of Object.keys(data)) {
      generateCareerLinks(category);
    }
  }, [heatmap]);
  
  useEffect(() => { // [careerLinkEls]
    if ((Object.keys(careerLinkEls).length) === 0) return;

    const subPagePath = props.history.location.pathname;
    const subPageMatch = subPagePath.match(/\/careers\/?(.*)/);
    if (subPageMatch[1]) {
      setSubPage(true);
      const category = subPageMatch[1];
      const elements = careerLinkEls[category];
      document.title = `${category} Careers - PathFinder`;

      setDetailsState(
        <div className="category-details-container">
          <Link
            to="/careers"
            className="back-arrow"
          >
            {"←"}
          </Link>
          <strong className="careers-details-header">{category}</strong>
          {category !== 'Popular' &&
            <p className="category-description">A description about {category}.</p>
          }
          <ul className="category-details-items">{elements}</ul>
        </div>
      );
    }

    drawColumns();
  }, [careerLinkEls]);

  function generateCareerLinks(category) {
    if (!(category in data)) {
      toggleLogs && console.error(`Error: Invalid category: ${category}`);
      return [];
    }
    // map from 0-100 to 90-40
    const startRange = [0,100], endRange = [95,40];

    /** 
     * @typedef {Object} HeatmapData 
     * @property {string} jobTitle
     * @property {number} score
     * @property {number} postCnt
     */
    /** @type {HeatmapData[]} */
    let heatmapCategoryData = [];
    for (const jobTitle of data[category]) {
      if (!(jobTitle in heatmap)) {
        toggleLogs && console.log(`Error: Unrecognized job listed: ${jobTitle}`);
        continue;
      }
      heatmapCategoryData.push({
        jobTitle: jobTitle,
        score: heatmap[jobTitle].score,
        postCnt: heatmap[jobTitle].postCnt,
      });
    }

    heatmapCategoryData = heatmapCategoryData.sort((a,b) => {
      if (b.postCnt === a.postCnt) return a.jobTitle.localeCompare(b.jobTitle);
      return b.postCnt-a.postCnt;
    });

    let elements = [];
    for (const heatmapData of heatmapCategoryData) {
      const bkgLight = mapNumericRange(heatmapData.score, startRange, endRange);
      const postCnt = heatmapData.postCnt;
      elements.push(
        <li key={uuid()}>
          <OverlayTrigger placement="top" delay={{hide: 0}} overlay={
            <Tooltip>
              {`${postCnt} stor${postCnt !== 1 ? 'ies' : 'y'}`}
            </Tooltip>
          }>
            <div 
              className="heatmap-indicator" 
              style={{'--bkg-light': `${bkgLight}%`, '--color-light': `${(bkgLight > 70 ? 20 : 90)}%`}}
              ref={(el) => dataIndicatorRefs.current[heatmapData.jobTitle] = el}
              onClick={(evt) => evt.stopPropagation()}
            >
              {postCnt}
            </div>
          </OverlayTrigger>
          <Link 
            to={`/career/${formatLink(heatmapData.jobTitle)}`}
            className="career-link"
            onClick={() => {
              props.mixpanel.track('Clicked careers page link', {jobTitle: heatmapData.jobTitle});
            }}
          >
            {heatmapData.jobTitle}
          </Link>
        </li>
      )
    }

    setCareerLinkEls((prevLinks) => {
      return { ...prevLinks,
        [category]: elements
      };
    });
  }

  /**
   * Calculates elements per column based on width (imperfect method)
   */ 
  function calcElementsPerCol() {
    const screenWidth = Math.min(window.outerWidth, window.innerWidth);
    return Math.ceil(Object.keys(data).length/Math.ceil(screenWidth / 800));
  }

  function drawColumns() {
    const elementsPerCol = calcElementsPerCol();
    // Create each category by generating list
    let categoryElements=[];
    for (const category of Object.keys(data)) {
      const elements = careerLinkEls[category];
      categoryElements.push(
        <div 
          onClick={(evt) => {
            if (evt.target.classList.contains('career-link')) return;
            props.history.push(`/careers/${category}`);
            props.mixpanel.track('Clicked on careers category', { category: category });
          }}
          className={"category-container " + (category === 'Popular' ? 'popular ' : '')}
          key={category}>
            <strong>{category}</strong>
            <ul>{elements}</ul>
        </div>
      );
    }

    // Create columns
    let careerElements = [];
    let count = 0;
    let column = [];
    for (const ele of categoryElements) {
      column.push(ele);
      count++;
      if (count >= elementsPerCol) {
        careerElements.push(
          <div className="column" key={uuid()}>
            {column}
          </div>
        );
        count=0;
        column=[];
      }
    }
    if (column.length) {
      careerElements.push(
        <div className="column" key={uuid()}>
          {column}
        </div>
      );
    }
    setColumnsState(<div className="careers-columns container">{careerElements}</div>);
  }

  function generateHeatmap() {
    let max = 0;
    
    /** @type {Heatmap} */
    let scores = Object.keys(formattedJobCounts).reduce((obj, jobTitle) => {
      obj[jobTitle] = { score: 0, postCnt: 0 };
      return obj;
    }, {});

    for (const post of postsData) {
      // Points for response length
      const responsesScoreWeight = 1/10;
      let responsesScore = Math.round(post.responses.reduce((sum, cur) => sum + cur.answer.length, 0) * responsesScoreWeight);
      for (const jobTitle of post.jobTitles) {
        if (!(jobTitle in scores)) {
          toggleLogs && console.log(`Unsupported job title from user found: ${jobTitle}`);
          continue;
        }

        // Points added for having a post
        const postScore = 50; 

        const scoreGained = postScore + responsesScore;
        scores[jobTitle].score += scoreGained;
        scores[jobTitle].postCnt++;

        if (scores[jobTitle].score > max) {
          max = scores[jobTitle].score;
        }
      }
    }

    for (const jobTitle of Object.keys(formattedJobCounts)) {
      const scrapedWeight = 2;
      const scoreGained = Math.round(formattedJobCounts[jobTitle] * scrapedWeight);
      scores[jobTitle].score += scoreGained;

      if (scores[jobTitle].score > max) {
        max = scores[jobTitle].score;
      }
    }

    for (const jobTitle of Object.keys(formattedJobCounts)) {
      scores[jobTitle].score /= max / 100;
    }

    setHeatmap(scores);
  }

  return (
    <div className="careers-component container">
      <div className="careers-header container">
        <h2>
          Careers
          {/* <span className="indev">(page still under development)</span> */}
        </h2>
        Explore careers and learn about the day-to-day, the benefits, the skills required, etc. for over 80 careers in tech.
        <div className="heatmap-legend">
          <div>low activity</div>
          <div className="heatmap-gradient"></div>
          <div>high activity</div>
        </div>
        {/* <div className="search-bar-container">
          <SearchBar mixpanel={props.mixpanel} type="main" />
        </div> */}
      </div>
      {subPage ? detailsState : columnsState}
    </div>
  );
}

export default withRouter(Careers);
