import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Info } from 'react-feather';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import mixpanel from 'mixpanel-browser';
import { AuthProvider, loginUser } from './context';
import { AppRoute } from './components';
import NotFound from './pages/NotFound';
import { routes } from './config/routes';
import { capitalize, binarySearch, formatURL } from './util';

let toggleLogs = false;
/**
 * @typedef {Object} User
 * @property {ObjectId} _id
 * @property {String} firstName
 * @property {String} lastName
 * @property {String} email
 * @property {String} dateJoined
 * @property {Boolean} isAnonymous
 * @property {String} avatarUrl
 * @property {ObjectId[]} roleIds
 */

/**
 * @typedef {import('./components/FAQCard/FAQCard').PostInfo} Post
 */

/**
 * @typedef {Object} MongoPost
 * @property {ObjectId} _id
 * @property {string} userId
 * @property {{question: string, answer: string}[]} responses
 * @property {string} roleId
 * @property {string} date as an ISOString
 */

/**
 * @typedef {Object} PostData
 * @property {ObjectId} _id role id
 * @property {string} company
 * @property {string[]} jobTitles
 * @property {string} experienceLevel
 * @property {string} officialTitle
 * @property {string} team
 * @property {string} roleId role id as string 
 * @property {MongoPost[]} posts post matched to role
 */

/**
 * @typedef {Object} CommentSection
 * @property {ObjectId} _id
 * @property {ObjectId} postId
 * @property {ObjectId[]} comments
 */

/**
 * @typedef {Object} Comment
 * @property {ObjectId} _id
 * @property {string} userId
 * @property {Reactions} reactions
 * @property {string} date
 * @property {string} content
 */

// Prefix for PathFinder API
var apiPrefix = '';
export const getAPIPrefix = () => apiPrefix;

/**
 * @typedef {{[subdomain: string]: Community}} CommunityData
 */

/**
 * @typedef {Object} Community
 * @property {string} name Readable name of the community.
 * @property {string} description Description of the community.
 * @property {string} color Color of the community.
 * @property {CommunityAIChat} AIChat AIChat prompt and suggestions associated with the community.
 * @property {CommunityLink[]} links Array of links associated with the community.
 */

/**
 * @typedef {Object} CommunityLink Links associated with the community.
 * @property {string} url URL of the link.
 * @property {string} platform Ex: "slack", "discord". "general" for general links. 
 */

/**
 * @typedef {Object} CommunityAIChat
 * @property {string} chatPrompt AIChat placeholder prompt
 * @property {string[]} suggestedPrompts AIChat suggested prompts for ChatSuggestion
 */

/** @type {CommunityData} */
const communityData = {
  "bitsinbio": {
    name: "Bits in Bio",
    description: "A community building software for science",
    links: [
      { url: "https://bitsinbio.org", platform: "general" },
      { url: "https://bitsinbio.slack.com", platform: "slack" }
    ],
    color: "#41A7AB",
    AIChat: {
      chatPrompt: "Drug Discovery Startup Founders that are using AI",
      suggestedPrompts: [
        "AI and Bio PhD students who're also job hunting",
        "Managers that lead technical engineer or research teams",
        "Looking for experts in synthetic biology"
      ],
    },
  },
  "bobatalks": {
    name: "BobaTalks",
    description: "We'll figure it out, together.",
    links: [
      { url: "https://bobatalks.com/", platform: "general" },
      { url: "https://discord.com/invite/bobatalks", platform: "discord"}
    ],
    color: "#0B2348",
  },
}

/**
 * @param {string} subdomain 
 * @returns {Community}
 */
export const getCommunityData = (subdomain) => {
  if (subdomain.length === 0) return null;
  if (!(subdomain in communityData)) {
    if (subdomain !== 'staging') {
      console.error("Unexpected subdomain: " + subdomain);
    }
  }
  return communityData[subdomain];
}

// Preloaded Data
var jobCounts = [];
var jobsList = [];
var allTermsLists = {};
var formattedJobs = [];
var stories = [];
/**
 * Returns array of all job titles
 * @return {string[]}
 */
export const getJobsList = () => jobsList;
/**
 * Returns array of objects containing a job title and the count of job posts
 * that were scraped under that job title
 * @return {{jobtitle: string, count: number}[]}
 */
export const getJobCounts = () => jobCounts;
/**
 * Returns object whose keys are term types and values are the list of terms
 * of that term type
 * - Term types: **languages, skills, degrees, education, industries**
 * @return {Object.<string, string[]>}
 */
export const getAllTermsLists = () => allTermsLists;
export const getStories = () => stories;
/**
 * @return {boolean} `true` if site is loaded, `false` otherwise
 */
export const siteLoaded = () => apiPrefix.length > 0;
/**
 * @param  {boolean} withCareers `true` to include 'careers' at beginning of list. Defaults to `true`.
 * @return {string[]} An array containing each possible term type
 */
export const getTermTypes = (withCareers = true) => {
  return (withCareers ? ['careers'] : [])
    .concat(['languages', 'skills', 'degrees', 'education', 'industries']);
}

/**
 * Searches `arr` using `input` and returns array of results
 * @param  {string}   input User's search input value
 * @param  {number[]} arr Array of terms to search through
 * @return {string[]}     Array of suggestions found from `input` \
 *    If `input` matches term in `arr`, that term will be the first element in
 *    the result
 */
export const searchForTerms = (input, arr) => {
  if (arr.length === 0) return [];

  // Standardize capitalization for matching
  input = input.toLowerCase();
  let arrLower = arr.map(e => e.toLowerCase());

  // Get index 
  let idx = binarySearch(input, arrLower);
  if (idx < 0 || idx >= arrLower.length) return [];

  /**
   * If no exact match is found, begin looking for suggestions.
   * After search, finds leftmost suggestion which should be within 
   * RANGE indices from m
   */
  const RANGE = 6;
  let i;
  for (i = -RANGE; i <= RANGE; ++i) {
    if (arrLower[idx + i] && arrLower[idx + i].substring(0, input.length) === input) {
      idx += i; break;
    }
  }

  if (i === RANGE + 1) return []; // No suggestions found
  // Clamp m to be in bounds
  if (idx < 0) idx = 0;
  else if (idx >= arrLower.length) idx = arrLower.length - 1;

  // Get list of matching suggestions by tracking range of indices of suggestions
  let start = idx, end = idx + 1;
  let term = arrLower[start].substring(0, input.length);
  while (term === input) {
    if (end >= arrLower.length) {
      end = arrLower.length + 1; break;
    }
    term = arrLower[end].substring(0, input.length);
    ++end;
  }

  const result = arr.slice(start, end > 1 ? end - 1 : 0);
  return result;
}

/**
 * Deprecated
 * @param   {number} count  Number of times search term appeared in query
 * @param   {string} job    Name of job
 * @return  {number}        Percentage representing number of times the
 *      term showed up in job posts for job, divided by the total number of
 *      entries that job has. Rounded to whole number if greater than 1,
 *      else rounds to 2 decimal places.
 */
export const percentOfJob = async (count, job, type) => {
  let jobCount = 0;
  await fetch(`${apiPrefix}/data/countWithDemand/${type}/${job}`).then(response => {
    if (!response.ok) throw response;
    return response.json();
  })
    .then(json => {
      jobCount = parseInt(json[0].count);
    })
    .catch(error => {
      console.error(`Error in percentOfJob(${count}, ${job}, ${type})`);
    })
  let percentage = parseInt(count) * 100 / jobCount;
  return percentage > 1 ? Math.round(percentage) : Math.round(percentage * 100) / 100;
}

/**
 * The thresholding values used in searches. \
 * To tune these values, go to **Overview.jsx** and toggle on `logPercentageData`
 * and `automate`, then check to see at which threshold values non-programming
 * jobs start to display languages
 * @return {{ jobCount: number, percentage: number }}
 */
export const getThresholdValue = () => {
  return ({
    jobCount: 70,
    percentage: 19
  });
};

const reqHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'PfApiKey': process.env.REACT_APP_PF_APIKEY,
}

const requestOptionsGet = {
  method: 'GET',
  headers: reqHeaders
};

/**
 * Fetches the list of jobs and their numbers of job posts that contain at
 * least 1 term from the specified `termType`
 * @param {string} termType The type of the term
 * @return {Promise.<{jobtitle: string, count: number}[] | []>}
 */
export const getJobCountsContainingTermOfType = async (termType) => {
  try {
    const response = await fetch(`${apiPrefix}/data/jobCountsContainingTermOfType/${termType}`, requestOptionsGet);
    if (!response.ok) throw response;
    let results = await response.json(); // [{ jobtitle: string, count: number }]
    // Convert count from string to number
    results = results.map((result) => {
      return { ...result, count: parseInt(result.count) };
    });

    return results;

  } catch (error) {
    console.error(`Error calling getJobCountsContainingTermOfType(${termType})\n`, error);
    return [];
  }
}

/**
 * Takes in an array of objects resulting from a reverse search along with the
 * term type. \
 * Returns a promise containing an array of objects describing the percentage
 * of job posts (out of the number of job posts under the current job title
 * that contained at least one term of the specified term type) the input term
 * was found in for each job title
 * @param {{jobtitle: string, count: number}[]} data Resulting array of data
 *   from a reverse search
 * @param {string} termType The type of the term that was used in the reverse search
 * @return {Promise.<{jobtitle: string, percentage: number}[] | []>}
 */
export const getJobPercentages = async (data, termType) => {
  const results = await getJobCountsContainingTermOfType(termType);

  let jobsPercentages = [];
  let formattedData = []; // change data format to [{ [jobtitle]: count }]
  data.forEach((e) => formattedData[e.jobtitle] = e.count);
  const jobTitles = new Set(Object.keys(formattedData));

  for (const result of results) {
    if (!jobTitles.has(result.jobtitle)) continue;

    // Calculate percentage for this job, rounding to nearest int if > 1, and rounding to 2 decimal places if <= 1
    let percentage = formattedData[result.jobtitle] * 100 / result.count;
    percentage = (percentage > 1
      ? Math.round(percentage)
      : Math.round(percentage * 100) / 100);
    jobsPercentages.push({
      jobtitle: result.jobtitle,
      percentage: percentage
    });
  }

  jobsPercentages.sort((a, b) => b.percentage - a.percentage);
  return jobsPercentages;
}

/**
 * Perform a reverse search using the specified input term and its type. \
 * Returns a promise containing an array of objects describing the number of 
 * job posts the input term was found in for each job title
 * @param {string} input The term to search with
 * @param {string} termType The type of the term being used as input
 * @param {boolean} doThresholdResults `true` to filter results based on threshold. 
 *    Defaults to `true`.
 * @return {Promise.<{ jobtitle: string, count: number }[] | []>}
 */
export const reverseSearch = async (input, termType, doThresholdResults = true) => {
  try {
    const formattedInput = input.replace(/\//, '%2f');
    const response = await fetch(`${apiPrefix}/data/reverseSearch/${termType}/${formattedInput}`, requestOptionsGet);
    if (!response.ok) throw response;
    /** @type {{ jobtitle: string, count: number }[]} */
    let results = await response.json();
    // Convert count from string to number
    results = results.map((result) => {
      return { ...result, count: parseInt(result.count) };
    });

    if (doThresholdResults) {
      let thresholdedResults = [];
      const threshold = getThresholdValue();
      const jobCountsWithTerm = await getJobCountsContainingTermOfType(termType);
      const jobCountsList = getJobCounts();
      for (const result of results) {
        let totalJobCount = jobCountsList.find((obj) => obj.jobtitle === result.jobtitle).count;
        if (totalJobCount < threshold.jobCount) continue;

        let jobCountWithTerm = jobCountsWithTerm.find((obj) => obj.jobtitle === result.jobtitle).count;
        const percentage = Math.round(jobCountWithTerm * 100 / totalJobCount);
        if (percentage < threshold.percentage) continue;

        thresholdedResults.push(result);
      }

      return thresholdedResults;
    } else {
      return results;
    }

  } catch (error) {
    toggleLogs && console.error(`Error calling reverseSearch(${input}, ${termType})\n`, error);
    return [];
  }
}

/**
 * Gets terms of `termType` from `jobTitle`
 * @param {string} termType The type of the terms to get from job
 * @param {string} jobTitle The title of the job to get terms from
 * @return {Promise.<{value: string, count: number}[] | []>}
 */
export const getTermsOfTypeFromJob = async (termType, jobTitle) => {
  try {
    const response = await fetch(`${apiPrefix}/data/termsOfTypeFromJob/${termType}/${jobTitle}`, requestOptionsGet);
    if (!response.ok) throw response;
    let results = await response.json();
    // Convert count from string to number
    results = results.map((result) => {
      return { ...result, count: parseInt(result.count) };
    });

    return results;

  } catch (error) {
    toggleLogs && console.error(`Error calling getTermsOfTypeFromJob(${termType}, ${jobTitle})\n`, error);
    return [];
  }
}

/**
 * Get description of `term`
 * @param {string} term
 * @return {Promise.<string>}
 */
export const getTermDescription = async (termType, term) => {
  try {
    const response = await fetch(`${apiPrefix}/data/description/${termType}/${term}`, requestOptionsGet);
    if (!response.ok) throw response;
    let results = await response.json();
    if (results.length === 0) { // Term definition was not found
      return '';
    }
    return Object.values(results)[0].description;

  } catch (error) {
    toggleLogs && console.error(`Error calling getTermDescription(${term})\n`, error);
    return '';
  }
}

/**
 * Test endpoint that fetches users
 */
export const fetchUsers = async () => {
  try {
    const response = await fetch(`${apiPrefix}/mongo/getUsers`);
    if (!response.ok) throw response;
    let results = await response.json();
    return results;

  } catch (err) {
    toggleLogs && console.error(`Error calling fetchUsers()\n`, err);
    return [];
  }
}

/**
 * Updates user profile in MongoDB after Google login
 * @param {Object} profileObj User's profileObj provided by Google after login
 * @return {Promise<User>} User data in Mongo format
 */
export const updateUser = async (profileObj) => {
  const formattedProfile = { ...profileObj };
  toggleLogs && console.log(formattedProfile);
  for (let [k, v] of Object.entries(formattedProfile)) {
    formattedProfile[k] = formatURL(v);
  }
  try {
    const response = await fetch(`${apiPrefix}/mongo/updateUser/${formattedProfile.givenName}/${formattedProfile.familyName}/${formattedProfile.email}/${formattedProfile.imageUrl}`, {
      method: 'PUT', headers: reqHeaders
    });
    if (!response.ok) throw response;
    let userObj = await response.json();
    return userObj;
  } catch (err) {
    toggleLogs && console.error(err);
    toggleLogs && console.error('Error calling updateUser() with data:');
    toggleLogs && console.error(profileObj);
  }
}

/**
 * Gets story given a postId
 * @param {string} postId ID of post
 * @return {Promise<Post>}
 */
export const getStory = async (postId) => {
  try {
    const response = await fetch(`${apiPrefix}/mongo/getStory/${postId}`, requestOptionsGet);
    if (!response.ok) throw response;
    const posts = await response.json();
    return posts[0];

  } catch (err) {
    toggleLogs && console.error(`Error calling getStory(${postId}):\n`, err);
    return {};
  }
}

/**
 * Gets stories posted for the given career
 * @return {Promise<Post[]>}
 */
export const getCareerPosts = async (career) => {
  try {
    const response = await fetch(`${apiPrefix}/mongo/getCareerPosts/${career}`, requestOptionsGet);
    if (!response.ok) throw response;
    const posts = await response.json();
    return posts;

  } catch (err) {
    toggleLogs && console.error(`Error calling getCareerPosts(${career}):\n`, err);
    return [];
  }
}

/**
 * Posts comment
 * @param {string} userId ID of user posting comment
 * @param {string} postId ID of post that comment is made for
 * @param {string} content Content of comment
 */
export const postComment = async (userId, postId, content) => {
  try {
    const response = await fetch(`${apiPrefix}/mongo/postComment/${userId}/${postId}/${content}`, {
      method: 'POST', headers: reqHeaders
    });
    if (!response.ok) throw response;
    toggleLogs && console.log('Successfully posted comment')
  } catch (err) {
    toggleLogs && console.error(`Error calling postComment(${userId}, ${postId}, ${content}):\n`, err);
  }
}

/**
 * Posts comment
 * @param {string} email Recipient Email
 * @param {string} postId ID of post that comment is made for
 * @param {string} posterName
 * @param {string} commenterName
 * @param {string} comment
 */
export const sendCommentNotification = async (email, postId, posterName, commenterName, comment) => {
  try {
    const response =
      await fetch(`${apiPrefix}/mj/sendNotification/${email}/${postId}/${posterName}/${commenterName}/${comment}`, {
        method: 'POST', headers: reqHeaders
      });
    if (!response.ok) throw response;
    toggleLogs && console.log('Successfully sent notification')
  } catch (err) {
    toggleLogs && console.error(`Error calling sendNotification/${email}/${postId}/${posterName}/${commenterName}/${comment}):\n`, err);
  }
}

/**
 * Gets comments under specified post
 * @param {string} postId
 */
export const getComments = async (postId) => {
  try {
    const response = await fetch(`${apiPrefix}/mongo/getComments/${postId}`, requestOptionsGet);
    if (!response.ok) throw response;
    const result = await response.json();
    return result;
  } catch (err) {
    toggleLogs && console.error(`Error calling getComments(${postId}):\n`, err);
  }
}

/**
 * Deletes comment
 * @param {string} commentId
 */
export const deleteComment = async (commentId) => {
  try {
    const response = await fetch(`${apiPrefix}/mongo/deleteComment/${commentId}/`, {
      method: 'DELETE', headers: reqHeaders
    });
    if (!response.ok) throw response;
    toggleLogs && console.log('Successfully deleted comment')
    return true;
  } catch (err) {
    toggleLogs && console.error(`Error calling deleteComment(${commentId}):\n`, err);
    return false;
  }
}

/**
 * Posts reactions from a comment to the DB
 * @param {string} commentId
 * @param {string} reaction
 * @param {string} userId
 */
export const postReaction = async (commentId, reaction, userId) => {
  try {
    const response = await fetch(`${apiPrefix}/mongo/postReaction/${commentId}/${reaction}/${userId}`, {
      method: 'POST', headers: reqHeaders
    });
    if (!response.ok) throw response;
    toggleLogs && console.log('Successfully posted reaction')
  } catch (err) {
    toggleLogs && console.error(`Error calling postReaction(${userId}, ${postId}, ${content}):\n`, err);
  }
}

/**
 * Posts reactions from a comment to the DB
 * @param {string} commentId
 * @param {string} reaction
 * @param {string} userId
 */
export const updateCommentReaction = async (userId, commentId, reactionType) => {
  let updatedCommentData = {};
  try {
    const response = await fetch(`${apiPrefix}/mongo/updateCommentReaction/${userId}/${commentId}/${reactionType}/`, {
      method: 'PUT', headers: reqHeaders
    })
    if (!response.ok) throw response;
    toggleLogs && console.log('Successfully updated a reaction!')
    updatedCommentData = await response.json();
  } catch (err) {
    toggleLogs && console.error(`Error calling updateCommentReaction(${userId}, ${commentId}, ${reactionType}):\n`, err);
  }

  return updatedCommentData;
}

/**
 * Posts reactions from a story to the DB
 * @param {string} postId
 * @param {string} reaction
 * @param {string} userId
 */
export const updatePostReaction = async (userId, postId, reactionType) => {
  let updatedPostData = {};
  try {
    const response = await fetch(`${apiPrefix}/mongo/updatePostReaction/${userId}/${postId}/${reactionType}/`, {
      method: 'PUT', headers: reqHeaders
    })
    if (!response.ok) throw response;
    toggleLogs && console.log('Successfully updated a reaction!')
    updatedPostData = await response.json();
  } catch (err) {
    toggleLogs && console.error(`Error calling updatePostReaction(${userId}, ${postId}, ${reactionType}):\n`, err);
  }

  return updatedPostData;
}

export const getReaction = async (userId, commentId) => {
  try {
    const response = await fetch(`${apiPrefix}/mongo/getReaction/${userId}/${commentId}/`, {
      method: 'GET', headers: reqHeaders
    });
    if (!response.ok) throw response;
    toggleLogs && console.log('Successfully retrieved reactions!')
  } catch (err) {
    toggleLogs && console.error(`Error calling getReaction(${userId}, ${commentId}):\n`, err);
  }
}

/**
 * Sends welcome email to user if this is their first time logging in
 * Creates new Mailjet contact and adds them to the PathFinder Users contact list
 * @param {string} email
 * @return {boolean} `true` if the email was sent; `false` otherwise
 */
export const newContact = async (email) => {
  try {
    const response = await fetch(`${apiPrefix}/mj/newContact/${email}`, {
      method: 'POST', headers: reqHeaders
    });
    if (!response.ok) throw response;
    if (response.status === 200) return false;
    if (response.status === 201) return true;
  } catch (err) {
    toggleLogs && console.error(`Error calling newContact(${email}):\n`, err);
    return false;
  }
}

/**
 * Retrieves Company Logo URL
 * @param {string} company name of company 
 * @return {string} URL String for company logo
 */
export const getCompanyLogoURL = (company) => {
  let companyString = ''; // initially set string to ''

  const logoExceptions = { 'GE Aviation': 'GE.com', 'San Diego Supercomputer Center': 'sdsc.edu', 'AT&T': 'att.com', 'Standard Cognition': 'standard.ai', 'NASA': 'nasa.gov', 'Samsung': 'samsung.com', 'Microsoft': 'office.com', 'Figma': 'figmaelements.com' };
  if (logoExceptions[company]) {
    companyString = logoExceptions[company];
  } else {
    for (const e of Object.keys(logoExceptions)) {
      if (company.indexOf(e) >= 0) {
        companyString = logoExceptions[e];
      }
    }
    if (companyString.length === 0) {
      companyString = company + '.com';
    }
  }
  const companyLogoURL = `https://logo.clearbit.com/${companyString}`

  return companyLogoURL;
}

/**
 * Send Magic Link to user's email 
 * @param {string} email user's email address
 */
export const sendMagicLink = async (email) => {
  try {
    console.log(`${apiPrefix}/magic-link/login/${email}`)
    const response = await fetch(`${apiPrefix}/magic-link/login/${email}`, {
      method: 'POST', headers: reqHeaders
  });

    if (!response.ok) return false;
    return true;
  } catch (error) {
    console.error('Error occured while sending magic link');
    return false;
  }
}

export const loginUserMagicLink = async (dispatch, userObj, token) => {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    const data = {user: userObj, auth_token: token, loginMethod: 'magic-link'}
    localStorage.setItem('currentUser', JSON.stringify(data));
    dispatch({ type: 'LOGIN_SUCCESS', payload: data });
    toggleLogs && console.log(`localStorage: ${localStorage}`);
  
    const fullName = userObj.firstName + (userObj.lastName ? ' ' + userObj.lastName : '');
    await newContact(userObj.email, fullName);
    console.log('Authentication successsful!');
  } catch(error) {
    dispatch({ type: 'LOGIN_ERROR', error: error});
    console.error('Error occurred during authentication:', error);
  }
}

/**
 * Signs in user using Magic Link 
 * @param {string} token user's token to verify
 */
export const verifyUser = async (dispatch, token) => {
  try {
    const response = await fetch(`${apiPrefix}/magic-link/verify?token=${token}`, {
      method: 'GET', headers: reqHeaders
  });

    if (response.ok) {
      const userObj = await response.json()
      if (!userObj) {
          toggleLogs && console.error('Error updating user');
          return 'Fail';
      }
      loginUserMagicLink(dispatch, userObj, token);
      
      return 'Success';
    } else if (response.status === 404) {
      return 'New user';
    } else {
      return 'Fail';
    }
  } catch (error) {
    console.error('Error occurred during authentication:', error);
    return 'Fail';
  }
};

/**
 * Creates New User
 * @param token magic link token
 * @param firstName User's first name
 * @param lastName User's last name
 * @param isDisplayFirstNameOnly boolean
 * 
 */
export const createNewUser = async (dispatch, token, firstName, lastName, isDisplayFirstNameOnly) => {
  try {
    const body = {
      token: token,
      firstName: firstName,
      lastName: lastName,
      isDisplayFirstNameOnly: isDisplayFirstNameOnly
    }
    const response = await fetch(`${apiPrefix}/magic-link/register`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(body)
    })

    if (response.status !== 201) return false;

    const userObj = await response.json()
    loginUserMagicLink(dispatch, userObj, token);
    
    return true;
  } catch (error) {
    console.error('Error occurred during authentication:', error);
    return false;
  }
}

 /*
 * Redirects a user to the linkedin oauth consent screen. 
 */
const linkedinRedirect = () => {
  const linkedinAuthorizationURL = "https://www.linkedin.com/oauth/v2/authorization?response_type=code";
  const scope = "openid%20profile%20w_member_social%20email";
  const client_id = "77icjr2n4e8alb";

  const dynamic_redirect_uri = `${window.location.protocol}//${window.location.host}/`

  if (!localStorage.linkedinOriginalUrl) {
    localStorage.setItem("linkedinOriginalUrl", window.location.href)
    localStorage.setItem("linkedinRedirectURI", dynamic_redirect_uri)
  }
  window.location.assign(`${linkedinAuthorizationURL}&client_id=${client_id}&redirect_uri=${dynamic_redirect_uri}&state=${localStorage.linkedinAuthState}&scope=${scope}`);
}

/**
 * Sends a GET request to the server to fetch a state and hmac token, and sets both in local storage
 * @returns {string} URL string to redirect user
 */
export const linkedinSignin = async () => {
  const response = await fetch(`${apiPrefix}/auth/linkedin/getState`, {
    method: "GET",
    headers: reqHeaders
  });
  const data = await response.json();
  if (data.state && data.hmac) {
    localStorage.setItem("linkedinAuthState", data.state);
    localStorage.setItem("linkedinAuthHmac", data.hmac);
  } else {
    console.error("No state and key found");
  }

  linkedinRedirect()
}

/**
 * Uses the code (from Linkedin's API), state, and hmac token (generated by the server) to authorize
 * a linkedin oauth login and get the linkedin user's info. After this is done, the user is signed in
 * and their information is updated in the database
 * @param {object} authDispatch 
 * @returns 
 */
export const authorizeLinkedinSignin = async (authDispatch) => {
  try {

    const code = localStorage.linkedinCodeTemp;
    const state = localStorage.linkedinStateTemp;
    const hmac = localStorage.linkedinHmacTemp;
    const redirect_uri = localStorage.linkedinRedirectURI;

    // with a fresh code, fetches user data and logs in the user
    if (code && state && hmac && redirect_uri) {
      let userData = undefined;
      const response = await fetch(`${apiPrefix}/auth/linkedin/getUserInfo/${code}/${state}/${hmac}/${formatURL(redirect_uri)}`, {
        method: "GET",
        headers: reqHeaders
      });
      userData = await response.json();
      userData.imageUrl = userData.picture;
      userData.givenName = userData.name.split(" ")[0];
      userData.familyName = userData.name.split(" ")[1];
      const userObj = await updateUser(userData);
      if (!userObj) {
        toggleLogs && console.error('Error updating user');
        return;
      }

      // login
      const payload = { user: userObj, authToken: userData.authToken, loginMethod: 'linkedin' };
      authDispatch({ type: 'LOGIN_SUCCESS', payload: payload });
      localStorage.setItem('currentUser', JSON.stringify(payload))
      const fullName = userObj.firstName + (userObj.lastName ? ' ' + userObj.lastName : '');
      await newContact(userObj.email, fullName);

      // remove items used to login
      localStorage.removeItem("linkedinCodeTemp");
      localStorage.removeItem("linkedinStateTemp");
      localStorage.removeItem("linkedinHmacTemp");
      localStorage.removeItem("linkedinRedirectURI");
    }
  }
  catch (error) {
    console.error(error);
  }
}


/**
 * Deprecated
 * @param   {string} term  term to process
 * @return  {string} Properly capitalized version of a term
 */
export const processTerm = (term) => {
  if (term.length === 0) return '';
  if (term.length === 1) {
    switch (term) {
      case 'b':
        return `Bachelor's Degree`;
      case 'm':
        return `Master's Degree`;
      case 'a':
        return `Associate's Degree`;
      case 'p':
        return 'PhD';
      default:
        return term.toUpperCase();
    }
  } else {
    term = term.toLowerCase();
    // Replace all instances of terms in toReplace
    const toReplace = ['SQL', 'PHP', 'CSS', 'NLP', 'DB'];
    for (const e of toReplace) {
      const re = new RegExp(e.toLowerCase(), 'gi');
      term = term.replace(re, e);
    }

    const words = [];
    const capitalTerms = new Set(['css', 'scss', 'html', 'api', 'aws', 'ui', 'matlab', 'abap', 'acas', 'b2b', 'fdd', 'w3c', 'wadl', 'ibm', 'ide', 'sdk', 'ac', 'ab', 'cad', 'ocr', 'plm', 'sdd', 'sdlc', 'qc', 'qa', 'qnx', 'cli', 'ndk', 'ee', 'nlp', 'http', 'https', 'mba', 'ux', 'xaml', 'jsf', 'json', 'jsp', 'jss', 'csm', 'cspo', 'csv', 'cpi', 'ci', 'cpo', 'cpu', 'cqrs', 'cpc', 'cpm', 'cgi', 'i2c', 'ppc', 'pcb', 'pci', 'pwa', 'pmo', 'pmp', 'pos', 'bi', 'pop', 'cto', 'ctr', 'cisa', 'cissp', 'ccent', 'ccna', 'ccnp', 'cdi', 'cdn', 'ceh', 'cam', 'crm', 'cvpr', 'cvs', 'cuda', 'cms', 'cntk', 'cobit', 'jax-rs', 'jax-ws', 'jaxb', 'bdd', 'bgp', 'asf', 'adf', 'adfs', 'apc', 'asic', 'alm', 'rp', 'ansi', 'amp', 'amqp', 'awr', 'avr', 'gcc', 'gcd', 'gcia', 'gcih', 'gcm', 'gnu', 'grc', 'grpc', 'gsec', 'gui', 'gwt', 'ms']);
    const lowerTerms = new Set(['and', 'of', 'or', 'pytest', 'sqlmap', 'iOS', 'asyncio', 'gtest']);
    const otherTerms = { 'javascript': 'JavaScript', 'saas': 'SaaS', 'paas': 'PaaS', 'baas': 'BaaS', 'iaas': 'IaaS', 'jaas': 'JaaS', 'ios': 'iOS', 'oauth': 'OAuth', 'odata': 'OData', 'freebsd': 'FreeBSD', 'pytorch': 'PyTorch', 'pycharm': 'PyCharm', 'pyspark': 'PySpark', 'sqlalchemy': 'SQLAlchemy', 'phd': 'PhD', 'javafx': 'JavaFX', 'devops': 'DevOps', 'opencv': 'OpenCV', 'tensorflow': 'TensorFlow', 'xctest': 'XCTest', 'webrtc': 'WebRTC', 'weblogic': 'WebLogic', 'websphere': 'WebSphere', 'webgl': 'WebGL', 'influxdb': 'InfluxDB', 'typescript': 'TypeScript', 'soc': 'SoC', 'javacc': 'JavaCC', 'phonegap': 'PhoneGap', 'php-fpm': 'PHP-FPM', 'pmi-acp': 'PMI-ACP', 'circleci': 'CircleCI', 'codeigniter': 'CodeIgniter', 'gstreamer': 'GStreamer' };
    for (const word of term.split(' ')) {
      const w = word.toLowerCase();
      if (capitalTerms.has(w)) words.push(w.toUpperCase());
      else if (lowerTerms.has(w)) words.push(w);
      else if (otherTerms[w]) words.push(otherTerms[w]);
      else words.push(word[0].toUpperCase() + word.substring(1));
    }

    // Don't capitalize first word if exception
    if (lowerTerms.has(words[0])) {
      let ret = words[0];
      if (words.length > 1) {
        ret += ' ' + capitalize(words.slice(1).join(' '), ['/', '-']);
      }
      return ret;
    } else {
      return capitalize(words.join(' '), ['/', '-']);
    }
  }
}

/**
 * Deprecated
 * @param  {string} title title to process
 * @return {string} Properly capitalized version of a career title
 */
export const processCareerTitle = (title) => {
  if (formattedJobs.length === 0) return;
  if (title.length === 0) {
    console.error('[processCareerTitle] Error: empty career title');
    return '';
  }
  title = title.replace(/ /g, '').toLowerCase();
  const jobsDeformatted = formattedJobs.map(e => e.replace(/ /g, '').toLowerCase());
  const idx = jobsDeformatted.indexOf(title);
  if (idx === -1) {
    return 'Unknown career title';
  }
  return formattedJobs[idx];
}

export const ResultsTooltip = (props) => (
  <OverlayTrigger
    placement={props.placement}
    delay={props.delay}
    overlay={
      <Tooltip className="results-tooltip search-element">
        {props.message}
      </Tooltip>
    }
  >
    <Info className="match-info" size={props.infoSize} style={props.style} />
  </OverlayTrigger>
);

ResultsTooltip.defaultProps = {
  placement: 'top',
  delay: { show: 25, hide: 150 },
  message: 'The percentage matched indicates the percentage of job posts for this role that mention this keyword.',
  infoSize: 16
}

// Init Mixpanel analytics
const initMixpanel = async () => {
  await mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}
initMixpanel();

var dbOverride = "";
export const setDbOverride = (value) => {
  dbOverride = value;
  switch (dbOverride) {
    case "PathFinder":
      apiPrefix = "https://pathfinder9.herokuapp.com/v0"; break;
    case "PathFinderTest":
      apiPrefix = "https://pathfinder-test.herokuapp.com/v0"; break;
    default:
      console.warn("[Developer error] Invalid DB override: " + dbOverride);
  }
}
export const getDbOverride = () => dbOverride;

/**
 * PathFinder main App component
 */
const App = () => {
  // Used to re-render component for pages that require data to automatically be loaded in
  const [doUpdate, setDoUpdate] = useState(false);

  useEffect(() => {
    // Changes depending on environment
    const hostname = window.location.hostname;
    const tabIcon = document.querySelector('#app-icon');
    if (hostname === 'localhost') {
      apiPrefix = 'http://localhost:3010/v0';
      tabIcon.href = '/logo-dev.png';
      toggleLogs = true;
    } else if (hostname === 'staging.pathfinder.fyi' || hostname === 'pathfinder-test.netlify.app' || hostname === 'staging.localhost') {
      apiPrefix = 'https://pathfinder-test.herokuapp.com/v0';
      tabIcon.href = '/logo-test.png';
      toggleLogs = true;
    } else {
      apiPrefix = 'https://pathfinder9.herokuapp.com/v0';
      tabIcon.href = '/logo.png';
      toggleLogs = false;
    }
    setDoUpdate(prev => !prev);
  }, []);

  /**
   * Deprecated
   * Preload data
   */
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiPrefix}/data/jobs`, requestOptionsGet);
      if (!response.ok) throw response;
      /** @type {{ jobtitle: string, count: number}[]} */
      const data = await response.json();

      jobCounts = data.sort((a, b) => a.jobtitle < b.jobtitle ? - 1 : 1);
      jobCounts.forEach((job) => jobsList.push(job.jobtitle));
    } catch (error) {
      toggleLogs && console.error('Error fetching list of jobs\n', error);
    }

    const termTypes = getTermTypes(false);
    for (const termType of termTypes) {
      try {
        const response = await fetch(`${apiPrefix}/data/termsOfType/${termType}`, requestOptionsGet);
        if (!response.ok) throw response;
        /** @type {Object.<string, string[]>[]} */
        const data = await response.json();

        let termsList = [];
        for (let job of data) {
          termsList.push(Object.values(job)[0]);
        }
        allTermsLists[[termType]] = termsList;

      } catch (error) {
        toggleLogs && console.error(`Error fetching terms of type: ${termType}\n`, error);
      }
    }

    // Test endpoints here
    // const posts = await getCareerPosts('Data Scientist');
    // console.log('ds posts received:', posts);
    // const users = await fetchUsers();
    // console.log('users:', users)
    // const comments = await getComments('632706e9935be48edf9c2030');
    // console.log('comments received:', comments)

    // Update app when data has been loaded
    setDoUpdate(prev => !prev);
  }

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Switch>
            <Redirect from="/career/:title/testimonials" to="/career/:title/stories" />

            {routes.map((route) => (
              <AppRoute exact
                key={route.path}
                path={route.path}
                component={route.component}
                isPrivate={route.isPrivate}
                hasFooter={route.hasFooter}
                siteLoaded={siteLoaded()}
              />
            ))}

            <Redirect from="/field/" to="/field/software" />
            <Redirect from="/career/:title/" to="/career/:title/overview" />
            <Route exact path="/*" component={() => <NotFound />} />
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;