import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { X } from 'react-feather';
import { 
  getAllTermsLists, searchForTerms, reverseSearch, 
  getJobPercentages
} from '../../app';
import { capitalize, formatLink, setEq } from '../../util';
// import '../../css/advanced_search.css';
import './advanced_search.css';

const AdvancedSearch = (props) => {
  // Current set of terms user has selected to be searching for
  const [keywordsState, setKeywordsState] = useState(new Set([]));
  const [keywordElsState, setKeywordElsState] = useState(null);
  const keywordsRef = useRef();
  const [resultsState, setResultsState] = useState(null);
  const [resultsCount, setResultsCount] = useState(0);
  const searchBarRef = useRef();
  const [suggestionsState, setSuggestionsState] = useState(null);
  const suggestionsRef = useRef();
  const searchTerms = getAllTermsLists();

  useEffect(() => { // []
    document.title = 'Advanced Search - PathFinder';
    if (searchBarRef.current) {
      searchBarRef.current.addEventListener('keydown', handleSubmit);
    }
  }, []);

  useEffect(() => { // [keywordsState]
    if (keywordsState.size === 0) {
      setResultsState(null);
      return;
    }

    // Get results based on new keywords
    let resultsObj = {}; // { [jobTitle]: string, percentage: number }
    async function fetchData() {
      const keywords = keywordsState;
  
      for (let keyword of keywords) {
        for (const [termType, termsList] of Object.entries(searchTerms)) {
          const termsFound = searchForTerms(keyword, termsList);
  
          let foundMatch = termsFound[0] === keyword;

          if (!foundMatch) {
            continue;
          }
          // Display 'Searching...' if results are currently being displayed
          setResultsState(null);

          let formattedKeyword = formatLink(keyword);

          const searchResults = await reverseSearch(formattedKeyword, termType);

          // Abort if user changed input during search
          if (!setEq(keywords, getCurrentKeywords())) return;

          let jobPercentages = await getJobPercentages(searchResults, termType);

          // Abort if user changed input during search
          if (!setEq(keywords, getCurrentKeywords())) return;

          for (const jobPercentage of jobPercentages) {
            const jobTitle = jobPercentage.jobtitle;
            if (resultsObj[jobTitle]) {
              resultsObj[jobTitle] += jobPercentage.percentage;
            } else {
              resultsObj[jobTitle] = jobPercentage.percentage;
            }
          }

          break;
        }
      }

      let results = [];

      for (const [jobTitle, percentage] of Object.entries(resultsObj)) {
        results.push({
          jobtitle: jobTitle,
          percentage: percentage
        });
      }

      // Sort in ascending order
      results = results.sort((a, b) => b.percentage-a.percentage);
      // Weight by number of keywords
      results = results.map(e => { 
        return {
          jobtitle: e.jobtitle,
          percent: Math.round((e.percentage / keywords.size) * 100) / 100
        }
      });

      displayResults(results);
    }
      
    fetchData();
  }, [keywordsState]);

  // Returns a set containing the current keywords chosen by the user
  function getCurrentKeywords() {
    const res = new Set([]);
    const keywordsEl = keywordsRef.current;
    if (!keywordsEl.children) return new Set([]);

    for (const keywordEl of keywordsEl.children) {
      const keyword = keywordEl.innerText;
      res.add(keyword);
    }
    
    return res;
  }

  // Returns whether or not current search value matches one of the current suggestions
  function matchedTerm(term) {
    const suggestionsEl = suggestionsRef.current;
    if (suggestionsEl?.children) {
      const suggestions = suggestionsEl.children[0].innerText;
      const re = new RegExp('[💼💻🤹‍♂️🎓🏫🏭].+\n', 'g');
      const res = suggestions.replace(re, '');
      for (const suggestion of res.split('\n')) {
        if (suggestion.toLowerCase() === term.toLowerCase()) {
          return suggestion;
        }
      }
      return "";
    }
  }

  /**
   * Called when user inputs into search bar
   * Shows suggestions based on input
   */
  function handleInput() {
    let searchInput = searchBarRef.current.value.toLowerCase();
    const inputLen = searchInput.length;
    if (inputLen === 0) {
      setSuggestionsState(null);
      return;
    }

    const suggestions = {};
    const suggestionEls = [];

    for (const [termType, termsList] of Object.entries(searchTerms)) {
      const res = searchForTerms(searchInput, termsList);
      if (res[0] === searchInput) foundMatch = true;

      // First 5 results
      for (const term of res.slice(0, 5)) {
        if (!suggestions[termType]) suggestions[termType] = [];
        suggestions[termType].push(term);
      }
    }

    if (Object.entries(suggestions).length > 0) {
      const emojis = {'careers': '💼', 'languages': '💻', 'skills': '🤹‍♂️', 'degrees': '🎓', 'education': '🏫', 'industries': '🏭'};

      for (const [termType, termsList] of Object.entries(suggestions)) {
        // Type header
        suggestionEls.push(
          <li
            key={uuid()}
            className="result-header"
          >
            {emojis[termType]} <strong>{capitalize(termType)}</strong>
          </li>
        );

        // Items
        for (let term of termsList) {
          suggestionEls.push(
            <li
              key={uuid()}
              onClick={() => {
                if (!keywordsState.has(term)) {
                  addKeyword(term);
                }
              }}
              className="result"
            >
              {term.substring(0, inputLen)}<b>{term.substring(inputLen)}</b>
            </li>
          )
        }
      }
    }

    setSuggestionsState(<ul>{suggestionEls}</ul>);
  }

  /**
   * Updates keywordsState and keyword elements
   * @param {Set<string>} keywords set of keywords to update states to
   */
  function updateKeywords(keywords) {
    const keywordEls = [];

    for (const keyword of keywords) {
      keywordEls.push(
        <li
          key={uuid()}
          className="keyword"
        >
          {keyword}
          <X onClick={() => { // Not creating external function to keep keywords set in scope
            // should never happen
            if (!keywords.has(keyword)) {
              console.error(`Error: Trying to delete keword that was never entered: ${keyword}`);
              return;
            }

            keywords.delete(keyword);
            setKeywordsState(keywords);
            setKeywordElsState(keywordEls);
            updateKeywords(keywords);
          }} />
        </li>
      );
    }

    setKeywordsState(new Set(keywords));
    setKeywordElsState(keywordEls);
    setSuggestionsState(null);
  }

  function addKeyword(keyword) {
    if (keywordsState.has(keyword) || keyword.length === 0) {
      // Keyword is invalid
      return;
    }

    const newKeywords = new Set([...keywordsState, keyword]);
    searchBarRef.current.value = '';
    updateKeywords(newKeywords);
  }

  /**
   * Called when enter is pressed in search bar
   * Adds current input to list of keywords if not currently present
   * @param {KeyboardEvent} evt keydown event
   */
  function handleSubmit(evt) {
    if (evt.key === 'Enter') {
      const val = searchBarRef.current.value;
      if (val.length === 0) return;
      const keywords = getCurrentKeywords();
      const term = matchedTerm(val);

      // If search term is valid and not already selected
      if (term.length > 0 && !keywords.has(val)) {
        updateKeywords(keywords.add(term));
        searchBarRef.current.value = '';
      }
    }
  }

  /**
   * Generates JSX for results 
   * @param {Object[]} resultsObj results
   * @param {String} resultsObj[].jobtitle name
   * @param {Number} resultsObj[].percent percent match
   */
  // Similar to Results.jsx, could reuse that component in future here
  function displayResults(resultsObj) {
    const resultElements = [];
    let count = 0;
    if (resultsObj) {
      for (const [i, e] of resultsObj.entries()) {
        resultElements.push(
          <li
            key={uuid()}
            className="result"
            title={e.jobtitle}
            onClick={() => {
              props.history.push(`/career/${formatLink(e.jobtitle)}`)
            }}
          >
            {e.jobtitle}
            <span className="percent-match-container">{e.percent}%</span>
          </li>
        );
        count++;
      }
    }
    setResultsState(resultElements);
    setResultsCount(count);
  }

  /**
   * Creates results title string based on keywords
   * @param {Set<string>} keywords set of keywords to display
   * @return {string} string containing keywords separated with commas
   */
  function displayResultsTitle(keywords) {
    let resultsTitle = "";
    for (const keyword of keywords) {
      resultsTitle += keyword + ", ";
    }
    return resultsTitle.slice(0, -2);
  }

  return (
    <div className="advanced-search-component container">
      <h3 className="header-margin">Advanced Search</h3>

      <div className="advanced-search-container">
        <input 
          placeholder="Search for a keyword" 
          onChange={() => handleInput()}
          ref={searchBarRef}
          className="advanced-search-bar"
        />
        {suggestionsState &&
          <div 
            className="suggestions"
            ref={suggestionsRef}
          >
            {suggestionsState}
          </div>
        }
        <div 
          className="keywords"
          ref={keywordsRef}
        >
          {keywordElsState}
        </div>
      </div>
      <div className="advanced-search-results">
        {keywordsState.size > 0 && !resultsState &&
          <div className="header-margin">
            <span className="spinner-border spinner-border-sm"></span>
            <span className="searching">Searching...</span>
          </div>
        } 
        {resultsState &&
          <>
            <h3 className="header-margin">Results for {displayResultsTitle(keywordsState)}</h3>
            <p className="count-results">{resultsCount} results found.</p>
            <ul className="display-advanced-search-results">
              <div className="results-header">
                <b className="title-header">Job Title</b>
                <b className="percent-header">Percent Weighted Match</b>
              </div>
              {resultsState}
            </ul>
          </>
        }
      </div>
    </div>
  )
}

export default withRouter(AdvancedSearch);