import { useState, useEffect } from 'react';
import { useSubdomain, setSubdomainOverride } from '../../hooks';
import { getDbOverride, setDbOverride } from '../../app';
import './debug.css';
import NotFound from '../NotFound';

const Debug = () => {
  const [subdomainSetting, setSubdomainSetting] = useState("");
  const [dbSetting, setDbSetting] = useState("");

  const subdomain = useSubdomain();

  useEffect(() => {
    document.title = 'Debug Settings - PathFinder';

    setSubdomainSetting(subdomain);

    const dbOverride = getDbOverride();
    if (dbOverride.length > 0) setDbSetting(dbOverride);
    else {
      const hostname = window.location.hostname;
      if (hostname === 'localhost') setDbSetting('PathFinderTest');
      else setDbSetting('PathFinder');
    }
  }, []);

  const isValidUrl = () => {
    const url = window.location.host;
    return (url.includes('localhost') || url.includes('staging'));
  }

  const overrideSubdomain = (value) => {
    setSubdomainOverride(value);
    setSubdomainSetting(value);
  }

  const overrideDatabase = (value) => {
    setDbOverride(value);
    setDbSetting(value);
  }

  return (
    <div className="debug-component container">
      {isValidUrl() ? <>
        <h1 className="mb-4">PathFinder Debug Settings</h1>
        <div className="overrides">
          <div className="subdomain-overrides">
            <h5>Override subdomain (Current value: <b>{subdomainSetting === '' ? "<none>" : subdomainSetting}</b>)</h5>
            <div className="input-group w-50 mb-3">
              <input 
                type="text" 
                className="form-control" 
                spellCheck="false"
                onKeyDown={(e) => e.key === 'Enter' && overrideSubdomain(e.target.value)} />
              <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={() => overrideSubdomain('')}>Reset override</button>
              </div>
            </div>
            <div className="debug-community">
              <button className="btn btn-secondary bitsinbio-background " type="button" onClick={() => overrideSubdomain('bitsinbio')}>bitsinbio</button>
              <button className="btn btn-secondary bobatalks-background " type="button" onClick={() => overrideSubdomain('bobatalks')}>bobatalks</button>
            </div>
          </div>
          <div className="database-override mt-5">
            <h5>Override database</h5>
            <button className={"btn me-2 " + (dbSetting === "PathFinder" ? "btn-primary" : "btn-outline-primary")} type="button" onClick={() => overrideDatabase('PathFinder')}>PathFinder</button>
            <button className={"btn " + (dbSetting === "PathFinderTest" ? "btn-primary" : "btn-outline-primary")} type="button" onClick={() => overrideDatabase('PathFinderTest')}>PathFinderTest</button>
          </div>
        </div></> 
        : <NotFound />}
    </div>
  )
}

export default Debug;