import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import './about.css';
import rafikiImg from './../../images/aboutImg/rafiki.png';

const About = (props) => {
  const [devState, setDevState] = useState(<div></div>);
  const [productState, setProductState] = useState(<div></div>);
  const [internState, setInternState] = useState(<div></div>);
  const [pastState, setPastState] = useState(<div></div>);
  let teamData = {
    'Development Team': [
      {
        name: 'Ming Jeng',
        role: <>
        <em>Co-Founder</em><br />
      </>,
      },
      {
        name: 'Eric Ngor',
        role: <>
          <em>Co-Founder</em><br />
        </>,
      },
      {
        name: 'Andrew C.',
        role: <>
        <em>Engineer</em>
      </>,
      },
      {
        name: 'Tau J.',
        role: 'Engineer',
      },
      {
        name: 'Mariah M.',
        role: 'Engineer',
      },
      {
        name: 'Inan X.',
        role: 'Engineer',
      }
    ],
    'Design Team': [
      {
        name: 'Lauren H.',
        role: 'Designer',
      },
      {
        name: 'Angie Y.',
        role: 'Designer',
      }
    ],
    'Interns': [
      {
        name: 'Aashna B.',
      },
      {
        name: 'Siddhant S.',
      },
      {
        name: 'Vinish D.',
      }
    ],
    'Past Contributors': [
      'Chainy Carson','Ashley Hii','Alice Liang','Kimberly Szeto','Atira Nair', 'Ivy Ho'
    ]
  }

  useEffect(() => {
    document.title = 'About Us - PathFinder';
    const aboutTab = document.querySelector('#nav-about');
    if (aboutTab) {
      aboutTab.classList.add('selected');
    }
    drawMeetTheTeam();

    return () => {
      if (aboutTab) {
        aboutTab.classList.remove('selected');
      }
    };
  }, []);
  function drawMeetTheTeam() {
    let devElements = [];
    for (const member of teamData['Development Team']) {
      devElements.push(
        <div className="teammate" key={member.name}>
          <img src={`../about/${member.name}.jpg`} alt={member.name}></img>
          <strong>{member.name}</strong>
          <em>{member.role}</em>
        </div>
      );
    }

    setDevState(<div className="team-row">{devElements}</div>);
    
    let productElements = [];
    for (const member of teamData['Design Team']) {
      productElements.push(
        <div className="teammate" key={member.name}>
          <img src={`../about/${member.name}.jpg`} alt={member.name}></img>
          <strong>{member.name}</strong>
          <em>{member.role}</em>
        </div>
      );
    }
    setProductState(<div className="team-row">{productElements}</div>);

    let internElements = [];
    for (const member of teamData['Interns']) {
      internElements.push(
        <div className="intern" key={member.name}>
          {member.name}
        </div>
      
      // <>
      //   <span key={member.name}>{member.name}</span> - <em>{member.role}</em>
      // </>
      );
    }
    setInternState(<div>{internElements}</div>);
    

    let pastElements = [];
    for (const member of teamData['Past Contributors']) {
      pastElements.push(
        <p key={member}>{member}</p>
      );
    }
    setPastState(<div>{pastElements}</div>);
  }

  return (
    <div className="about-component">
      <div className="mission-container">
        <div className="mission-content">
          <img
            className="about-mission rafiki"
            src={rafikiImg}
            alt="rafiki"
          />
          <div className="mission-text">
            <h1>Our Mission</h1>
            We want to scale up career path navigation.
          </div>
        </div>
      </div>
      <div className="story-container">
        <h1>Our Thesis</h1><br/>
        <p>
        <b>The Why.</b><br/>
         Having a career you enjoy is the most important factor to living a fulfilled life according to Pew Research. Yet there is currently no good and scalable solution for career path navigation. 
        </p>
        <p>
        <b>The Problem.</b><br/>
        Here’s some real people we know personally that can be used as mental examples to illustrate the problem: <br/>
        <i>Fred: “I am a burnt out dermatologist doctor but I want to do something in the intersection of technology/dermatology.” <br/>
        Janice: “I am a strategy consultant but I want to transition into my passions in sustainability and policy.” <br/>
        Dan: “I have a Math degree but I want to be an AI researcher and apply AI to law enforcement applications - specifically to counter human trafficking.”
        </i> <br/>
        What would you do if you were them? How would you find people that can help them? <br />
        Seriously, think about it. 
        For such a common theme, there is no existing universal solution right now. Existing methods are just some form of tedious trial-and-error lookups. You can look tediously over dozens of Linkedin profiles, articles, videos and still not find the right person or content that matches this level of specificity. 
        </p>
        <p>
          <b>The Opportunity.</b><br/>
        We believe everyone’s career journey is a unique <i>Story</i> that can help others facing similar dilemmas. Despite there being an exhaustive combination of people's experiences, disciplines, interests, and values, there are also billions of <i>Stories</i> out there that are untapped.
        Surely, there is a person with that “perfect match” who can help Fred, Dan, and finally… you. <br />
        </p>
        <p>
        That’s why we are building PathFinder – to unlock these hidden <i>Stories</i> so we can help everyone pursue their passions faster. Life is too precious to waste on unfulfilling work.
        </p>
        <p>
        PathFinder is a platform for sharing career stories, inspiring and aiding others. We're developing an AI to match you with the perfect <i>Story</i>.
        </p>
        <p>
          You can contribute by submitting your own <i>Story</i>. We are also working with professional communities. Would love to hear your ideas down below in our Contact & Feature Requests section.
        </p>
      </div>
      <div className="team-container">
        <h1>Meet the Team</h1>
          <div className="dev-team">
            <i>Development Team</i>
            {devState}
          </div>
          <div className="product-and-past-team">
            <div className="product-team">
              <i>Design Team</i>
              {productState}
            </div>
            <div className="interns">
              <i>Interns</i>
              {internState}
            </div>
            <div className="past-contributors">
              <i>Past Contributors</i>
              {pastState}
            </div>
          </div>
        </div>
      <div className="feedback-container">
        <h1>Have a burning idea for PathFinder? Submit a feature request!</h1><br/>
        <p>We <strong>love</strong> feature requests and want to continue to build features tailored for you. You can put feature requests in this form. Will get right back to you!</p>
        <a 
          href="https://docs.google.com/forms/d/e/1FAIpQLSeyGTO5hp8zDM9MYFTN-BfmUaODbjnbZ-J1PpDo5R3cy8gXfg/viewform"
          onClick={() => {
            props.mixpanel.track('Clicked feedback link', { from: 'about' });
          }}
        >
          <button className="feature-request-btn">
          Submit a Feature Request
          </button>
        </a>
      </div>
      <div 
        className="contact-container"
        style={{
          backgroundImage: "url('./aboutImg/blueLayer1.png')",
        }}>
        <div className="contact-text">
          <h1>Contact Us</h1>
          {/* Our email is <a href="mailto:team@pathfinder.fyi"><strong>team@pathfinder.fyi</strong></a> for any further comments and questions. We wish you the best of luck on finding your path! */}
          <span>You can reach us at <a href="mailto:team@pathfinder.fyi"><strong>team@pathfinder.fyi</strong></a>, or join the conversation in our Discord server!</span>
        </div>
        <a href="https://discord.gg/zB8yfE4thX" className="discord-widget" target="_blank" rel="noopener noreferrer">
          <img src="https://discordapp.com/api/guilds/855179461771395084/widget.png?style=banner3" alt=""></img>
        </a>
        {/* <iframe src="https://discord.com/widget?id=855179461771395084&theme=dark" width="350" height="300" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe> */}
      </div>
    </div>
  );
}
export default withRouter(About);
