import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createNewUser } from "../../app";
import { useAuthDispatch } from '../../context';
import "./name_popup.css";

const NamePopup = ( { token }) => {
  const authDispatch = useAuthDispatch();
  const history = useHistory();

  const modal = useRef(null);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const [isDisplayFirstNameOnly, setIsDisplayFirstNameOnly] = useState(false);

  useEffect(() => {
    modal.current.showModal();
  }, [modal]);

  const handleClickSubmit = async (e) => {
    e.preventDefault();

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;

    /* Call function to send request to create new user */
    const res = 
      await createNewUser(authDispatch, token, firstName, lastName, isDisplayFirstNameOnly);

    if (!res) alert('Something went wrong with creating your account');
    history.push('/');
  }

  const handleFirstNameCheckbox = () => {
    setIsDisplayFirstNameOnly(!isDisplayFirstNameOnly);
  }

  return (
    <dialog ref={modal} className="namePopup">
        <div className="layout">
            <section className="buttonSection">
                <h3 className="blueText">
                  Hello, help us get to know you!<br />
                  What's your name?
                </h3>
                <form className="nameSection" onSubmit={handleClickSubmit}>
                    <label className="nameLabel">First Name</label>
                    <input
                        type="firstName"
                        id="firstName"
                        className="nameInput"
                        placeholder="First Name"
                        ref={firstNameRef}
                    />
                    <label className="nameLabel">Last Name</label>
                    <input
                        type="lastName"
                        id="lastName"
                        className="nameInput"
                        placeholder="Last Name"
                        ref={lastNameRef}
                    />
                    <span className="checkboxContainer">
                      <input
                        type="checkbox"
                        onChange={() => handleFirstNameCheckbox()}
                        checked={isDisplayFirstNameOnly}
                        id={"firstNameCheckbox"}
                      />
                      <h5 className="blueText firstNameText">
                        Only display first name to others
                      </h5>
                    </span>
                    <button type="submit" className="getStartedButton">Get Started</button>
                </form>
            </section>
        </div>
    </dialog>
  );
}

export default NamePopup;