import { useRef, useState } from 'react';
import './chat_box.css';
import { getCommunityData } from '../../../app';
import { useSubdomain } from '../../../hooks';

const ChatBox = ({ handleSubmit }) => {
  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));
  const inputBox = useRef(null);

  function handleEnter(event) {
    const isEnter = event.key === "Enter";
    if (!isEnter) {
      return;
    }
    if (!event.shiftKey) {
      event.preventDefault();
      handleSubmit(inputBox.current.value, inputBox);
    }
  }

  function autoResize() {
    const textarea = inputBox.current;
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);

    textarea.style.height = "2rem";

    const numberOfLines = Math.floor(textarea.scrollHeight / lineHeight);

    if (numberOfLines === 1) {
      return;
    } else {
      textarea.style.height = numberOfLines * lineHeight + 'px';
    }
  }

  function focusInput() {
    inputBox.current.focus();
  }


  return (
    <div className="chatInput" onKeyPress={handleEnter} onClick={focusInput}>
      <img className="chatInput-sparkles" src="/icons/sparkles.png" alt="sparkles" draggable="false"/>
      <textarea 
        ref={inputBox}
        className='inputBox'
        onInput={autoResize}
        placeholder={community && community.AIChat ? community.AIChat.chatPrompt : 'Ask AI to help you find the right career path'}></textarea>
      <button className="chatInput-submit" type="submit" onClick={() => {handleSubmit(inputBox.current.value, inputBox)}}>
        <img src="/icons/submitArrow.png" alt="submit button" />
      </button>
    </div>
  );
};

export default ChatBox;