import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { ChevronUp, ChevronDown } from 'react-feather';
import { AIChat, CommunityLanding } from '../../components';
import { useIsMountedRef, useSubdomain } from '../../hooks';
import { getCommunityData, newContact } from '../../app';
import { useAuthState } from '../../context';
import './landing.css';

const Landing = (props) => {
  //const [fieldContainerState, setFieldContainerState] = useState(null);
  const subscribeRef = useRef();
  const subscribeInputBoxRef = useRef();
  const subscribeInputRef = useRef();
  const subscribeFeedbackRef = useRef();
  const isMountedRef = useIsMountedRef();
  const [activeAIChat, setActiveAIChat] = useState(false);

  const userDetails = useAuthState();

  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));

  // temporary
  const featuredStories = [
    {
      title: 'FAANG Principal Engineer answers common career questions',
      author: 'John Miller',
      authorImg: './avatars/john_miller.jpg',
      splashImg: './landingAiChat/featured_1.png',
      href: '/story?post=63bbc7c547acfe45bbe13355',
    },
    {
      title: 'Hear from an x7 Career Pivoter on landing that Product Design offer',
      author: 'Jean Kang',
      authorImg: './avatars/jean_kang.jpg',
      splashImg: './landingAiChat/featured_2.png',
      href: '/story?post=64d08faf7658d6ec6d57fdc4',
    },
    {
      title: 'AI for Gaming and Graphics',
      author: 'Daiana',
      authorImg: './default_avatar.png',
      splashImg: './landingAiChat/featured_3.png',
      href: '/story?post=63bbc7be41618356c1f3e25b',
    },
  ];

  /*
  const history = useHistory();
  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    if (!isRedirected && community) {
      history.push('/discover');
      setIsRedirected(true);
    }
  }, [history, isRedirected]);
  */

  useEffect(() => { // []
    document.title = 'PathFinder';

    // Scroll down if url contains id
    const linkedEl = window.location.hash;
    if (linkedEl === '#subscribe') {
      setTimeout(() => {
        subscribeRef.current?.scrollIntoView();
      }, 100);
    }

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <div className="landing-component">
      {community ?
      <CommunityLanding mixpanel={props.mixpanel} active={activeAIChat} setActive={setActiveAIChat}/> :
      <div className="landing-container">
        <AIChat mixpanel={props.mixpanel} active={activeAIChat} setActive={setActiveAIChat}/>
        <div className="red-gradient" />
        <div className="yellow-gradient" />
        <div className="blue-gradient" />

        {!userDetails.user ? 
        <div className="landing-sign-in-suggestion">
          <p><span className="landing-sign-in-link landing-colorText" onClick={() => /* clean up later */ props.setShowSignin.setShowSignin(true)}>Sign in</span> for free to be part of the community and access all other features!</p>
        </div>
        : null}

        <div className="down-button">
          <p>Learn more</p>
          <ChevronDown
            className="arrow-down"
            onClick={() => document.getElementById("instant-answers").scrollIntoView({ behavior: 'smooth', block: 'center' }) /*window.scroll({ top: 1044, behavior: 'smooth' })*/ }
          />
        </div>

        {/* Instant Answers Section */}
        <div className="landing-instant-answers-container container">
          <div id="instant-answers" className="landing-instant-answers-left">
            <h1>
              Instant Answers
            </h1>
            <p>
              AI powered chat offers answers to your question and mentor/peer matching
            </p>
            <h6>
              <span className="landing-colorText landing-try-now" onClick={() => window.scrollTo(0,0)} >Try it Now</span>
            </h6>
          </div>
          <div className="landing-instant-answers-right">
            <div className="landing-instant-answers-question">
              <span>Engineering New Grad that got offers from a Startup and Big Tech, which one to choose?</span>
            </div>
            <img
              className="landing-img"
              width="758px"
              height="681px"
              src="./landingAiChat/instantAnswers.png"
              alt="AI Chat Response Sample"
              loading="lazy"
            />
          </div>
        </div>

        {/* Featured Stories Section */}
        <div className="landing-featured-stories-container container">
          <h1>Check out our featured stories</h1>
          <p className="landing-featured-stories-description">
            Click on the stories below to delve into the experiences and expertise of our professionals,
            enabling you to gain valuable insights into different career paths and make informed decisions about your own career choices.
          </p>

          {/* cards are hardcoded for now */}
          <div className="landing-featured-stories-cards">
            <div className="landing-featured-row">
              {featuredStories.map(s =>
              <a href={s.href} className="landing-featured-card" key={s.title}>
                <img
                  className="landing-featured-splash-img"
                  alt="featured story preview"
                  src={s.splashImg}
                />
                <div className="landing-featured-content-container">
                  <h5>{s.title}</h5>
                  <div>
                    <img
                      className="landing-featured-profile-img avatar"
                      alt="featured author profile image"
                      src={s.authorImg}
                    />
                    <strong>{s.author}</strong>
                  </div>
                </div>
              </a>)}
            </div>
          </div>
          <a href="/discover"><button className="landing-button">More Stories</button></a>
        </div>

        {/* Join Experts Section */}
        <div className="landing-join-experts-container container">
          <div className="landing-join-experts-left">
            <img
              className="landing-img join-experts-img"
              width="733.826px"
              height="601px"
              src="./landingAiChat/joinExperts.png"
              alt="Story Posts Sample"
              loading="lazy"
            />
          </div>
          <div className="landing-join-experts-right">
            <h1>Join a community of experts</h1>
            <p>
              Want to share your own job experience? Create your first story about your job experience with PathFinder today!
            </p>
            <a href="/discover"><button className="landing-button">Discover</button></a>
          </div>
        </div>
        
        {/* Try PathFinder Today Section */}
        <div className="landing-try-today-container container">
          <div className="landing-try-today-left">
            <div>
              <h1>Try PathFinder today</h1>
              <p>
                Bond with others in your industry to gain helpful stories by colleagues and coworkers
              </p>
            </div>
            {userDetails.user ? 
            <button className="landing-button" onClick={() => window.scrollTo(0,0)}>Try Now</button>
            :
            <button className="landing-button" onClick={() => /* clean up later */ props.setShowSignin.setShowSignin(true)}>Sign In</button>}
          </div>
          <div className="landing-try-today-right">
            <img
              className="landing-img try-today-img"
              src="./landingAiChat/tryPathfinderToday.png"
              alt="PathFinder Comments Sample"
              loading="lazy"
            />
          </div>
        </div>

        <div className="subscribe-container container">
          <div id="subscribe" ref={subscribeRef}>
            <h1>
              Subscribe to our newsletter
            </h1>
            <div className="subscribe-form">
              <div className="subscribe-feedback" ref={subscribeFeedbackRef}>&nbsp;</div>
              <div id="email-input-box" ref={subscribeInputBoxRef}>
                <input 
                  id="email-input-inner"
                  type="email" 
                  spellCheck="false"
                  placeholder="Enter your email to join!"
                  ref={subscribeInputRef}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    if (val.length !== 0) {
                      subscribeInputBoxRef.current.classList.add('not-empty');
                    } else {
                      subscribeInputBoxRef.current.classList.remove('not-empty');
                    }
                  }}
                />
                <button id="email-input-button" className="landing-button" onClick={async () => {
                  const input = subscribeInputRef.current.value;
                  subscribeFeedbackRef.current.classList.add('show');

                  if (input.indexOf('@') > -1) {
                    subscribeFeedbackRef.current.classList.remove('invalid')
                    const res = await newContact(input);

                    if (res) {
                      subscribeFeedbackRef.current.classList.add('success');
                      subscribeFeedbackRef.current.innerText = 'Thanks for subscribing!';
                    } else {
                      subscribeFeedbackRef.current.classList.add('existing-contact');
                      subscribeFeedbackRef.current.innerText = 'This email address is already subscribed to PathFinder.';
                  }

                  } else {
                    subscribeFeedbackRef.current.classList.add('invalid');
                    subscribeFeedbackRef.current.classList.remove('success');
                    subscribeFeedbackRef.current.innerText = 'Please enter a valid email address.'
                  }
                }}>Subscribe</button>
              </div>
            </div>
            <img
              className="mailbox"
              width="395"
              height="353"
              src="./landingAiChat/subscribeMailbox.png"
              alt="Mailbox"
              loading="lazy"
            />
          </div>
        </div>
        <ChevronUp className="arrow-up" size="50" onClick={() => window.scrollTo(0,0)} />
      </div>}
    </div>
  );
};

export default withRouter(Landing);
