import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { X, Plus } from 'react-feather';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FAQCard, Comments, Share, Story, Example } from '../../components';
import { getCareerPosts, getStories } from '../../app';
import { processLink } from '../../util';
import { useIsMountedRef } from '../../hooks';
import './stories.css';

// Burner account keys
// const X_MASTER_KEY = '$2b$10$TCCyCx2w8n4wnUqcKYg/HuxYd007mW67baU8PMFCE4Wr8fZvhbz3G';
// const BIN_ID = '61a97b9f62ed886f9159154b';
// const BIN_VER = 'latest';

/**
 * @typedef {import('../../components/FAQCard/FAQCard').PostInfo} PostInfo
 */

const Stories = (props) => {
  const [jobTitle, setJobTitle] = useState('');
  const [filterDropdowns, setFilterDropdowns] = useState(<></>);
  const [selectedFiltersEls, setSelectedFiltersEls] = useState(<></>);
  const selectedFiltersRef = useRef(new Set()); // Keeps track of selected filters across callbacks
  const [cardsState, setCardsState] = useState([]);
  const cardsInfoRef = useRef([]); // For filtering
  const [selectedCard, setSelectedCard] = useState(null);
  const [faqOffsetHeight, setFaqOffsetHeight] = useState(0);
  const [questions, setQuestions] = useState([]);
  const expandedCardContainerRef = useRef(<></>);
  const expandedCardModalRef = useRef(<></>);
  const faqContainerRef = useRef(<></>);
  const previewContainerRef = useRef(<></>);
  const commentsRef = useRef(<></>);
  const [sortTypeState, setSortTypeState] = useState('none');
  const [currentPost, setCurrentPost] = useState('')

  const isMountedRef = useIsMountedRef();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const deviceSize = useRef('');
  const screenWidth = Math.min(window.outerWidth, window.innerWidth);
  if (screenWidth <= 767) {
    deviceSize.current = 'mobile';
  } else if (screenWidth > 1024) {
    deviceSize.current = 'desktop';
  }

  useEffect(() => { // []
    setJobTitle(processLink(props.match.params.title));
    selectedFiltersRef.current = new Set([]);

    props.history.listen((location) => {
      if (!isMountedRef.current) return;
      if (location.pathname.match(/\/stories/)) {
        updateFaqContainerHeight();
      }
    })

    function handleResize() {
      const newWidth = Math.min(window.outerWidth, window.innerWidth);
      if (newWidth <= 767) {
        if (deviceSize.current !== 'mobile') {
          // On resize to mobile
          if (expandedCardContainerRef.current.style) {
            expandedCardContainerRef.current.style.display = 'none';
            // const selectedCard = document.querySelector('.selected');
            // if (selectedCard) selectedCard.classList.remove('selected');
            expandedCardModalRef.current.classList.remove('d-block');
          }
        }
        deviceSize.current = 'mobile';
      } else if (newWidth > 767) {
        if (deviceSize.current === 'mobile') {
          // On resize to tablet+ from mobile
          if (expandedCardContainerRef.current.style) {
            expandedCardContainerRef.current.style.display = 'block';
          }
        }
        deviceSize.current = 'desktop';
      }

      if (faqContainerRef.current?.offsetHeight !== faqOffsetHeight) {
        updateFaqContainerHeight();
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      isMountedRef.current = false;
    }
  }, []);

  useEffect(() => { // [jobTitle]
    if (!jobTitle) return;

    const fetchStories = async () => {
      const stories = await getCareerPosts(jobTitle);
      initPosts(stories);
    };

    fetchStories();
  }, [jobTitle]);

  useEffect(() => { // [questions]
    if (!jobTitle) return;
    
    const filterOptions = {
      'Date Posted': [
        'Today', 'This week', 'This month', 'This year'
      ],
      'Experience Type': [
        'Full Time Employee', 'Intern', 'Undergrad Researcher', 'Researcher', 'Contractor', 'Freelance', 'Entrepreneur'
      ],
      'Question': [
        "TLDR: What do you do at your job?",
        "Can you explain what you (or your team) is currently building and what your role entails as part of this team?",
        "What's the process of getting this job?",
        "What hard skills do you use for your job?",
        "What key things helped you the most in getting this job?",
        "What's your favorite thing about your job/career?",
        "What don't you like about your job/career?",
      ]
    };

    const filterEls = [];
    for (const [filterCategory, filterValues] of Object.entries(filterOptions)) {
      const buttonID = `${filterCategory}-dropdown`;
      const menuEls = [];
      for (const filterValue of filterValues) {
        menuEls.push(
          <li 
            id={uuid()}
            data-filtervalue={filterValue}
            className="dropdown-item" 
            key={uuid()} 
            onClick={(element) => {
              element.stopPropagation(); // keep dropdown open on click

              const checkbox = document.querySelector(`[data-filtervalue="${filterValue}"] input`);
              if (checkbox.checked) {
                let newFilters = selectedFiltersRef.current;
                for (const filter of newFilters) {
                  if (filter.value == filterValue) {
                    selectedFiltersRef.current.delete(filter);
                    checkbox.checked = false;
                  }
                }
                updateFilters(newFilters);
              } else {
                addFilter({
                  category: filterCategory,
                  value: filterValue
                });

                props.mixpanel.track('Selected filter', {
                  category: filterCategory,
                  value: filterValue
                });
              }
            }}
            title={filterCategory === 'Question' ? filterValue : undefined}
          >
            <input 
              type="checkbox" 
              className="dropdown-item-checkbox"
              onClick={(element) => {
                // undo users input, ideally the checkbox would be disabled but styled
                // this propagates to parent element li onClick() afterward
                element.target.checked = !element.target.checked; 
              }}
            />
            {filterValue}
          </li>
        );
      }
      filterEls.push(
        <span className="dropdown" key={uuid()}>
          <button className="btn btn-secondary dropdown-toggle" type="button" id={buttonID} data-bs-toggle="dropdown" aria-expanded="false">
            {filterCategory}
          </button>
          <ul className="dropdown-menu" aria-labelledby={buttonID}>
            {menuEls}
          </ul>
        </span>
      );
    }
    setFilterDropdowns(filterEls);
  }, [questions]);

  useEffect(() => { // [cardsState]
    if (!cardsState.length) return;
    updateFaqContainerHeight();
  }, [cardsState]);


  /**
   * Initializes posts state given posts details
   * @param {PostInfo[]} postInfos
   */
  function initPosts(postInfos) {
    if (postInfos.length === 0) return;

    let newCards = [];
    let newInfo = [...postInfos];

    // Sort by date
    if (sortTypeState === 'date') {
      // Sort by newest first
      newInfo.sort((a, b) => {
        return (new Date(b.date)) - (new Date(a.date));
      });
    }

    // Cherry-picked post priority
    const topPostIds = [
      '63bbc7be41618356c1f3e258', // Eric Ngor - Google One
      '63bbc7be41618356c1f3e259', // Ming Jeng - Okta
    ]
    const topPostInfos = [];
    for (const postId of topPostIds) {
      for (let i=0; i < newInfo.length; ++i) {
        if (newInfo[i].post._id === postId) {
          topPostInfos.push(newInfo.splice(i, 1)[0]);
        }
      }
    }
    newInfo = topPostInfos.concat(newInfo);
    
    // Sort spotlight posts
    newInfo.sort((a, b) => {
      return ((b.post.spotlight || 0) - (a.post.spotlight || 0));
    });

    const questionsSet = new Set();
    
    // Get the ID of linked post from URL
    const linkedPost = newInfo.find(info => info.post._id === searchParams.get('post'));
    const selectedPost = linkedPost ? linkedPost : newInfo[0];

    for (const postInfo of newInfo) {
      const selected = postInfo.post._id === selectedPost.post._id && (deviceSize.current !== 'mobile' || linkedPost);
      console.log(postInfo)
      newCards.push(
        <FAQCard info={postInfo} setCurrentPost={setCurrentPost} key={uuid()} mode="preview" setSelectedCard={setSelectedCard} selected={selected} expandedCardModal={expandedCardModalRef} fromLink={linkedPost && selected}  mixpanel={props.mixpanel} />
      );
      // Generate list of questions present from post info
      postInfo.post.responses.forEach((response) => {
        if (!questionsSet.has(response.question)) {
          questionsSet.add(response.question);
        }
      });
    }
    setQuestions([...questionsSet]);

    if (newInfo.length > 0) {
      setCardsState(newCards);
      cardsInfoRef.current = newInfo;
      let currentPost = linkedPost ? linkedPost : newInfo[0];
      
      setCurrentPost(currentPost);
      // setSelectedCard(
      //   <FAQCard info={currentPost} setCurrentPost={setCurrentPost} key={uuid()} mode="expanded" expandedCardModal={expandedCardModalRef} mixpanel={props.mixpanel} />
      // );
      setSelectedCard(
        <Story storyData={currentPost} type="story"/>
      );

      // Do not display expandedCard on mobile unless there is linked post
      if (deviceSize.current === 'mobile' && expandedCardContainerRef.current.style && !linkedPost) {
        expandedCardContainerRef.current.style.display = 'none';
      }

      // Scroll to linked post
      const selectedEleScroll = document.querySelector('.selected');
      if (selectedEleScroll && linkedPost) {
        selectedEleScroll.style.setProperty(
          'scroll-margin-top', '80px'
        );
        selectedEleScroll.scrollIntoView();
      }
    }
  }

  /**
   * Handles selection of a filter \
   * Replaces any selected "Date Posted" filters if a "Date Posted" filter was selected \
   * Does nothing if filter was already selected
   * @param newFilter the new filter
   * @param newFilter.category the category of the filter (e.g. "Date Posted")
   * @param newFilter.value    the value of the filter (e.g. "This week")
   */
  function addFilter(newFilter) {
    if (!newFilter) {
      console.error('Error: Invalid filter');
      return;
    }

    for (let filter of selectedFiltersRef.current) {
      // Filter is already selected
      if (filter.value === newFilter.value) return;
      // Replace current Date Posted filter
      if (filter.category === 'Date Posted' 
          && filter.category === newFilter.category) {
          selectedFiltersRef.current.delete(filter);
          document.querySelector(`[data-filtervalue="${filter.value}"] input`).checked = false;
      }
    }

    document.querySelector(`[data-filtervalue="${newFilter.value}"] input`).checked = true;
    const newFilters = new Set([...selectedFiltersRef.current, newFilter]);
    updateFilters(newFilters);
  }

  /**
   * Updates displayed filters and posts based on new filters
   * @param {Set<filter>} filters new set of filters to be updated to
   * @param filter.category the category of the filter (e.g. "Date Posted")
   * @param filter.value    the value of the filter (e.g. "This week")
   */
  function updateFilters(filters) {
    const filterEls = [];
    for (const filter of filters) {
      filterEls.push(
        <li
          key={uuid()}
          className="filter rounded-pill d-block list-unstyled"
        >
          <span 
            className="filter-button"
            onClick={() => {
              if (!filters.has(filter)) {
                console.error(`Error: Trying to delete filter that was never entered: ${filter}`);
                return;
              }
              
              filters.delete(filter);
              document.querySelector(`[data-filtervalue="${filter.value}"] input`).checked = false;
              selectedFiltersRef.current = filters;
              setSelectedFiltersEls(filterEls);
              updateFilters(filters);
            }}
          >
            <span className={"filter-value" + (filter.category === 'Question' ? ' question-filter' : '')}>
              {filter.category === 'Question' 
                ? (
                  <OverlayTrigger 
                    placement="top" 
                    delay={{show: 200, hide: 50}}
                    overlay={
                      <Tooltip>
                        {filter.value}
                      </Tooltip>
                    }
                  >
                    <span>{filter.value.substring(0, 15) + '...'}</span>
                  </OverlayTrigger>
                )
                : filter.value
              }
              
            </span>
            <X size={16}/>
          </span>
        </li>
      );
    }

    selectedFiltersRef.current = filters;
    setSelectedFiltersEls(filterEls);

    if (selectedFiltersRef.current.size === 0) {
      const newCards = [];
      for (const postInfo of cardsInfoRef.current) {
        const selected = newCards.length === 0 && deviceSize.current !== 'mobile';
        newCards.push(
          
          <FAQCard info={postInfo} setCurrentPost={setCurrentPost} key={uuid()} mode="preview" setSelectedCard={setSelectedCard} selected={selected} expandedCardModal={expandedCardModalRef} mixpanel={props.mixpanel} />
        );
      }
      if (newCards.length > 0) {
        setCardsState(newCards);
        setSelectedCard(
          <FAQCard info={cardsInfoRef.current[0]} setCurrentPost={setCurrentPost} key={uuid()} mode="expanded" setSelectedCard={setSelectedCard} expandedCardModal={expandedCardModalRef} mixpanel={props.mixpanel} /> 
        );
      }
      return;
    }

    // Update filtered post results here
    let dateFilter = "";
    let experienceFilters = new Set([]);
    let questionFilters = new Set([]);

    for (const filter of selectedFiltersRef.current) {
      if (filter.category === 'Date Posted') {
        dateFilter = filter.value;
      } else if (filter.category === "Experience Type") {
        experienceFilters.add(filter.value);
      } else if (filter.category === "Question") {
        questionFilters.add(filter.value);
      }
    }

    const newCards = [];
    let selectedInfo = {};
    for (const info of cardsInfoRef.current) {
      const selected = newCards.length === 0 && deviceSize.current !== 'mobile';
      let added = false; // To ensure each post is displayed only once
      // Date filter
      let dateFilterValues = {
        'Today': 1,
        'This week': 7,
        'This month': 30,
        'This year': 365
      }
      if (dateFilterValues[dateFilter]) {
        const dateFilterValue = dateFilterValues[dateFilter];
        const searchFromDate = new Date(Date.now());
        searchFromDate.setDate(searchFromDate.getDate() - dateFilterValue);

        const postDate = new Date(info.date);
        if (postDate - searchFromDate > 0) {
          // Add post
          newCards.push(
            <FAQCard info={info} setCurrentPost={setCurrentPost} key={uuid()} mode="preview" setSelectedCard={setSelectedCard} selected={selected} expandedCardModal={expandedCardModalRef} mixpanel={props.mixpanel} />
          );
          if (selected) selectedInfo = info;
          continue;
        }
      }

      // Experience filters
      for (const filterVal of experienceFilters) {
        if (filterVal === info.experienceLevel) {
          newCards.push(
            <FAQCard info={info} setCurrentPost={setCurrentPost} key={uuid()} mode="preview" setSelectedCard={setSelectedCard} selected={selected} expandedCardModal={expandedCardModalRef} mixpanel={props.mixpanel} />
          );
          if (selected) selectedInfo = info;
          added = true;
          break;
        }
      }
      if (added) continue;

      // Question filters
      const questions = new Set([]);
      for (const response of info.post.responses) {
        questions.add(response.question);
      }
      for (const filterVal of questionFilters) {
        if (questions.has(filterVal)) {
          newCards.push(
            <FAQCard info={info} setCurrentPost={setCurrentPost} key={uuid()} mode="preview" setSelectedCard={setSelectedCard} selected={selected} expandedCardModal={expandedCardModalRef} mixpanel={props.mixpanel} />
          );
          if (selected) selectedInfo = info;
          added = true;
          break;
        }
      }
      if (added) continue;
    }

    if (newCards.length > 0 && Object.keys(selectedInfo).length > 0) {
      setSelectedCard(
        <FAQCard info={selectedInfo} setCurrentPost={setCurrentPost} key={uuid()} mode="expanded" expandedCardModal={expandedCardModalRef} mixpanel={props.mixpanel} />
      );
    } else if (newCards.length === 0) {
      setSelectedCard(null);
    }
    setCardsState(newCards);
  }

  // Called when clear filters is clicked
  function clearFilters() {
    for (let filter of selectedFiltersRef.current) {
      document.querySelector(`[data-filtervalue="${filter.value}"] input`).checked = false;
    }
    selectedFiltersRef.current = new Set([]);
    setSelectedFiltersEls(null);
    updateFilters(new Set([]));
  }

  function updateFaqContainerHeight() {
    if (!faqContainerRef.current.style) return;
    faqContainerRef.current.style.setProperty(
      '--offset-top', faqContainerRef.current.offsetTop+'px'
    );
    setFaqOffsetHeight(faqContainerRef.current.offsetHeight);
  }

return (
    <div className="stories-component">
      <div className="filters-contribute">
        <div className="filters-dropdown-container">
          <div className="filter-results-bold">
            <h5><b>Filters</b></h5></div>
          <div className="filter-options">
            <div className="filter-category">
              <h5><b>Date Posted</b></h5>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Today')} />
                  <p2> Today</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('This week')} />
                <p2> This week</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('This Month')} />
                <p2> This month</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('This year')} />
                <p2> This year</p2>
              </div>
              <div className="view-more-button" onClick={() => handleButtonSelection('viewmore')}>
                {/* <input type="button" onChange={() => handleButtonSelection('viewmore')} /> */}
                View More
              </div>
            </div>
          </div>
          <div className="filter-options">
            <div className="filter-category"><h5><b>Experience Level</b></h5>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Intern')} />
                <p2> Intern</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Junior')} />
                <p2> Junior</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Mid-Level')} />
                <p2> Mid-Level</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Senior-Level')} />
                <p2> Senior-Level</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Contractor')} />
                <p2> Contractor</p2>
              </div>
              <div className="view-more-button" onClick={() => handleButtonSelection('viewmore')}>
                {/* <input type="button" onChange={() => handleButtonSelection('viewmore')} /> */}
                View More
              </div>
            </div>
          </div>
          <div className="filter-options">
            <div className="filter-category"><h5><b>Experience Type</b></h5>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Full-Time')} />
                <p2> Full-Time</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Part-Time')} />
                <p2> Part-Time</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Permanent')} />
                <p2> Permanent</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Temporary')} />
                <p2> Temporary</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Contract')} />
                <p2> Contract</p2>
              </div>
              <div className="view-more-button" onClick={() => handleButtonSelection('viewmore')}>
                {/* <input type="button" onChange={() => handleButtonSelection('viewmore')} /> */}
                View More
              </div>
            </div>
          </div>
          <div className="filter-options">
            <div className="filter-category"><h5><b>Company</b></h5>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Google')} />
                <p2> Google</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Microsoft')} />
                <p2> Microsoft</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Meta')} />
                <p2> Meta</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Figma')} />
                <p2> Figma</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Nvidia')} />
                <p2> Nvidia</p2>
              </div>
              <div className="view-more-button" onClick={() => handleButtonSelection('viewmore')}>
                {/* <input type="button" onChange={() => handleButtonSelection('viewmore')} /> */}
                View More
              </div>
            </div>
          </div>
          <div className="filter-options">
            <div className="filter-category"><h5><b>Industry</b></h5>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Product Design')} />
                <p2> Product Design</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Product Management')} />
                <p2> Product Management</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Software Engineer')} />
                <p2> Software Engineer</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Machine Learning')} />
                <p2> Machine Learning</p2>
              </div>
              <div className="filter-category-element">
                <input type="checkbox" onChange={() => handleFilterSelection('Data Scientist')} />
                <p2> Data Design</p2>
              </div>
              <div className="view-more-button" onClick={() => handleButtonSelection('viewmore')}>
                {/* <input type="button" onChange={() => handleButtonSelection('viewmore')} /> */}
                View More
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {selectedFiltersRef.current?.size > 0 &&
        <div className="selected-filters-container">
          {selectedFiltersEls}
          <button className="clear-filters" onClick={clearFilters}>
            clear filters
          </button>
        </div>
      }

      {cardsState.length === 0 &&
        <h4 className="no-stories">No stories found</h4>
      }

      {cardsState.length > 0 &&
        <div className="faq-container" ref={faqContainerRef}>
          <div className="faq-preview-cards-container" ref={previewContainerRef}>
            {cardsState}
          </div>
          <div 
            className="expanded-card-modal" 
            onClick={(evt) => {
              if (evt.target == expandedCardModalRef.current) {
                expandedCardModalRef.current.classList.remove('d-block');
                const selectedCard = document.querySelector('.selected');
                if (selectedCard) selectedCard.classList.remove('selected'); 
              }
            }}
            ref={expandedCardModalRef}
          >
            <div className="faq-expanded-card-container" ref={expandedCardContainerRef}>
              {selectedCard}
              <div className="comments-container" ref={commentsRef}>
                <Share currentPost={currentPost} jobTitle={jobTitle}/> 
                <Comments  currentPost={currentPost} mixpanel={props.mixpanel} />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default withRouter(Stories);
