import { usePopper } from 'react-popper';
import { useState } from 'react'
import ReactDom from 'react-dom';
import "./share.css"

const Share = ( {currentPost, jobTitle} ) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const [copyOpen, setCopyOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [{ name: 'arrow', options: {padding: 0, element: arrowElement,}}]
  });

  function copy () {
    setCopyOpen(true);
    // Add to User's Clipboard
    const hostname = window.location.hostname;
    let baseUrl = '';
    if (hostname === 'localhost') 
      baseUrl = 'http://localhost:3000';
    else if (hostname === 'pathfinder-test.netlify.app') 
      baseUrl = 'https://pathfinder-test.netlify.app';
    else if (hostname === 'staging.pathfinder.fyi')
      baseUrl = 'https://staging.pathfinder.fyi';
    else 
      baseUrl = 'https://pathfinder.fyi'
    const copyUrl = `${baseUrl}/story?post=${currentPost.post._id}`;
    navigator.clipboard.writeText(copyUrl);
    setPopperOpen(false);
    
    setTimeout(()=> {
      setCopyOpen(false);
    }, 2500)
  }

  return (
    <div className="share-container" onClick={(evt) => evt.stopPropagation()}>
      <button type="button" ref={setReferenceElement} onClick={()=> setPopperOpen(!popperOpen)} >
        <img src="/share_button.svg" alt="share" /> 
      </button>

      {popperOpen ? <div className="share-modal-overlay" onClick={() => setPopperOpen(false)}></div> : null}
      {popperOpen ? 
        ReactDom.createPortal(
      <div
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="share-modal-container">
          <div className="share-modal-entry" onClick={copy}>
            <img src="/copyLink.png" alt="link" />
            Copy Link
          </div>
          <div className="share-modal-entry">
            <img src="/landingImg/companyLogo/linkedIn.png" alt="link" />
            Share on LinkedIn (coming soon!)
          </div>
      </div>
        ,document.querySelector('#portal')
      ) : null}
      <div className={copyOpen ? "copy show" : "copy"}>Link copied to clipboard</div>
    </div>
  );
};

export default Share;

