import React from 'react';

import Landing from '../pages/Landing';
import AdvancedSearch from '../pages/AdvancedSearch';
import Field from '../pages/Field';
import Results from '../pages/Results';
import Careers from '../pages/Careers';
import Career from '../pages/Career';
import Discover from '../pages/Discover';
import DiscoverV2 from '../pages/DiscoverV2';
import StoriesV2 from '../pages/StoriesV2';
import About from '../pages/About';
import ToS from '../pages/ToS';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Verify from '../pages/Verify';

import mixpanel from 'mixpanel-browser';
import Debug from '../pages/Debug';

const initMixpanel = async () => {
  await mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}
initMixpanel();

export const getMixpanel = () => mixpanel;

export const routes = [
  {
    path: '/',
    component: (setShowSignin) => <Landing setShowSignin={setShowSignin} mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  },
  {
    path: '/advanced-search',
    component: () => <AdvancedSearch mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  },
  {
    path: '/field/:field',
    component: (field) => <Field field={field} mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  },
  {
    path: '/results/:query',
    component: () => <Results mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  },
  {
    path: '/careers',
    component: () => <Careers mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  },
  {
    path: '/careers/*',
    component: () => <Careers mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  },
  {
    path: '/career/:title/:tab',
    component: (title) => <Career title={title} mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: false,
  },
  {
    path: '/discover',
    component: () => <DiscoverV2 mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  },
  {
    path: '/about',
    component: () => <About mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  },
  {
    path: '/terms-of-service',
    component: () => <ToS />,
    isPrivate: false,
    hasFooter: true
  },
  {
    path: '/privacy-policy',
    component: () => <PrivacyPolicy />,
    isPrivate: false,
    hasFooter: true
  },
  {
    path: '/debug',
    component: () => <Debug />,
    isPrivate: false,
    hasFooter: true
  },
  {
    path: '/verify',
    component: () => <Verify mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: false
  },
  { // IMPORTANT: Always leave this route at the bottom.
    path: '/:post', // story?postId=<postId>
    component: () => <StoriesV2 mixpanel={mixpanel} />,
    isPrivate: false,
    hasFooter: true,
  }
];