import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'react-feather';
// import { v4 as uuid} from 'uuid';
import { FAQCard } from '../../components';
import { getCareerPosts, getStories } from '../../app';
import { useIsMountedRef } from '../../hooks';
import './discover.css';

/**
 * @typedef {import('../../components/FAQCard/FAQCard').PostInfo} PostInfo
 */

const Discover = (props) => {
  /** { [sectionName]: jobTitles[] } */
  const [sectionNames, setSectionNames] = useState({});
  /** @type {[PostInfo[], React.Dispatch<React.SetStateAction<PostInfo[]>>]} */
  const [postInfos, setPostsData] = useState([]);
  /** @type {[Object.<string, ReactElement[]>, React.Dispatch<React.SetStateAction<Object.<string, ReactElement[]>>>]} */
  const [cards, setCards] = useState({});
  /** @type {[Object.<string, boolean>, React.Dispatch<React.SetStateAction<Object.<string, boolean>>>]} */
  const [seeMoreState, setSeeMoreState] = useState({}); // true value represents expanded

  const carouselRef = useRef(<></>);
  const isMountedRef = useIsMountedRef();

  useEffect(() => { // []
    document.title = 'Discover Stories - PathFinder';

    const discoverTab = document.querySelector('#nav-discover');
    discoverTab.classList.add('fw-bold', 'selected');

    let sections = {
      'Engineering': ['Software Engineer'],
      'Product': ['Product Manager', 'Product Designer'],
    }

    setSectionNames(sections);

    // const carouselInterval = setInterval(() => {
    //   // slideCarousel(1);
    // }, 2000);

    let initSeeMoreState = Object.keys(sections).reduce((obj, sectionName) => {
      obj[sectionName] = false;
      return obj;
    }, {});

    setSeeMoreState(initSeeMoreState);
    const fetchStories = async () => setPostsData(await getCareerPosts('ALL'));
    fetchStories();

    return () => {
      discoverTab.classList.remove('fw-bold', 'selected');
      isMountedRef.current = false;
      // clearInterval(carouselInterval);
    }
  }, []);

  useEffect(() => { // [postsData]
    if (!postInfos || postInfos.length === 0 || !isMountedRef.current) return;

    // Search through sections and data to create cards under each category*
    for (const [sectionName, jobTitles] of Object.entries(sectionNames)) {
      let newCards = [];
      for (const postInfo of postInfos) {
        for (const jobTitle of postInfo.jobTitles) {
          if (jobTitles.includes(jobTitle)) {
            if (sectionName === 'Product') {
              // hardcoded filters for now to avoid inaccurate product posts
              if (postInfo.officialTitle === 'Production Engineer' ||
              (postInfo.officialTitle === 'Staff' && postInfo.post.user.firstName === 'Li')) {
                break;
              }
            }
            const newCard = <FAQCard info={postInfo} mode="discover" key={postInfo._id} mixpanel={props.mixpanel} />;
            newCards.push(newCard);
            break;
          }
        }
        if (newCards.length >= 8) break;
      }
      setCards((prevCards) => {
        return { ...prevCards,
          [sectionName]: newCards
        }
      });
    }

    let featuredPosts = [
      postInfos.find((postInfo) => postInfo.officialTitle === 'Production Engineer'), // production engineer; anonymous
      postInfos.find((postInfo) => postInfo.post.user.fullName === 'Daiana'), // swe
      postInfos.find((postInfo) => postInfo.post.user.fullName === 'Ivy H'), // ui
      postInfos.find((postInfo) => postInfo.post.user.fullName === 'Tim Nguyen'), // swe
      postInfos.find((postInfo) => postInfo.post.user.fullName === 'Jessie'), // date scientist
      postInfos.find((postInfo) => postInfo.post.user.fullName === 'Leon Pham'), // swe
      postInfos.find((postInfo) => postInfo.post.user.fullName === 'Daniel Kim'), // swe
    ];

    let featuredCards = [];

    for (let i=0; i < featuredPosts.length; ++i) {
      let postInfo = featuredPosts[i];

      const newCard = <FAQCard 
        info={postInfo} 
        mode="discover" 
        className={'featured ' + (i === 1 ? 'focused ' : '')}
        key={postInfo._id}
        index={i} 
        carouselRef={carouselRef}
        mixpanel={props.mixpanel}
      />;
      featuredCards.push(newCard);
    }

    setCards((prevCards) => {
      return { ...prevCards,
        featured: featuredCards
      }
    })
  }, [postInfos]);

  /**
   * @param {number} d Amount to change carousel index by
   */
  function slideCarousel(d) {
    const carouselIdx = parseInt(
      getComputedStyle(carouselRef.current).getPropertyValue('--index')
    );
    let newIdx = carouselIdx + d;

    // Wrap idx around
    const cardsCount = carouselRef.current.children.length;
    if (newIdx < 0) newIdx = cardsCount-1;
    else if (newIdx >= cardsCount) newIdx = 0;

    carouselRef.current.style.setProperty('--index', newIdx);

    carouselRef.current.children[carouselIdx].classList.remove('focused');
    carouselRef.current.children[newIdx].classList.add('focused');
  }

  return (
    <div className="discover-component">
      <h2 className="main-header">Discover</h2>
      <p className="main-description">Read Stories from people working in tech.</p>

      <section className="featured">
        <h2 className="section-header">
          Featured
          <button onClick={() => slideCarousel(-1)}>
            <ChevronLeft size={40} />
          </button>
          <button onClick={() => slideCarousel(1)}>
            <ChevronRight size={40} />
          </button>
        </h2>
        <div className="featured-cards-container">
          <div className="carousel" ref={carouselRef}>{cards.featured}</div>
        </div>
      </section>

      {Object.keys(sectionNames).map((sectionName) => {
        return (
          <section className="content-section" key={sectionName}>
            <h2 className="section-header">
              Highlights from{' '}<span className="section-name">{sectionName}</span>
            </h2>
            
            {cards[sectionName] &&
              <div className="cards-container">
                {!seeMoreState[sectionName] 
                  ? cards[sectionName].slice(0,4)
                  : cards[sectionName] }
              </div>
            }
            <button 
              className="see-more"
              onClick={() => {
                setSeeMoreState((prev) => {
                  return { ...prev,
                    [sectionName]: !prev[sectionName]
                  }
              })}}
            >
              {!seeMoreState[sectionName]
                ? 'See more'
                : 'See less' }
            </button>
          </section>
        )})}
    </div>
  );
}

export default withRouter(Discover);