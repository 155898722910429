import "./signin.css";
import { useState, useEffect, useRef } from "react";
import React from "react";
import GoogleLogin from "react-google-login";
import { X } from "react-feather";
import { newContact, updateUser, linkedinSignin, sendMagicLink } from "../../app";
import { loginUser, useAuthDispatch } from "../../context";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export function Signin({ setShowSignin }) {
    const authDispatch = useAuthDispatch();
    const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
    const [email, setEmail] = useState('');

    const modal = useRef(null);
    const emailInputRef = useRef();

    useEffect(() => {
        modal.current.showModal();
    }, [modal]);

    useEffect(() => {
        modal.current.addEventListener('mousedown', (event) => {
            if (!modal.current.children[0].contains(event.target)) {
                modal.current.close();
                setShowSignin(false);
            }
        });
    }, []);


    async function googleLoginResponse(response) {
        if (response.error) {
            if (response.error !== 'popup_closed_by_user') {
                console.error('Error receiving response from Google login:');
                console.error(response);
            }
        } else {
            try {
                const userObj = await updateUser(response.profileObj);
                if (!userObj) {
                    toggleLogs && console.error('Error updating user');
                    return;
                }
                await loginUser(authDispatch, response.tokenId, userObj, 'google');
                const fullName = userObj.firstName + (userObj.lastName ? ' ' + userObj.lastName : '');
                await newContact(userObj.email, fullName);
                
                location.reload();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleClickMagicLink = async (e) => {
        e.preventDefault();

        const currEmail = emailInputRef.current.value;
        if (currEmail.indexOf('@') > -1){
            setEmail(currEmail);
            const res = await sendMagicLink(currEmail);
            if (res) {
                setIsMagicLinkSent(true);
            }
        } else {
            alert(`Please enter a valid email address`);
        }
    }

    return (
        <dialog ref={modal} className="signinModal">
            {!isMagicLinkSent &&
                <div className="layout">
                    <section className="featureSection textContainer">
                        <h2 className="headlineText">Get Exclusive Features When You Sign In!</h2>
                        <ul className="featureList">
                            <li className="featureItem">💡 Get professional insights</li>
                            <li className="featureItem">👥 Access matched mentors related to your query</li>
                            <li className="featureItem">📖 Access external resources relevant to your query</li>
                            <li className="featureItem">🔔 Notifications tailored to your questions</li>
                            <li className="featureItem">‍💬 Connect with like-minded peers</li>
                        </ul>
                        <b className="boldFeature">🚀 Unlock these exclusive features for enhanced career growth today!</b>
                    </section>
                    <div className="verticalLine"></div>
                    <section className="buttonSection">
                        <button 
                            className="closingButton" 
                            onClick={() => { 
                                modal.current.close();
                                setShowSignin(false);
                            }}>
                            <X color="#527ef0" />
                        </button>
                        <h3 className="blueText">Join a community and bond with mentors and peers in your industry.</h3>
                        <form className="magicLinkSection" onSubmit={handleClickMagicLink}>
                            <label className="emailLabel">Sign Up or Sign In</label>
                            <input
                                type="email"
                                id="email"
                                className="emailInput"
                                placeholder="Email"
                                ref={emailInputRef}
                            />
                            <button type="submit" className="emailButton">Continue with Email</button>
                        </form>
                        <span className="horizontalLine">or</span>
                            <GoogleLogin
                            render={renderProps =>
                                <button onClick={renderProps.onClick} className="signinButton">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.5 8.18187C16.5 7.6146 16.4481 7.06914 16.3516 6.5455H8.66326V9.64005H13.0566C12.8673 10.6401 12.2922 11.4873 11.4276 12.0546V14.0619H14.0659C15.6095 12.6691 16.5 10.6182 16.5 8.18187Z" fill="#4285F4" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.66327 16C10.8674 16 12.7152 15.2837 14.0659 14.0619L11.4276 12.0546C10.6967 12.5346 9.76161 12.8182 8.66327 12.8182C6.53711 12.8182 4.73748 11.4109 4.09555 9.52H1.36828V11.5927C2.71151 14.2073 5.47218 16 8.66327 16Z" fill="#34A853" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.09555 9.52C3.93229 9.04 3.83952 8.52732 3.83952 8.00005C3.83952 7.47277 3.93228 6.96005 4.09555 6.48004V4.40732H1.36828C0.815399 5.48732 0.5 6.70914 0.5 8.00005C0.5 9.29096 0.815403 10.5127 1.36828 11.5927L4.09555 9.52Z" fill="#FBBC05" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.66327 3.18182C9.86179 3.18182 10.9379 3.58546 11.7839 4.37818L14.1252 2.08364C12.7115 0.792728 10.8636 0 8.66327 0C5.47218 0 2.7115 1.79277 1.36828 4.40732L4.09555 6.48004C4.73748 4.58913 6.53711 3.18182 8.66327 3.18182Z" fill="#EA4335" />
                                    </svg>
                                    <p className="buttonText">Continue with Google</p>
                                </button>
                            }
                            clientId={clientId}
                            onSuccess={googleLoginResponse}
                            onFailure={googleLoginResponse}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true} />
                        <button onClick={linkedinSignin} className="signinButton">
                            <img src="/landingImg/companyLogo/linkedIn.png" height={16} width={16}></img>
                            Continue with LinkedIn
                        </button>
                    </section>
                </div>
            }
            {isMagicLinkSent &&
                <div className="magicLinkSentLayout">
                    <button 
                        className="closingButton" 
                        onClick={() => { 
                            modal.current.close();
                            setShowSignin(false);
                        }}>
                        <X color="#527ef0" />
                    </button>
                    <h3 className="blueText magicLinkSentText">
                        A Magic Link has been sent to you.
                    </h3>
                    <h3 className="blueText magicLinkSentText">
                        Check your email!
                    </h3>
                    <h5 className="normalText">
                        We emailed a magic link to {email}.
                        The link expires in 10 minutes.
                    </h5>
                    <h5 className="boldText">
                        Click the link to sign in or sign up.
                    </h5>
                </div>
            }
        </dialog>
    );
};