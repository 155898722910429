import React, { useState } from 'react';
import { ReactComponent as Heart } from '../../../assets/heart.svg';
import { ReactComponent as Like } from '../../../assets/like.svg';
import { ReactComponent as Surprise } from '../../../assets/surprise.svg';
import { ReactComponent as HeartPopover } from '../../../assets/heart_popover.svg';
import { ReactComponent as LikePopover } from '../../../assets/like_popover.svg';
import { ReactComponent as SurprisePopover } from '../../../assets/surprise_popover.svg';
import './reactionButtonV2.css';

const ReactionButtonV2 = ({ data, userId, type, reaction, handleReaction }) => {
  const [isVisible, setIsVisible] = useState(false);

  const icons = { "Love": <Heart />, "Like": <Like />, "Surprise": <Surprise /> };
  const story_preview_icons = { "Love": <Heart height={16} width={16} />, "Like": <Like height={16} width={16} />, "Surprise": <Surprise height={16} width={16}/> };
  const popover_icons = { "Love": <HeartPopover />, "Like": <LikePopover />, "Surprise": <SurprisePopover /> };

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <div className="popover-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button
        className={data.reactions[reaction].includes(userId) ? "reaction-button-selected" : "reaction-button"}
        onClick={handleReaction(data._id, reaction)}
      >
        <div>{type === "story-preview" ? story_preview_icons[reaction] : icons[reaction]}</div>
        <div>{data.reactions[reaction].length}</div>
      </button>
      {isVisible && type !== "story-preview" &&
        <div className="popover-content">
          <span>
            <div sx={{ p: 1.5, fontFamily: 'DM Sans', color: '#888888' }}>
              {popover_icons[reaction]} <b>{reaction}</b> reacted by {data.reactions[reaction].length}
              {(data.reactions[reaction].length > 1) ? ` people` : ` person`}
            </div>
          </span>
        </div>}
    </div>
  );
};

export default ReactionButtonV2;
