import { useState } from 'react';
import { useAuthState } from '../../../context';
import { useSubdomain } from '../../../hooks';
import { getCommunityData } from '../../../app';
import './chat_prompt.css';

const ChatPrompt = ({ content }) => {
  const userDetails = useAuthState();
  const subdomain = useSubdomain();
  const [community] = useState(() => getCommunityData(subdomain));

  return (
    <div className="chat-prompt">
      <div className="chat-prompt-content">
        <strong className="chat-prompt-title">You</strong>
        <div className="user-input">
          {content}
        </div>
      </div>
      <div className="chat-bubble-container">
        {userDetails.user ? <img
          src={userDetails.user.avatarUrl}
          alt={userDetails.user.firstName + ' Avatar'}
          referrerPolicy="no-referrer"
          className="chat-bubble-icon"
          onError={async (evt) => {
            const url = `https://ui-avatars.com/api/?name=${userDetails.user.firstName + ' ' + userDetails.user.lastName}&length=2&bold=true&background=527ef0&color=fcfcfc&font-size=0.5`;
            const imgUrl = await getUIAvatar(url);
            evt.target.src = imgUrl;
          }}
        />
        :
        <img src="/default_avatar.png" className="chat-bubble-icon" />
        }
      </div>
    </div>
  );
};

export default ChatPrompt;