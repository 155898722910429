import React, { useState, useRef, useEffect } from 'react';
import './discoverV2.less';

const FilterSection = ({ filterCategory, filterData, onFilterSelectionChange }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(filterData);
  const inputRef = useRef(null);

  useEffect(() => {
    const searchData = filterData.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
    const filteredSearchData = searchData.filter(item => !selectedFilters.includes(item));
    setFilteredData(filteredSearchData);
  }, [filterData, searchTerm, selectedFilters]);

  const handleFilterSelection = (element) => {
    const updatedFilters = selectedFilters.includes(element)
      ? selectedFilters.filter(filter => filter !== element)
      : [...selectedFilters, element];

    setSelectedFilters(updatedFilters);
    onFilterSelectionChange(updatedFilters);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function focusInput() {
    inputRef.current.focus();
  }
  
  return (
    <div className="filter-category" onClick={focusInput}>
      <b className="filter-title">{filterCategory}</b>
      <div className="search-box">
        <img src="/icons/SearchIcon.svg"/>
        <input
          ref={inputRef}
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="filter-category-container">
        {selectedFilters.map((element, index) => (
          <div key={element + index} className="filter-category-element">
            <input
              type="checkbox"
              id={"selected-filter-"+element}
              onChange={() => handleFilterSelection(element)}
              checked={selectedFilters.includes(element)}
            />
            <label
              htmlFor={"selected-filter-"+element}
              className="filter-label">{element}</label>
          </div>
        ))}
        {filteredData.map((element, index) => (
          <div key={index} className="filter-category-element">
            <input
              type="checkbox"
              onChange={() => handleFilterSelection(element)}
              checked={selectedFilters.includes(element)}
              id={"filter-"+element}
            />
            <label
              htmlFor={"filter-"+element}
              className="filter-label">{element}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterSection;