import React, { useEffect, useRef } from 'react';
import { useIsMountedRef } from '../../hooks';
import './loading.css';

const Loading = (props) => {
  const isMountedRef = useIsMountedRef();
  const loadingMessage = useRef();
  const loadingError = useRef();

  useEffect(() => { // []
    const timeout = 8000; // Time to wait before displaying loading message
    setTimeout(() => {
      if (!isMountedRef.current) return;
      loadingMessage.current.classList.remove('d-none');
    }, timeout);

    setTimeout(() => {
      if (!isMountedRef.current) return;
      loadingError.current.classList.remove('d-none');
      props.mixpanel.track('Loading issue encountered', { _timeout: timeout });
    }, timeout * 2);

    return () => {
      isMountedRef.current = false;
    }
  }, []);

  return (
    <div className="loading-component">
      <img src="/full_logo.png" alt="Logo" />
      <div className="loading-bar">
        <div className="loading-bar-progress"></div>
      </div>
      <div className="loading-message d-none" ref={loadingMessage}>
        Still loading...
        <div className="loading-error d-none" ref={loadingError}>
          Please notify
          <a href="mailto: team@pathfinder.fyi"> team@pathfinder.fyi </a>
          if the site fails to load
        </div>
      </div>
    </div>
  );
}

export default Loading;