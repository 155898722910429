import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Overview from '../Overview';
import Stories from '../Stories';
import { Footer } from '../../components';
// import { useAuthState, useAuthDisaptch } from '../../context';
import { useIsMountedRef } from '../../hooks';
import { capitalize, processLink } from '../../util';
// import '../../css/career.css';
import './career.css';

const Careers = (props) => {
  const [jobTitle, setJobTitle] = useState('');
  const [tab, setTab] = useState('');
  const [navTabs, setNavTabs] = useState([]);
  const [tabContent, setTabContent] = useState([]);
  const isMountedRef = useIsMountedRef();
  const navRefs = useRef({});
  const tabRefs = useRef({});
  const [drawFooter, setDrawFooter] = useState(false);

  useEffect(() => {
    props.history.listen(location => {
      if (!isMountedRef.current) return;
      let matchTab = location.pathname.match(/\/career\/.+\/(.+)/);
      if (matchTab) setTab(matchTab[1]);
      return () => isMountedRef.current = false;
    });

    const currentTab = props.match.params.tab;

    setTab(props.match.params.tab);
    setJobTitle(processLink(props.match.params.title));

    const tabs = ['overview', 'stories'];

    // Nav tabs
    const navEls = [];
    for (const type of tabs) {
      const buttonClass = 'nav-link btn-lg' + (type === currentTab ? ' active' : '');
      const buttonID = `${type}-nav-tab`;
      const bsTarget = `#${type}-tab`;
      const ariaControls = `${type}-tab`;
      const ariaSelected = type === 'Overview' ? 'true' : 'false';
      const onClickFn = () => switchTab(type);

      navEls.push(
        <li className="nav-item" role="presentation" key={uuid()}>
          <button className={buttonClass} id={buttonID} data-bs-toggle="pill" data-bs-target={bsTarget} role="tab" aria-controls={ariaControls} aria-selected={ariaSelected} onClick={onClickFn} ref={el => navRefs.current[type] = el}>
            <div>{capitalize(type)}</div>
          </button>
        </li>
      );
    }

    setNavTabs(navEls);

    // Tab content
    const tabEls = [];
    for (const type of tabs) {
      const tabClass = 'tab-pane' + (type === currentTab ? ' active' : '');
      const tabID = `${type}-tab`;
      const labelledby = `${type}-nav-tab`;
      let component = <></>;
      switch(type) {
        case 'overview':
          component = <>
            <Overview {...props} />
            {/* <Footer mixpanel={props.mixpanel} /> */}
          </>;
          break;
        case 'stories':
          component = <Stories {...props} />;
          break;
        default:
          console.error('Error: invalid tab type');
      }

      tabEls.push(
        <div className={tabClass} id={tabID} role="tabpanel" aria-labelledby={labelledby} key={uuid()} ref={el => tabRefs.current[type] = el} >
          {component}
        </div>
      );
    }

    setTabContent(tabEls);
  }, []);

  useEffect(() => { // [tab]
    if (!tab || Object.keys(navRefs.current).length === 0) return;

    document.title = `${jobTitle} ${capitalize(tab)} - PathFinder`;

    // Manually update tabs in case of back or forward browser buttons
    for (const [tabName, tabEl] of Object.entries(tabRefs.current)) {
      if (tab === tabName) {
        tabEl.classList.add('active');
        navRefs.current[tabName].classList.add('active');
      } else {
        tabEl.classList.remove('active');
        navRefs.current[tabName].classList.remove('active');
      }
    }

    updateDrawFooter();
  }, [tab]);

  useEffect(() => {
    window.addEventListener('resize', updateDrawFooter);

    return () => {
      window.removeEventListener('resize', updateDrawFooter);
    }
  });

  function updateDrawFooter(evt) {
    const screenWidth = Math.min(window.outerWidth, window.innerWidth);
    const newDrawFooter = tab !== 'stories' || screenWidth < 767;
    setDrawFooter(newDrawFooter);
  }

  // Handle switching between tabs on career page
  function switchTab(newTab) {
    if (newTab !== tab) {
      setTab(newTab);
      props.history.push(`./${newTab}`);
    }
  }

  return (
    <>
      <div className="career-component container">
        {jobTitle &&
          <div className="job-title-container">
            <div className="title-and-tabs-container">
              <h1 className="job-title">
                {jobTitle}
              </h1>
              <ul className="nav nav-pills" id="career-tabs" role="tablist">
                {navTabs}
              </ul>
            </div>
            {/* <p>A description about the {jobTitle} career (coming soon!)</p> */}
          </div>
        }
        
        <div className="tab-content" id="career-tab-content">
          {tabContent}
        </div>
      </div>
      {drawFooter && <Footer mixpanel={props.mixpanel} />}
    </>
  )
}

export default withRouter(Careers);
