import React, { useState, useEffect, useRef } from 'react';
import { Triangle } from 'react-feather';
import './comment.css';
import CommentReply from '../CommentReply/CommentReply';
import Reactions from '../../Reactions/Reactions.jsx';
import { postReaction, updateReaction, getReaction, deleteComment } from '../../../app.js'
import { getTimeSincePost, handleReplyButtonClick } from '../../../util.js'
import { useAuthState } from '../../../context';
import {ReactComponent as Heart} from '../../../assets/heart.svg';
import {ReactComponent as Like} from '../../../assets/like.svg';
import {ReactComponent as Surprise} from '../../../assets/surprise.svg';
import {ReactComponent as AddReaction} from '../../../assets/add_reaction.svg';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


/**
 * @typedef {Object} CommentData
 * @property {string} user
 * @property {string} avatarUrl should be user data
 * @property {number} id 
 * @property {string} date
 * @property {string} content
 * @property {number} rating
 * @property {Object} reactions
 */

/** 
 * @param {Object} props
 * @param {CommentData} props.commentData
 */

const Comment = ({ setComments, commentData }) => {

  /** @type {[CommentData, React.Dispatch<React.SetStateAction<CommentData]} */
  const [data, setData] = useState(commentData);
  const [replyActive, setReplyActive] = useState(false)
  const userDetails = useAuthState();

  const [timePassed, setTimePassed] = useState('');

  const optionsMenuToggleRef = useRef();
  const optionsMenuRef = useRef();

  // Configure Date
  const monthsAbbreviated = [
    'Jan.',
    'Feb.',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];

  const givenDate = commentData.date
  const newYear = givenDate.slice(0,4)
  const newMonth = monthsAbbreviated[parseInt(givenDate.slice(5,7))-1]
  const newDay = givenDate.slice(8,10)

  async function handleDelete() {
    if (commentData._userId !== userDetails.user._id) return;
    if (window.confirm('Are you sure you wish to permanently delete your comment?')) {
      const response = await deleteComment(commentData._id);
      if (response) {
        setComments(comments => comments.filter(c => c._id !== commentData._id));
      } else {
        alert("Couldn't delete comment, please refresh and try again.");
      }
    }
  }

  useEffect(() => {
    setTimePassed(getTimeSincePost(commentData.date));
    const handleClickOutside = (event) => {
      if (optionsMenuToggleRef.current.checked && !optionsMenuRef.current.contains(event.target)) {
        optionsMenuToggleRef.current.checked = false;
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <div>
      <article className="comment-component">
        {commentData &&
          <>
            <div className="date">{timePassed}</div>
            <div className="comment-container">
              {commentData.user ? 
              <img
                  src={commentData.user.avatarUrl ? commentData.user.avatarUrl: ""}
                  alt={commentData.user.fullName + ' Avatar'}
                  className="avatar"
                  referrerPolicy="no-referrer"
                  loading="lazy"
              />
              : null}
              <div className="comment-body">
                {commentData.user ? 
                <h5>{commentData.user.fullName}</h5>
                : <h5>testing user</h5>}
                <div className="content">{commentData.content}</div>
                <div className="comment-footer">
                  <Reactions
                    data={data}
                    setData={setData}/>
                  <div className="reply-button"
                    onClick={() => handleReplyButtonClick(commentData.user.fullName)}>
                    Reply
                  </div>
                </div>
              </div>
            </div>
            <input className="options-menu-toggle" type="checkbox" ref={optionsMenuToggleRef} />
            {userDetails.user && userDetails.user._id === commentData._userId ? ( 
            <div className="options-menu-container" ref={optionsMenuRef}>
              <label className="options-menu-button" onClick={() => {optionsMenuToggleRef.current.checked = !optionsMenuToggleRef.current.checked}}>
                <img width="20px" height="20px" src="/kebab_menu.svg"></img>
              </label>
              <ul className="options-menu">
                <li onClick={handleDelete}>
                  Delete Comment
                </li>
              </ul>
            </div>) : null}
          </>
        }
        <hr />
      </article>
      <CommentReply active={replyActive}/>
    </div>
  );
}

export default Comment;